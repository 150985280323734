@import '../../../cmrMixins.scss';

@mixin inputText {
    @include mediumFont($color: $sirBlue);
    width: 20%;
    display: flex;
}

.scheduleMarketReportHolder {
    padding-top: 40px;
    margin-bottom: 50px;
    .scheduleMarketReportHeader {
        @include mediumFont($textTransform: uppercase, $color: $sirBlue);
        margin-bottom: 35px;
    }
    .scheduleMarketReportContent {
        .actionHolder {
            display: flex;
            margin-bottom: 35px;
            position: relative;
            .inputTitle {
                @include inputText;
                align-items: center;
            }
            .inputTitleAlt {
                @include inputText;
                padding-top: 6px;
            }
            .inputHolder {
                width: 80%;
                .schedulerInput {
                    width: 100%;
                    @include roundedInput;
                }
                .schedulerDropDown {
                    padding: 5px 0px 5px 22px;
                    .MuiSelect-select:focus {
                        background-color: transparent;
                    }
                }
                .disabledInput {
                    width: 100%;
                    @include roundedInput($backgroundColor: $lightGray);
                }
            }
            .radioHolder {
                width: 80%;
                background-color: $whiteColor;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 4px;
                padding: 12px 22px;
                .label {
                    color: $darkGray;
                    font-family: 'MercuryTextG4Roman';
                    font-size: 14px;
                }
                .labelSelected {
                    color: $sirBlue;
                }
                .formLabel {
                    &:not(:last-child) {
                        border-bottom: 1px solid #d6d6d6;
                    }
                }
            }
            .edit-icon {
                position: absolute;
                right: 0;
                bottom: 0;
                margin-right: -35px;
                margin-bottom: 9px;
                color: $darkGray;
                .edit {
                    cursor: pointer;
                }
            }
        }
        .emailHolder,
        .actionHolder {
            position: relative;
            .sh-icons {
                position: absolute;
                right: 0;
                bottom: 0;
                margin-right: -35px;
                margin-bottom: 9px;
                color: $darkGray;
                &.info {
                    margin-right: -30px;
                    .icon {
                        cursor: pointer;
                    }
                }
                .plus {
                    cursor: pointer;
                }
                .disabledPlus {
                    cursor: default;
                }
            }
        }
        .emailHolder {
            .additionalInputs {
                position: relative;
                .xmark {
                    position: absolute;
                    right: 10px;
                    z-index: 1;
                    top: 8px;
                }
            }
        }
        .buttonGroup {
            display: flex;
            justify-content: flex-end;
            padding: 10px 0px;
            .actionButton {
                width: 80px;
                padding: 7px;
                border: 1px solid $sirBlue;
                cursor: pointer;
                @include mediumFont($fontSize: 12px, $textTransform: uppercase);
                margin-left: 25px;
            }
            .save {
                color: $sirBlue;
                background-color: $whiteColor;
            }
            .cancel {
                color: $whiteColor;
                background-color: $sirBlue;
            }
            .saveInActive {
                border: 1px solid $infoColor;
                background-color: $infoColor;
                color: $whiteColor;
                cursor: default;
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .scheduleMarketReportHolder {
        padding-top: 20px;
        margin-bottom: 20px;
        .scheduleMarketReportHeader {
            margin-bottom: 20px;
            font-size: 12px;
        }
        .scheduleMarketReportContent {
            .actionHolder {
                margin-bottom: 20px;
                .inputTitle,
                .inputHolder input,
                .inputTitleAlt,
                div,
                .radioHolder .label {
                    font-size: 12px;
                }
            }
            .buttonGroup {
                .actionButton {
                    font-size: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .scheduleMarketReportHolder {
        padding-top: 10px;
        margin-bottom: 10px;
        .scheduleMarketReportHeader {
            margin-bottom: 10px;
            font-size: 12px;
        }
        .scheduleMarketReportContent {
            .actionHolder {
                margin-bottom: 15px;
                .inputTitle,
                .inputHolder input,
                .inputTitleAlt,
                div,
                .radioHolder .label {
                    font-size: 10px;
                }
                .inputHolder,
                .radioHolder {
                    width: 75%;
                }
                .inputTitle,
                .inputTitleAlt {
                    width: 23%;
                }
                .sh-icons.info {
                    font-size: 12px;
                    margin-right: -10px;
                }
                .edit-icon {
                    font-size: 12px;
                    margin-right: -15px;
                }
            }
            .emailHolder {
                .sh-icons {
                    font-size: 12px;
                    margin-right: -12px;
                }
            }
            .buttonGroup {
                .actionButton {
                    font-size: 9px;
                }
            }
        }
    }
}

.customTooltipContent {
    .text {
        color: '#fff';
        @include mediumFont($fontSize: 12px);
    }
    .tooltipImage {
        margin-top: 10px;
        width: 100%;
        height: 100%;
    }
}
