@import '../../styles/newGridDesigns.scss';
@import '../../styles/formMixins';
@import '../../styles/typographyMixins';

.searchResults {
    .glo-title {
        @include mercuryDisplayRoman($fontSize: 26px, $color: $black);
        margin-bottom: 12px;
    }
    .indiRes {
        @include mercuryDisplayRoman($fontSize: 15px, $color: $sirBlue);
        margin-bottom: 10px;
        a {
            color: inherit;
        }
        &.totalRecords {
            margin-top: 20px;
        }
        .textBold {
            font-weight: bold;
        }
    }
    .search-icon {
        margin-right: 10px;
    }
    .searchResults-indi {
        cursor: pointer;
    }
}
