@import '../../../developmentDetailsMixins';

.developmentFeaturesEdit {
    .field-label {
        label {
            text-transform: capitalize;
        }
        text-transform: capitalize !important;
    }

    .section {
        border-bottom: 1px solid $lightGray;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }
    .chipSelected {
        border: 1px solid $sirBlue;
        @include regularFont($fontSize: 14px, $color: $whiteColor, $fontWeight: 400);
        margin: 5px !important;
        background-color: $sirBlue;

        svg {
            color: $whiteColor;
        }
    }
    .headline,
    .body {
        @include mediumFont($fontSize: 14px, $lineHeight: 18px);
        margin: 10px 0px;
    }
}
