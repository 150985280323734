@import '../../../../shared/styles/typographyMixins';
@import '../../../../shared/styles/colors';
.testimonialList {
    .testimonialHeader {
        background-color: $lighterGray;
        .title {
            @include mediumFont(
                $textTransform: uppercase,
                $fontWeight: 400,
                $color: $darkGray
            );
            text-align: center;
            padding: 10px;
        }
    }
    .testimonialContent {
        .testimonial {
            &.hide {
                display: none;
            }
            .content {
                @include regularFont($fontSize: 14px, $color: $sirBlue);
                text-align: center;
                padding: 10px;
                .actionIcon {
                    cursor: pointer;
                }
            }
        }
    }
}
