@import '../../../cmrMixins.scss';
.social-container {
    .inner-container {
        .form-container {
            padding: 10px;
            height: auto;
            background-color: $backgroundGray;
            display: flex;
            align-items: center;
            box-shadow: 0px 1px 4px $infoColor;
            .add-social-campaign {
                display: flex;
                flex-wrap: wrap;
                .actionHolder {
                    margin-right: 20px;
                    width: 30%;
                    margin-bottom: 10px;
                    .cmrInput {
                        @include inlineFormInput;
                    }
                    @include datePickerInput;
                    @include dateField;
                    &.ecFileHolder {
                        .ecFile {
                            @include fileFields;
                        }
                        .spec {
                            @include specText;
                        }
                    }
                }
            }
            .addButtonHolder {
                @include inlineAddButton;
            }
        }
        .table-heading {
            @include tableHeading($padding: 18px 15px 18px 0px);
            width: 100%;

            .dates {
                width: 13%;
            }
            .type {
                width: 16%;
            }
            .potential-reach {
                width: 13%;
            }
            .inner-reach {
                text-align: left;
                display: block;
                width: 77%;
            }
            .empty {
                width: 10%;
            }
            .views {
                width: 11%;
            }
            .engagements {
                width: 15%;
            }
            .clicks {
                width: 10%;
            }
        }
        .inner-data-container {
            overflow-y: overlay;
            .table-data {
                @include tableRow;

                .data-text {
                    letter-spacing: 0px;
                    @include bookFont;
                    color: $sirBlue;
                    line-height: 18px;
                }
                .data-dates {
                    letter-spacing: 0.02px;
                    line-height: 20px;
                    width: 13%;

                    @include dateFormat;
                }
                .data-type {
                    word-break: break-word;
                    width: 16%;
                }
                .data-views {
                    width: 11%;
                }
                .data-potential-reach {
                    width: 13%;
                }
                .data-engagements {
                    width: 15%;
                }
                .data-clicks {
                    width: 10%;
                }
                .social-campaigns-icons {
                    @include tableIcons;
                }
            }
            .edit-form-container {
                padding: 10px;
                height: auto;
                z-index: 1000;
                position: relative;
                background-color: $whiteColor;
                .edit-social-campaign {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 15px 15px 0px;
                    .editDateHolder {
                        display: flex;
                        flex-direction: column;
                        width: 20%;
                        @include bookFont;
                        .cmrEditDate {
                            .MuiInput-root {
                                &:hover::before {
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                                }
                                input {
                                    color: #333;
                                    @include bookFont;
                                }
                            }
                        }
                        @include datePickerEdit($color: #333);
                    }
                    .editActionHolder {
                        justify-content: space-between;
                        padding-left: 10px;
                        font-family: 'BentonSansBook';
                        .cmrEditInput {
                            @include bookFont;
                            color: #333;
                            &:hover::before {
                                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                            }
                            &::after {
                                border: none;
                            }
                        }
                        .editType {
                            margin-left: 5px;
                            width: 80%;
                        }
                        .editReach {
                            width: 80%;
                        }
                        .editViews {
                            width: 80%;
                        }
                        .editEngagement {
                            width: 80%;
                        }
                        .editClicks {
                            margin-right: 15px;
                            width: 80%;
                        }
                    }
                }
                .editFileHolder {
                    display: flex;
                    flex-direction: row;
                    .cmrUrlInput {
                        width: 70%;
                        @include bookFont;
                        color: #333;
                        margin-right: 5px;
                        &:hover::before {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                        }
                        &::after {
                            border: none;
                        }
                    }
                    .ecFileHolder {
                        .ecFile {
                            @include fileFieldsEdit;
                            display: block;
                        }
                        .spec {
                            @include bookFont($fontSize: 10px);
                            display: inline-block;
                            color: #ccc;
                            cursor: pointer;
                        }
                    }
                }
                .editButtonHolder {
                    @include inlineEditButtons;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .social-container {
        .inner-container {
            .table-heading {
                .engagements {
                    margin-right: 20px;
                }
            }
            .inner-data-container {
                .table-data {
                    .data-dates {
                        width: 15%;
                    }
                }
            }
            .form-container {
                .add-social-campaign {
                    gap: 0px 25px;
                    .actionHolder {
                        width: 45%;
                        margin-right: 0;
                    }
                }
            }
            .edit-form-container {
                .edit-social-campaign {
                    .editActionHolder {
                        .cmrEditInput {
                            .editClicks {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .social-container {
        .inner-container {
            .form-container {
                flex-direction: column;
                .add-social-campaign {
                    .actionHolder {
                        width: 100%;
                        margin-right: 0px;
                        margin-bottom: 0px;
                        &.ecFileHolder {
                            .ecFile {
                                font-size: 10px;
                            }
                            .spec {
                                display: grid;
                            }
                        }
                        @include datePickerMobile;
                    }
                }
                .addButtonHolder {
                    width: 100%;
                    justify-content: flex-end;
                    display: inherit;
                }
            }

            .table-heading {
                @include tableHeadingMobile;
                
                .dates {
                    width: 13%;
                }
                .type {
                    width: 22%;
                }
                .potential-reach {
                    width: 18%;
                }
                .inner-reach {
                    text-align: center;
                    display: block;
                    width: 77%;
                }
                .empty {
                    width: 10%;
                }
                .views {
                    width: 11%;
                }
                .engagements {
                    width: 25%;
                }
                .clicks {
                    width: 10%;
                }
            }
            .inner-data-container {
                .table-data {
                    .data-text {
                        @include tableRowMobile;
                    }
                    .social-campaigns-icons {
                        @include tableIconsMobile;
                    }
                }
                .edit-form-container {
                    .edit-social-campaign {
                        flex-direction: column;
                        .editDateHolder {
                            width: 100%;
                            @include datePickerMobile;
                            .cmrDate {
                                margin-bottom: 10px;
                            }
                        }
                        .editActionHolder {
                            margin-right: 0;
                            padding-left: 0;
                            .cmrEditInput {
                                width: 100%;
                                font-size: 10px;
                                margin-bottom: 10px;
                                ::placeholder {
                                    font-size: 10px;
                                }
                            }
                            @include datePickerMobile;
                            .editType {
                                margin-left: 0;
                            }
                        }
                    }
                    .editFileHolder {
                        flex-direction: column;
                        .actionHolder {
                            &.ecFileHolder {
                                .ecFile {
                                    font-size: 10px;
                                }
                                .spec {
                                    display: grid;
                                }
                            }
                            .cmrUrlInput {
                                font-size: 10px;
                                width: 96%;
                                margin-bottom: 10px;
                                margin-right: 0px;
                                ::placeholder {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                    .editButtonHolder {
                        .actionButton {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
}
