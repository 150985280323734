@import './formMixins';

@mixin fileFields {
    @include fileFieldsBase;
    @include formElement($color: $placeholderColor);
}

@mixin fileFieldsEdit {
    @include fileFieldsBase;
    @include bookFont;
    color: #bbb;
}

@mixin fileFieldsBase {
    cursor: pointer;

    .file {
        display: none !important;
    }
    .fileIcon {
        margin-left: 10px;
    }
    .removeFile {
        border: none;
        background: none;
        padding: 0;
        cursor: pointer;
    }
}
