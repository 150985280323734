@import '../../../../../listingMixins';
.customTab {
    width: 100%;
    .tabs {
        padding: 5px;
        button {
            font-weight: bold;
        }
        .selected {
            border-bottom: 2px solid $goldColor;
        }
    }
    .customTabDelete {
        margin-left: 10px;
    }
    .customMarginTop {
        margin-top: 20px;
    }
    .contentHeader {
        @include mediumFont($fontSize: 14px, $lineHeight: 18px, $color: $sirBlue);
    }
    .inputFields {
        width: 100%;
        @include customInput;
        textarea {
            font-family: 'MercuryTextG4Roman' !important;
        }
    }
    .largeText {
        padding-top: 2px;
        padding-bottom: 1px;
    }
    .smallText {
        padding: 0px 12px;
    }
}
