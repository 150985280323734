@import '../../../../shared/styles/colors';

.scheduleHolder {
    height: 100%;
    .scheduleHeader {
        padding: 20px 40px;
        background-color: $darkBlue;
        color: $whiteColor;
        display: flex;
        justify-content: space-between;
        font-family: 'MercuryTextG4Roman';
        font-size: 18px;
        .backButton {
            background: transparent;
            border: none;
            cursor: pointer;
            height: fit-content;
            .arrow {
                margin-left: 10px;
            }
        }
    }
    .scheduleBody {
        height: calc(100% - 40px);
        padding: 0px 60px 40px 40px;
        background-color: $backgroundGray;
        overflow: auto;
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .scheduleHolder {
        .scheduleHeader {
            padding: 10px;
            font-size: 14px;
        }
        .scheduleBody {
            height: calc(100% - 40px);
            padding: 0px 40px 20px 20px;
        }
    }
}

@media screen and (max-width: 767px) {
    .scheduleHolder {
        .scheduleHeader {
            font-size: 12px;
            padding: 10px;
            // height: 8%;
        }
        .scheduleBody {
            height: calc(100% - 40px);
            padding: 0px 20px 10px 10px;
        }
    }
}
