@import '../../../shared/styles/colors';
@import '../../../shared/styles/typographyMixins';
@import '../../../shared/styles/commonMixins';
@import '../../../shared/styles/drawerMixins';
@import '../../../shared/styles/datePickerMixins';
@import '../../../shared/styles/tableMixins';
@import '../../../shared/styles/formMixins';

@mixin sectionTitle {
    @include mercuryDisplayRoman($sirBlue, 500, 46px, none);
    margin: 80px 0 60px 0;
}

@mixin headline {
    @include mercuryDisplayRoman($sirBlue, normal, 22px, none);
}

@mixin loaderPosition {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin listingButton {
    @include regularFont($sirBlue, normal, 12px, uppercase);
    line-height: 14px;
    letter-spacing: 1px;
    cursor: pointer;
    background-color: $whiteColor;
    border: 1px solid $gold;
}

@mixin listingButtonHover {
    background-color: $sirBlue;
    color: $whiteColor;
    border: 1px solid $sirBlue;
}

@mixin formLabel {
    label {
        margin-left: 0;
        margin-right: 10px;
        @include mediumFont($color: $darkBlue);

        span {
            color: $darkBlue;
            font-weight: bold;
            margin-right: 10px;
        }
    }

    label.disabledClass {
        span {
            color: $infoColor;
        }
    }
}

@mixin subnav {
    @include drawerComponentWidth;

    .nav-link {
        @include mediumFont($fontSize: 12px);
        margin-right: 15px;
        text-decoration: none;
        color: $infoColor;

        &.selected {
            color: $goldColor;
            border-bottom: solid 1px $goldColor;
            padding-bottom: 5px;
        }
    }
}

@mixin blueDropDown {
    .MuiInput-underline:after {
        border-bottom: none;
    }
    .MuiSelect-selectMenu {
        background-color: $darkBlue;
        color: $whiteColor;
        padding: 5px 35px 5px 10px;
    }
    svg {
        color: $whiteColor;
    }
}

@mixin dateFormat {
    @include mediumFont(
        $color: $sirBlue,
        $lineHeight: 20px,
        $letterSpacing: 0.02px,
        $textTransform: uppercase
    );
}

@mixin moreTitleText {
    @include mediumFont($textTransform: uppercase);
}

@mixin statusDetails {
    min-height: 100px;
    max-height: 400px;
    overflow-y: auto;
    width: 100%;

    .status-content {
        .content {
            margin-bottom: 5px;
            .subtitle {
                font-weight: bold;
            }
            .successResult {
                color: #008000;
            }
            .errorResult {
                color: #8b0000;
            }
        }
    }
    .infoText {
        margin: 20px 0px;
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        .button {
            padding: 15px;

            .icon {
                margin: 0px 7px;
            }
            &:nth-child(2n) {
                margin-left: 20px;
            }
        }
    }
}
