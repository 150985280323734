@import '../../../styles/typographyMixins';
@import '../../../styles/colors';
@import '../../../styles/formMixins';
@import '../../../styles/commonMixins';

.filterSelected {
    background-color: $backgroundGray !important;
}
.imageEditorContainer {
    .circularOverlay {
        @include loaderOverlay;
        .saveShowcaseText {
            @include loadingOverlayText;
        }
    }
    .toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $backgroundGray;
        padding: 10px 0px;
        .totalImages {
            font-family: 'MercuryTextG4Roman' !important;
            font-size: 18px;
            color: #000000;
            margin: 0px 20px;
        }
        .utilities {
            @include mediumFont;
            color: #000000;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            .sizes {
                margin: 0px 20px;
                .sizeList {
                    color: $whiteColor;
                    font-family: 'MercuryTextG4Roman' !important;
                    font-size: 14px;
                    background-color: $sirBlue;
                    border-radius: 4px;
                    padding: 0px 10px 0px 15px;
                }
            }
            .filter {
                background-color: $sirBlue;
                color: $whiteColor !important;
                font-family: 'MercuryTextG4Roman' !important;
                font-size: 14px;
                border-radius: 4px;
                margin-right: 20px;
                cursor: pointer;
                .filterText {
                    text-transform: capitalize;
                    font-family: 'MercuryTextG4Roman' !important;
                    font-size: 14px;
                }
                .fileIcon {
                    margin-left: 10px;
                }
            }
            .filterImages {
                color: $whiteColor;
                padding: 3px 10px;
            }
        }
    }
    .noteText {
        padding-top: 10px;
        // width: 70%;
        @include MercuryTextG4Roman();
    }
    .actionBar {
        @include mediumFont;
        color: $darkGray;
        margin: 15px 0px;
        .actionClick {
            text-decoration: underline;
            text-transform: uppercase;
            margin: 0px 20px;
            cursor: default;
        }
        .share {
            margin-left: 0px;
        }
        .actionSelected {
            color: $darkBlue;
            cursor: pointer;
        }
    }
    .actionSection {
        margin-top: 15px;
        .selectionHolder {
            display: flex;
            justify-content: space-between;
            .photoInfo {
                .checkbox:checked {
                    color: $sirBlue;
                }
                .checkbox:hover {
                    background-color: transparent;
                }
                .checkboxLabel {
                    color: $darkGray;
                    font-family: 'MercuryTextG4Roman' !important;
                    font-size: 14px;
                }
            }
            .selections {
                .imagesSelected {
                    color: #000000;
                    font-family: 'MercuryTextG4Roman' !important;
                    font-size: 14px;
                }
                .selectAll {
                    margin: 0px 20px;
                    .checkbox:checked {
                        color: $sirBlue;
                    }
                    .checkbox:hover {
                        background-color: transparent;
                    }
                    .checkboxLabel {
                        color: $darkGray;
                        font-family: 'MercuryTextG4Roman' !important;
                        font-size: 14px;
                    }
                }
                .clearAll {
                    color: $darkGray;
                    font-family: 'MercuryTextG4Roman' !important;
                    font-size: 14px;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-right: 20px;
                }
            }
        }
    }
    .imagesSection {
        margin: 15px 0px;
        .sortHolder {
            .editHolder {
                width: 100%;
                height: 400px;
                margin: 32px 0px 50px 0px;
                display: none;
                &.show {
                    background-color: #00000029;
                    display: block;
                }
            }
        }
        .imageHolder {
            position: relative;
            display: inline-block;
            border: 2px solid $whiteColor;
            margin: 0 18px 18px 0px;
            text-align: center;
            background-color: $backgroundGray;
            &.small {
                height: 135px;
                width: 175px;
            }
            &.medium {
                height: 195px;
                width: 250px;
            }
            &.large {
                height: 250px;
                width: 325px;
            }
            img {
                cursor: pointer;
                width: 100%;
                height: 100%;
                &.portrait {
                    width: auto;
                }
            }
            .imageInfo {
                transition: background-color 0.5s ease;
                position: absolute;
                top: 10%;
                left: 50%;
                transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                text-align: center;
                width: 100%;
                height: 20%;
                .imageDetailTop {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    background-color: rgba(0, 0, 0, 0);
                    color: rgba(0, 0, 0, 0);
                    font-size: 15px;
                    font-family: 'MercuryTextG4Roman' !important;
                    .smallFont {
                        font-size: 10px;
                        .fileIcon {
                            margin: 0px 10px;
                            cursor: move;
                        }
                    }
                    .fileIcon {
                        margin: 0px 20px;
                        cursor: move;
                    }
                }
                .imageDetailTopVisible {
                    background-color: rgba(0, 0, 0, 0.5);
                    color: rgba(255, 255, 255, 1);
                }
            }
            .actionIcons {
                transition: 0s ease;
                top: unset;
                .actionIconsBottom {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-size: 15px;
                    flex-direction: column;
                    justify-content: flex-end;
                    .captionText {
                        display: block;
                        font-family: 'MercuryTextG4Roman' !important;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        margin: 0px 20px;
                    }
                    .captionHolder {
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        color: rgba(255, 255, 255, 1);
                    }
                    .smallFont {
                        font-size: 10px;
                        .captionText {
                            margin: 0px 10px;
                        }
                        .warningText {
                            white-space: unset;
                            overflow: unset;
                            transition: none;
                        }
                    }
                    .mediumFont {
                        font-size: 12px;
                    }
                    .imageActionIconHolder {
                        font-size: 10px;
                        width: 100%;
                        display: none !important;
                        background-color: rgba(0, 0, 0, 0.5);
                        color: $whiteColor;
                        .allIconsHolder {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 3px 0px;
                            .fileIcon {
                                margin: 0px 10px;
                                cursor: pointer;
                            }
                            .mediumFileIcon {
                                padding: 5px 0px;
                                margin: 0px 15px;
                                font-size: 15px;
                            }
                        }
                        .smallFont {
                            font-size: 10px;
                            .captionText {
                                margin: 0px 10px;
                            }
                        }
                        .mediumFont {
                            font-size: 12px;
                        }
                    }
                    .imageActionIconHolderVisible {
                        display: block !important;
                    }
                }
            }
            .numberBadge {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -12px;
                right: -12px;
                background-color: $backgroundGray;
                color: $sirBlue;
                border-radius: 50%;
                width: 25px;
                height: 25px;
            }
            .highlightedBadge {
                background-color: $sirBlue;
                color: $whiteColor;
            }
            .loader {
                position: absolute;
                &.small {
                    top: 35px;
                    left: 55px;
                }
                &.medium {
                    top: 65px;
                    left: 92.25px;
                }
                &.large {
                    top: 93px;
                    left: 130px;
                }
            }
        }
        .selectedImageItem {
            padding: 0px !important;
            border: 2px solid $sirBlue;
        }
        .editHolder {
            width: 100%;
            height: 400px;
            margin: 32px 0px 50px 0px;
            background-color: #00000029;
            min-height: 543px;
        }
    }
    .chooseMainPhoto{
        margin-left: 10px;
    }
    .chooseMainPhoto,
    .saveButton {
        font-family: 'MercuryTextG4Roman';
        font-size: 14px;
        background-color: $sirBlue;
        color: $whiteColor !important;
        border: 1px solid $sirBlue;
        border-radius: 0px;
        &:hover {
            background-color: $sirBlue;
            color: $whiteColor !important;
        }
        &:disabled {
            background-color: $infoColor;
            color: $whiteColor !important;
            border: 1px solid $infoColor;
            border-radius: 0px;
        }
    }
    .saveButton,
    .cancelButton {
        margin: 0px 5px;
    }
    .cancelButton {
        font-family: 'MercuryTextG4Roman';
        font-size: 14px;
        background-color: $whiteColor !important;
        color: $sirBlue !important;
        border: 1px solid $sirBlue;
        border-radius: 0px;
        &:hover {
            background-color: $whiteColor;
            color: $sirBlue !important;
        }
    }
}
.imageEditorContainer {
    .imageEditMenuHolderFullScreen.fromList {
        .fullScreenIcon {
            top: 5%;
            z-index: 10;
        }
    }
}

@media only screen and (max-width: 767px) {
    .imageEditorContainer {
        .toolbar {
            flex-direction: column;
            align-items: unset;
            .totalImages {
                margin-bottom: 10px;
                display: inline-block;
            }
            .utilities {
                display: inline-block;
                align-items: unset;
                flex-direction: column;
                margin: 0px 20px;
                .size-holder {
                    margin-bottom: 10px;
                    display: inline-block;
                }
                .filter {
                    padding: 8px;
                }
            }
        }
        .actionSection {
            .selectionHolder {
                flex-direction: column;
                .selections {
                    text-align: end;
                    .selectAll,
                    .clearAll {
                        margin: unset;
                    }
                }
            }
        }
    }
}
