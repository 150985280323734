@import '../../../agentDashboardMixins';

@include formLabel;

.description-container {
    .section {
        margin: 20px 0px;
        border-bottom: 1px solid $lightGray;

        .content {
            margin-bottom: 10px;

            .formControlRoot {
                width: 80% !important;

                // &.dropDownBaseRoot {
                //     @include customInput;
                // }
                &.inputDisabled {
                    background-color: $backgroundGray;
                }

                .descDate {
                    border: 1px solid $darkGray;
                    border-radius: 10px;
                }

                .ant-picker-input>input {
                    @include bookFont($color: $black);
                }
            }

            .inputBaseRoot {
                @include customInput;

                &.inputDisabled {
                    background-color: $backgroundGray;
                }

                &.dropdownBaseRoot {
                    padding: 0;
                }
            }

            .MuiInputBase-input {
                padding: 8px 0px 8px;
                color: $black;
                background-color: transparent;

                &:disabled {
                    cursor: not-allowed;
                }
            }

            .formControlLabel {
                @include mediumFont($color: $sirBlue, $fontWeight: 400);
            }

            .checkboxLabel {
                @include mediumFont($color: $sirBlue);
            }

            .disabledLabel {
                color: $infoColor;
            }
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .identity-checkbox {
        color: $sirBlue !important;

        &:hover {
            background-color: transparent !important;
        }
    }

    .info-text {
        @include infoText;
    }

    .buttons {
        .button {
            margin-left: 20px;
        }
    }
}