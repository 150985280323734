@import '../../../listingMixins';

@include formLabel;
.mainMenuContainer {
    .heading {
        margin: 40px 0;
    }
    .radioHolder {
        .label {
            @include mediumFont($color: $sirBlue);
        }
    }
    .saleContainer {
        .section {
            margin: 20px 0px;
            border-bottom: 1px solid $lightGray;
            .content {
                margin-bottom: 10px;
                .MuiGrid-grid-md-4 {
                    display: flex !important;
                }
                .formControlRoot {
                    width: 80% !important;
                    &.dropDownBaseRoot {
                        // @include customInput;
                        > div > div {
                            background-color: transparent;
                        }
                    }
                    &.inputDisabled {
                        background-color: $backgroundGray;
                    }
                }
                .exterior-grid {
                    display: flex;
                }
                .exterior {
                    width: 100% !important;
                }
                .inputBaseRoot {
                    @include bookFont($color: $darkGray, $fontWeight: 400);
                    @include customInput;
                    &.inputDisabled {
                        background-color: $backgroundGray;
                    }
                    &.dropdownBaseRoot {
                        padding: 0;
                    }
                }
                .help-icon {
                    padding: 0px;
                    margin: 0px;
                }
                .zip-error-message {
                    position: absolute;
                    bottom: -22px;
                }
                .formControlLabel {
                    @include mediumFont($color: $sirBlue, $fontWeight: 400);
                }
                .addMls {
                    color: $infoColor;
                    text-decoration: underline;
                    cursor: pointer;
                }
                .date {
                    @include mediumFont($color: $sirBlue);
                }
                .dateSelect {
                    .listingDate {
                        width: 80%;
                        border: 1px solid $darkGray;
                        border-radius: 25px;
                        .MuiInput-root {
                            &:hover::before {
                                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                            }
                            input {
                                background-color: $whiteColor;
                                @include bookFont($color: $black, $fontWeight: 400);
                            }
                        }
                        input[type='date']:required:invalid::-webkit-datetime-edit {
                            color: transparent;
                        }
                        input[type='date']:focus::-webkit-datetime-edit {
                            color: $black !important;
                        }
                    }

                    @include datePickerPlaceholder($color: $darkGray);
                    .ant-picker-input > input {
                        @include bookFont($color: $black, $fontWeight: 400);
                    }
                }
                .MuiIconButton-colorSecondary:hover {
                    background-color: transparent;
                }
                .MuiCheckbox-colorSecondary.Mui-checked {
                    color: $sirBlue;
                }
                .checkboxLabel {
                    @include mediumFont($color: $sirBlue);
                }
                .disabledLabel {
                    color: $infoColor;
                }
            }
            .tooltipContent {
                position: relative;
                .listingDetailTooltip {
                    margin: 0px 30px;
                    cursor: pointer;
                    color: $infoColor;
                    position: absolute;
                    right: 0;
                }
            }
        }
        .endSection {
            border-bottom: none;
        }
    }
    .info-text {
        @include infoText;
    }
}
.ant-picker-dropdown {
    z-index: 7056 !important;
}
.datePickerCommon {
    @include bookFont($color: $darkGray);
    border: none;
    background: none;
    outline: none;
    box-shadow: none;
    width: 100%;
    padding: 4px 0px;
}

@media only screen and (max-width: 767px) {
    .mainMenuContainer {
        .saleContainer {
            .section {
                .content {
                    .formControlRoot {
                        width: 95% !important;
                    }
                }
                .tooltipContent {
                    .listingDetailTooltip {
                        margin: unset;
                        bottom: 15px;
                    }
                }
            }
        }
    }
}
