@import '../../../../../listingMixins';

.chip-list {
    .MuiChip-root {
        background-color: $whiteColor;
        margin: 0px 10px 10px 0px !important;
        border: solid 1px $darkBlue;
        @include regularFont($sirBlue, 400, 14px, none);
        &.selected {
            color: $whiteColor;
            background-color: $sirBlue20;
        }

        .MuiChip-deleteIcon {
            color: $whiteColor;
        }
    }

    .header-menu {
        background-color: $backgroundGray;
        @include mediumFont($color: $sirBlue, $fontWeight: 400);
        margin: 20px 0;
        padding: 10px 20px;

        .links-container {
            float: right;

            span.link {
                margin-right: 20px;
                a {
                    color: $darkBlue;
                }
            }
        }
    }
    .content {
        width: 100%;
    }
    .closeIcon {
        cursor: pointer;
    }
}
