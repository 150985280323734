@import "../../../shared/styles/colors";

$sirDarkBlue: $darkBlue;

.headerHolder {
    background-color: $sirDarkBlue;
    margin: auto;
    max-width: 1300px;
    .header {
        margin: auto;
        max-width: 1300px;
        height: 100px;
        padding: 24px 32px 23px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
            width: auto;
            height: 50px;
        }
        .rightSide {
            display: flex;
            .headerSearch {
                padding-right: 50px;
                position: relative;
                input.search {
                    margin-top: 10px;
                    width: 310px;
                    background-color: $sirDarkBlue;
                    border: none;
                    border-bottom: 1px solid $whiteColor;
                    padding-left: 25px;
                    color: $infoColor;
                    font-size: 16px;
                    padding-bottom: 10px;
                    font-family: "MercuryTextG4Roman";
                    &:focus {
                        outline: none;
                    }
                    &::placeholder {
                        padding-left: 10px;
                    }
                }
                .icon {
                    position: absolute;
                    font-size: 20px;
                    top: 8px;
                    &.arrowRight {
                        right: 50px;
                    }
                }
                .iconColor {
                    color: $infoColor;
                }
            }
            .headerActions {
                .username {
                    color: $whiteColor;
                    font-size: 30px;
                    font-family: "FreigBigProBook";
                }
                .icon {
                    padding-right: 40px;
                    font-size: 30px;
                    position: relative;
                }
                .notificationCount {
                    position: absolute;
                    color: $goldColor;
                    font-size: 16px;
                    right: 35px;
                    top: -18px;
                }
            }
            .iconColor {
                color: $whiteColor;
            }
        }
    }
    .mobileSearchOverlay {
        display: none;
    }
    .hide {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .headerHolder {
        .header {
            .rightSide {
                .headerSearch {
                    padding-right: 20px;
                    input.search {
                        width: 250px;
                    }
                    .icon {
                        &.arrowRight {
                            right: 20px;
                        }
                    }
                }
                .headerActions {
                    .icon {
                        padding-right: 20px;
                    }
                    .notificationCount {
                        right: 15px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .headerHolder {
        .header {
            padding: 24px 20px 23px 20px;
            .logo {
                height: 40px;
            }
            .rightSide {
                .headerSearch {
                    padding-right: 20px;
                    .icon {
                        top: 0;
                        position: inherit;
                        font-size: 21px;
                    }
                    .search,
                    .arrowRight,
                    .search {
                        display: none;
                    }
                    .closeIcon {
                        font-size: 23px;
                    }
                }
                .headerActions {
                    .username {
                        font-size: 23px;
                    }
                    .icon {
                        font-size: 23px;
                        padding-right: 20px;
                        .notificationCount {
                            right: 14px;
                        }
                    }
                }
            }
        }
        .mobileSearchOverlay {
            border-top: 2px solid $goldColor;
            padding: 24px 32px;
            display: block;
            justify-content: space-between;
            .headerSearch {
                position: relative;
                width: 100%;
                input.search {
                    margin-top: 10px;
                    width: 100%;
                    background-color: $sirDarkBlue;
                    border: none;
                    border-bottom: 1px solid $whiteColor;
                    padding-left: 25px;
                    color: $infoColor;
                    font-size: 16px;
                    padding-bottom: 10px;

                    font-family: "MercuryTextG4Roman";

                    &:focus {
                        outline: none;
                    }
                    &::placeholder {
                        padding-left: 10px;
                    }
                }
                .icon {
                    position: absolute;
                    font-size: 20px;
                    top: 8px;
                    color: $infoColor;
                    &.arrowRight {
                        right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 360px) {
    .headerHolder {
        .header {
            padding: 20px;
            .logo {
                height: 30px;
            }
            .rightSide {
                .headerSearch {
                    .icon {
                        font-size: 18px;
                    }
                    .closeIcon {
                        font-size: 18px;
                    }
                }
                .headerActions {
                    .username {
                        font-size: 18px;
                    }
                    .icon {
                        font-size: 18px;
                        padding-right: 18px;
                        .notificationCount {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
