@import '../../../../styles/colors';
@import '../../../../styles/commonMixins';

.downloadContainer {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $goldColor;
    display: flex;
    .buttonsHolder {
        display: flex;
        align-items: center;
        width: 60%;
        .sizeText {
            font-family: 'MercuryTextG4Roman' !important;
            font-size: 16px;
            color: #000000;
            margin-right: 10px;
        }
        .sizeButton {
            color: $sirBlue;
            background-color: $whiteColor;
            border: 1px solid $sirBlue;
            margin-right: 10px;
            font-family: 'MercuryTextG4Roman';
            font-size: 14px;
            padding: 6px 10px;
            border-radius: 4px;
            cursor: pointer;
        }
        .sizeButtonSelected {
            color: $whiteColor;
            background-color: $sirBlue;
        }
    }
    .submitButtonHolder {
        display: flex;
        justify-content: flex-end;
        width: 40%;
        .actionButton {
            font-family: 'MercuryTextG4Roman';
            font-size: 14px;
            padding: 6px 10px;
            border-radius: 4px;
            border: 1px solid $sirBlue;
            color: $whiteColor;
            cursor: pointer;
            &:first-child {
                margin-right: 10px;
            }
        }
        .cancel {
            color: $sirBlue;
            background-color: $whiteColor;
        }
        .submitEnabled {
            margin-right: 15px;
            background-color: $sirBlue;
        }
    }
    .circularOverlay {
        @include loaderOverlay;
        .downloadingText {
            @include loadingOverlayText;
        }
    }
}
