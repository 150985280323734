@import '../../../agentDashboardMixins';

.add-assistant-container {
    .email-dropdown-field {
        > div > div > input {
            @include bookFont(14px, 400, $black);
        }
    }
    .content {
        margin-bottom: 30px;
        .formControlLabel {
            @include mediumFont(
                $color: $sirBlue,
                $fontWeight: 400,
                $textTransform: uppercase
            );
        }
        .formControlData {
            @include regularFont($darkGray, 400, 14px, none);
            word-wrap: break-word;
        }
    }
}
