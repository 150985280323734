@import '../../../../../../shared/styles/commonMixins';
.identity-container {
    .identity-form-control {
        width: 80%;
        > div > div {
            background-color: transparent;
        }
        .identity-input,
        .identity-drop-down {
            @include customInput;
        }
        .identity-text-field {
            &.inputDisabled {
                background-color: $backgroundGray;
                .identity-input {
                    background-color: inherit;
                }
            }
        }
    }
}
