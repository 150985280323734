@import '../../../listingMixins';

@mixin goldBottomBorder {
    border-bottom: solid 1px $goldColor;
    margin-bottom: 40px;
    padding-bottom: 30px;
}

form {
    .top-controls-container {
        @include goldBottomBorder;

        .MuiFormControl-root.form-controls-container {
            flex-direction: row;
            @include formLabel;
        }

        .language-container {
            padding-right: 40px;
            align-items: center;
            display: flex;
            @include blueDropDown;
        }

        .suppressmls-container {
            text-align: right;

            @include formLabel;
        }
    }

    .size-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        .size-title {
            color: $darkBlue;
            font-weight: bold;
            font-size: 16px;
            margin-right: 20px;
        }
    }

    .size-nav-container {
        margin-top: 20px;
        margin-bottom: 40px;

        @include subnav;
    }

    .headline {
        // @include roundedInput($padding: 1px 8px, $backgroundColor: $whiteColor);
        margin-bottom: 45px;
        // border: 1px solid $darkGray;
        // border-radius: 25px;
        @include customInput;
    }
}

@media only screen and (max-width: 767px) {
    form {
        .top-controls-container {
            .suppressmls-container {
                text-align: unset;
                margin-top: 10px;
            }
        }
        .size-nav-container {
            .nav-link {
                margin-bottom: 10px;
                display: inline-block;
            }
        }
    }
}
