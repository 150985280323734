@import '../../../shared/styles/commonMixins';

.officeDvManagePage {
  padding: 0 30px;
  background-color: #fff;

  .templateHolder {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
  }

  .officeDvHeading {
    text-align: left;
    font-family: FreigBigProBook;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    color: rgba(0,43,92,1);
    padding: 20px 0 5px 0;
  }

  .officeDvSubhead {
    font-size: 18px;
    color: #999999;
    font-style: normal;
    text-align: left;
    font-family: FreigBigProBook;
    font-weight: normal;
    margin-bottom: 10px;
  }

  .templateWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .customSwitch {
    @include customSwitch;
    margin: -40px 0 40px -40px;
  }
}
