@import '../../../salesTeamMixins';

.members-container {
    margin: 15px 0px;

    .sales-team-note {
        @include mediumFont(16px, '', $darkGray, '', none, normal);

        &.dragNote {
            margin-top: 20px;
            color: $sirBlue;
        }
    }

    .search-dropdown-field {
        margin: 15px 0px;


        >div>div>input {
            @include bookFont(14px, 400, $darkGray);
        }

        .MuiInputBase-input {
            padding: 8px 0px 8px !important;
            color: $black;
        }
    }

    .sales-team-agents-container {
        margin: 15px 0px;

        .sales-team-agent-header {
            @include mediumFont(14px, 0px, $sirBlue, 0px, uppercase, normal);
        }

        .dnd-grid-item-container {
            cursor: move;

            .dnd-grid-item {
                @include mediumFont(14px, 0px, $darkGray, 0px, none, normal);
            }

            .delete-button {
                margin: 0px 15px;
                padding: 0px;
                font-family: 'BentonSansMedium';
                color: $darkGray;
            }
        }
    }
}