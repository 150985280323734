@import '../../../../../styles/colors';
@import '../../../../../styles/formMixins';

.tagsSection {
    height: 100%;
    .chipsContainer {
        height: 80%;
        max-height: 75px;
        overflow: overlay;
        margin-bottom: 15px;
        .MuiChip-root {
            margin-right: 10px !important;
            margin-top: 15px !important;
            border: solid 1px $sirBlue;

            &.selected {
                color: $whiteColor;
                background-color: $sirBlue20;
            }

            .MuiChip-deleteIcon {
                color: $whiteColor;
            }
        }
    }
    .noTags {
        font-family: 'MercuryTextG4Roman';
        font-size: 18px;
    }
    .buttonsHolder {
        @include inlineEditButtons;
        background-color: $whiteColor;
    }
}
.tagsSectionFullScreen {
    .chipsContainer {
        max-height: 240px;
        height: 75%;
    }
}
