@import './colors';

@mixin drawer($width: 85%, $backGround: $backgroundGray) {
    .MuiDrawer-paperAnchorRight {
        width: $width;

        .form-content {
            padding: 20px 0 0 20px;
            background-color: $backGround;
            height: 100%;
        }
    }
}

@mixin drawerComponentWidth {
    width: 100%;
}

@mixin headerText {
    color: $whiteColor;
    font-size: 18px;
    font-family: 'MercuryTextG4Roman';
}

@mixin header {
    @include headerText;
    background-color: $darkBlue;
    display: flex;
    justify-content: space-between;
    padding: 20px;

    .back-button {
        @include headerText;
        background: transparent;
        border: none;
        cursor: pointer;
        height: fit-content;
        .arrow {
            margin-left: 10px;
        }
    }
}
