@import '../../../../listingMixins';

label {
    margin-left: 0 !important;
}
.showcaseSiteDetails {
    .domainGrid {
        margin: 0 0 20px 0;
        border-bottom: 1px solid $infoColor;
        .domainGrid-holder {
            margin-bottom: 20px;
        }
        .domainHeader {
            margin-bottom: 5px;
            @include mediumFont($fontSize: 14px, $lineHeight: 18px, $color: $sirBlue);
            display: flex;
            align-items: flex-end;
            min-height: 36px;
        }
        .domainBody {
            margin-bottom: 20px;
            font-family: 'MercuryTextG4Roman';
            font-size: 14px;
            color: $darkGray;
            word-break: break-all;
        }
        .domainActionButton {
            @include mediumFont($fontSize: 13px);
            color: $goldColor;
            padding: 0px;
            &:hover {
                background-color: transparent !important;
            }
            &:last-child {
                margin-left: 5px;
            }
        }
    }
    .fields {
        margin-top: 20px;
        padding-bottom: 30px;
        .showPrice {
            display: inherit;
        }
        .infoText {
            font-family: 'MercuryTextG4Roman';
            font-size: 12px;
            color: $infoColor;
            // padding-left: 20px;
        }

        .radioHolder {
            .showcaseRadio {
                margin-bottom: 20px;
                &.Mui-disabled {
                    span {
                        color: $infoColor;
                    }
                }
            }
        }

        .spacing {
            margin-top: 30px;
            &.smallSection {
                align-items: center;
            }
            &.customMarginTop {
                margin-top: 20px;
            }
        }

        .showPriceLabel,
        .showcaseRadio {
            .MuiTypography-body1 {
                font-family: 'MercuryTextG4Roman' !important;
                color: $darkGray;
            }
        }
    }
    .contentHeader {
        @include mediumFont($fontSize: 14px, $lineHeight: 18px, $color: $sirBlue);
    }
    .contentBody {
        letter-spacing: 0.02px;
        font-family: 'MercuryTextG4Roman';
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
    }
    .formControlRoot {
        width: 100%;
    }
    .inputFields {
        width: 100%;
        @include customInput;
        textarea {
            font-family: 'MercuryTextG4Roman' !important;
        }
    }
    .largeText {
        padding-top: 2px;
        padding-bottom: 1px;
    }
    .smallText {
        padding: 0px 12px;
    }
    .privateSite {
        .title {
            margin-top: 20px;
            padding-bottom: 30px;
            @include mediumFont($fontSize: 16px);
            color: $sirBlue;
        }
        .subTitle {
            margin-top: 20px;
        }
        .subTitle,
        .isPrivate,
        .isAutoGenerateCode {
            font-family: 'MercuryTextG4Roman';
            font-size: 12px;
            color: $infoColor;
            margin-bottom: 10px;
        }
        .isPrivate,
        .isAutoGenerateCode,
        .showcaseRadio {
            .MuiTypography-body1 {
                font-family: 'MercuryTextG4Roman' !important;
                color: $darkGray;
            }
        }
    }
    .generateAccessCodeButton {
        margin: 20px 5px;
        font-family: 'MercuryTextG4Roman';
        font-size: 15px;
        background-color: $sirBlue;
        color: $whiteColor !important;
        border: 1px solid $sirBlue;
        border-radius: 0px;
        &:hover {
            background-color: $sirBlue;
            color: $whiteColor !important;
        }
        &:disabled {
            background-color: $infoColor;
            color: $whiteColor !important;
            border: 1px solid $infoColor;
            border-radius: 0px;
        }
    }
    // .customTab {
    //     .tabs {
    //         padding: 5px;
    //         .selected {
    //             border-bottom: 2px solid #b28e3a;
    //         }
    //     }
    //     .customTabDelete {
    //         margin-left: 10px;
    //     }
    //     .customMarginTop {
    //         margin-top: 20px;
    //     }
    // }
    .showcaseVideoContainer {
        position: relative;
        .loaderClass {
            position: absolute;
            width: 100%;
        }
    }
    .showcaseTab {
        width: 100%;
    }
}

@media only screen and (max-width: 1023px) {
    .showcaseSiteDetails {
        .domainGrid {
            .domainActionButton:last-child {
                margin-left: unset;
            }
            .domainHeader {
                min-height: 54px;
            }
        }
    }
}

@media only screen and (max-width: 426px) {
    .showcaseSiteDetails {
        .domainGrid {
            .domainHeader {
                min-height: unset;
            }
        }
    }
}
