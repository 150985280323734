@import '../../../developmentDetailsMixins';

.developmentAssociatedListingEdit {
    .title-text {
        @include mediumFont($fontSize: 14px);
        margin: 20px 0 10px;
    }
    .headline {
        @include mediumFont($fontSize: 14px);
    }
    .customMultiSelectAutocomplete {
        @include customMultiSelectAutocomplete;
        &.fullWidth {
            width: 100% !important;
        }
    }
    .marginTop20 {
        margin-top: 20px;
    }
}
