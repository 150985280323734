@import './colors';
@import './commonMixins';

@mixin formFields {
    form {
        input[type='text'],
        .MuiSelect-select {
            @include roundedInput($padding: 10px 10px);
        }
    }
}

@mixin customCheckbox {
    .customCheckbox {
        appearance: none;
        background-color: $whiteColor;
        margin: 0;
        font: inherit;
        color: $infoColor;
        width: 1.1em;
        height: 1.1em;
        border: 0.15em solid $infoColor;
        transform: translateY(-0.075em);
        display: inline-grid;
        place-content: center;
        cursor: pointer;

        &::before {
            content: '';
            width: 0.65em;
            height: 0.65em;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $sirBlue;
        }

        &:checked::before {
            transform: scale(1);
        }
    }
}

@mixin formElement($color: #333, $fontSize: 15px) {
    color: $color;
    font-family: 'MercuryTextG4Roman' !important;
    font-size: $fontSize;
}

@mixin inlineFormInput(
    $color: #333,
    $fontSize: 15px,
    $borderBottom: 1px solid rgba(0, 0, 0, 0.42)
) {
    width: 100%;
    @include formElement($color, $fontSize);

    &:hover::before {
        border-bottom: $borderBottom;
    }

    &::after {
        border: none;
    }
}

@mixin roundedInput(
    $color: $darkGray,
    $padding: 5px 22px,
    $backgroundColor: $whiteColor
) {
    @include formElement($color: $color, $fontSize: 14px);
    background-color: $backgroundColor;
    border-radius: 4px;
    padding: $padding;
}

@mixin inlineFormButton($color: $sirBlue) {
    @include formElement($color: $whiteColor);
    width: 80px;
    text-transform: uppercase;
    padding: 7px;
    border: 1px solid $color;
    cursor: pointer;
    background-color: $color;
}

@mixin inlineAddButton {
    .addButtonActive,
    .active {
        @include inlineFormButton($color: $infoColor);
    }

    .addButtonDisabled,
    .unActive {
        @include inlineFormButton;
    }
}

@mixin inlineEditButtons {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    margin-top: 30px;

    .actionButton {
        width: 80px;
        text-transform: uppercase;
        padding: 5px;
        border: 1px solid $sirBlue;
        cursor: pointer;
        font-family: 'MercuryTextG4Roman' !important;
        font-size: 15px;

        &:first-child {
            margin-right: 25px;
        }
    }

    .saveActive {
        border: 1px solid $sirBlue;
        background-color: $sirBlue;
        color: $whiteColor;
    }

    .saveInActive {
        border: 1px solid $infoColor;
        background-color: $infoColor;
        color: $whiteColor;
        cursor: default;
    }

    .cancel {
        color: $sirBlue;
        background-color: $whiteColor;
    }
}

@mixin customButton {
    .customButton {
        padding: 10px;
        font-family: 'BentonSansMedium';
        font-size: 12px;
        color: $whiteColor;
        text-transform: uppercase;
        line-height: 14px;
        letter-spacing: 1px;
        cursor: pointer;
        background-color: $sirBlue;
        border: 1px solid $sirBlue;

        &:not(.disable):hover {
            background-color: $gold;
            color: $whiteColor;
            border: 1px solid $gold;
        }

        &:disabled {
            background-color: $darkGray;
            color: $whiteColor;
            border: 1px solid transparent;
        }

        &:disabled:hover {
            background-color: $darkGray;
            // border: none;
        }

        &.iconHolder {
            padding: 15px;

            .icon {
                margin: 0px 7px;
            }
        }
    }
}

/* Custom Multi Select With Search Autocomplete Component */

@mixin customMultiSelectAutocomplete {
    width: 80% !important;

    > * > div {
        border-radius: 10px;
        border: 1px solid $darkGray;
        padding: 7px !important;
    }

    fieldset {
        border-color: transparent !important;
        border-width: 1px !important;
    }

    input {
        padding: 0 !important;
    }
}

@mixin customSearchAutoComplete {
    .formControlRoot {
        width: 80%;
        @include customInput;

        > div > div {
            background-color: transparent;
        }

        &.dropDownBaseRoot {
            @include customInput;
        }
    }

    .MuiInputBase-input {
        padding: 8px 0px 8px;
        color: $black;

        &:disabled {
            cursor: not-allowed;
            color: $whiteColor;
        }
    }
}

@mixin customChips {
    .MuiChip-root {
        background-color: $whiteColor;
        margin: 0px 10px 10px 0px;
        border: solid 1px $sirBlue;
        @include regularFont($sirBlue, 400, 14px, none);
        &.selected {
            color: $whiteColor;
            background-color: $sirBlue20;
        }
        .MuiChip-deleteIcon {
            color: $whiteColor;
        }
    }
}
