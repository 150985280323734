@import '../../../cmrMixins.scss';

.adv-container {
    .inner-container {
        padding: 0;
        .data-container {
            .table-heading {
                @include tableHeading($padding: 18px 15px 18px 0, $fontColor: $infoColor);
                width: 100%;
                .col-1 {
                    display: flex;
                    width: 80%;
                    .date {
                        width: 26%;
                    }
                    .publications {
                        width: 50%;
                    }
                }
                .heading-text {
                    width: 10%;
                }
            }
            .inner-data-container {
                overflow: overlay;
                width: 100%;
                .table-data {
                    @include tableRow;
                    width: 100%;

                    .data-col-1 {
                        display: flex;
                        width: 80%;
                        .data-date {
                            width: 26%;
                            letter-spacing: 0.02px;
                            @include dateFormat;
                            line-height: 20px;
                        }
                        .data-publications {
                            width: 50%;
                            letter-spacing: 0px;
                            @include bookFont;
                            color: $sirBlue;
                            line-height: 18px;
                        }
                    }
                    .data-links {
                        width: 10%;
                        letter-spacing: 0px;
                        @include bookFont;
                        color: $sirBlue;
                        line-height: 18px;
                        text-decoration: underline;
                        a,
                        a:visited {
                            color: $darkBlue;
                        }
                    }
                }
                .adv-icons {
                    @include tableIcons;
                }
            }
            .form-container {
                padding: 10px;
                height: auto;
                background-color: $backgroundGray;
                display: flex;
                align-items: center;
                box-shadow: 0px 1px 4px $infoColor;
                .add-showing {
                    display: flex;
                    flex-wrap: wrap;
                }
            }
            .adv-container-inner.adv-edit {
                .form-container {
                    background-color: $whiteColor;
                    z-index: 1000;
                    position: relative;
                    padding: 10px;
                    flex-direction: column;
                    .add-showing {
                        display: flex;
                        .actionHolder {
                            width: 17%;

                            &:last-child {
                                width: 28%;
                            }
                        }
                    }
                    .editButtonHolder {
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        margin-top: 40px;
                        .actionButton {
                            padding: 5px;
                        }
                        .cancel {
                            color: $sirBlue;
                            background-color: $whiteColor;
                            margin-right: 10px;
                            border: 1px solid $sirBlue;
                        }
                    }
                }
            }
        }
        .actionHolder {
            margin-right: 20px;
            width: 30%;
            margin-bottom: 10px;
            .cmrInput {
                @include inlineFormInput;
            }
            @include datePickerInput;
            @include dateField;
            &.advFileHolder {
                .advFile {
                    @include fileFields;
                }
                .spec {
                    @include specText;
                }
            }
        }
        .addButtonHolder {
            @include inlineAddButton;
        }
        .editButtonHolder {
            @include inlineEditButtons;
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .adv-container {
        .inner-container {
            .form-container {
                .add-showing {
                    gap: 0px 25px;

                    .actionHolder {
                        width: 45%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .adv-container {
        .inner-container {
            .data-container {
                .form-container {
                    flex-direction: column;
                    .add-showing {
                        width: 100%;
                        .actionHolder {
                            width: 100%;
                            margin-right: 0;
                            @include datePickerMobile;
                            &.advFileHolder {
                                .advFile {
                                    font-size: 10px;
                                }
                                .spec {
                                    display: grid;
                                }
                            }
                        }
                    }
                    .addButtonHolder {
                        width: 100%;
                        justify-content: flex-end;
                        display: inherit;
                    }
                }
                .table-heading {
                    .col-1 {
                        @include tableHeadingMobile;

                        .date {
                            width: 35%;
                        }
                    }
                    .heading-text {
                        font-size: 9px;
                    }
                }
                .inner-data-container {
                    .table-data {
                        .data-col-1 {
                            .data-date,
                            .data-publications {
                                @include tableRowMobile;
                            }
                            .data-date {
                                padding-right: 10px;
                                width: 35%;
                            }
                            .data-publications {
                                width: 60%;
                            }
                        }
                        .data-links {
                            width: 14%;
                            font-size: 10px;
                            word-break: break-word;
                            line-break: unset;
                        }
                        .adv-icons {
                            @include tableIconsMobile;
                        }
                    }
                }

                .adv-container-inner.adv-edit {
                    .form-container {
                        .add-showing {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            .actionHolder {
                                width: 100%;

                                &:last-child {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
