@import '../../styles/typographyMixins';
@import '../../styles/colors';

.photoContainer {
    position: relative;
    height: 100%;
    .loaderClass {
        position: absolute;
        width: 100%;
    }
    .info-text {
        @include infoText;
        margin-bottom: 20px;
    }
    .imageSection {
        width: 343px;
        height: 457px;
        img {
            width: 100%;
            height: inherit;
            border: 1px solid $sirBlue;
        }
    }
    section {
        width: 60%;
        text-align: center;
        height: 200px;
        border: 2px $infoColor dashed;
        border-radius: 25px;
        margin-bottom: 20px;
        p {
            @include regularFont($sirBlue, 400, 14px, none);
            height: 120px;
            padding: 20px;
        }
    }
    .button {
        padding: 15px;
        .icon {
            margin: 0px 7px;
        }
    }
    .deleteHolder {
        margin-top: 20px;
        display: flex;
        justify-content: flex-start;
    }
    .errorRow {
        @include regularFont($sirBlue, 400, 14px, none);
        color: #ff0000;
        margin-top: 20px;
    }

    .imageController {
        position: relative;
        background-color: $backgroundGray;
        width: inherit;
        height: inherit;
        display: flex;
        align-content: center;
        flex-wrap: wrap;
    }

    .bottomBar {
        position: absolute;
        height: 40px;
        background-color: #000000;
        width: 100%;
        bottom: 0;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        .bottomActions {
            font-size: 30px;
            color: $whiteColor;
            opacity: 1;
            .icon {
                margin: 0px 20px;
                cursor: pointer;
            }
        }
    }
}
