@import '../../../cmrMixins.scss';

.news-container {
    .inner-container {
        padding: 10px 10px 0px 0px;
        .newsNotes {
            width: 100%;
            @include bookFont;
            color: $infoColor;
            margin-left: 10px;
            position: relative;
            .info {
                font-size: 12px;

                position: absolute;
                top: 4px;
                left: -10px;
            }
        }
        .placements {
            @include mediumFont(
                $lineHeight: 17px,
                $color: $sirBlue,
                $letterSpacing: 0.02px
            );

            padding: 15px 0px 15px 0px;
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $lightGray;
        }
        .data-container {
            .news {
                padding: 25px 0px 10px 0px;
                width: 40%;
                .date {
                    @include dateFormat;
                }
                .description,
                .publication {
                    letter-spacing: 0px;
                    @include bookFont;
                    color: $sirBlue;
                    line-height: 30px;
                    padding: 0px 0px 7px 0px;
                }
                .publicationName {
                    padding: 15px 0px 7px 0px;
                }
                a {
                    text-decoration: none;
                    .link {
                        letter-spacing: 0px;
                        @include bookFont;
                        color: $sirBlue;
                        line-height: 30px;
                        position: relative;
                        .arrow {
                            color: #707070;
                            margin: 0px 10px;
                            font-size: 10px;
                            position: absolute;
                            top: 5px;
                        }
                    }
                }
            }
            .border {
                background-color: $lightGray;
                margin: 0;
                border-width: 0.2px;
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .news-container {
        .inner-container {
            .data-container {
                .news {
                    width: 80%;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .news-container {
        .inner-container {
            .newsNotes {
                font-size: 9px;
                margin-left: 10px;
                .info {
                    font-size: 9px;
                    top: 2px;
                }
            }
            .data-container {
                .news {
                    width: 80%;
                    .date {
                        font-size: 9px;
                    }
                    .description,
                    .publication {
                        font-size: 12px;
                    }
                    a {
                        .link {
                            font-size: 12px;
                            position: relative;
                            .arrow {
                                font-size: 9px;
                                position: absolute;
                                top: 5px;
                                left: 90px;
                            }
                        }
                    }
                }
            }
        }
    }
}
