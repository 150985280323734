@import '../../../../styles/colors';
@import '../../../../styles/formMixins';
@import '../../../../styles/commonMixins';
@import '../../../../styles/typographyMixins';

.shareDetailsContainer {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $goldColor;
    .actionHolder {
        .content{
            margin-bottom: 10px;
            .shareInput{
                width: 95% !important;
            }
            .shareInputMessage{
                width: 98% !important;
            }
            .inputBaseRoot {
                @include bookFont($color: $darkGray, $fontWeight: 400);
                @include customInput;
            }
        }
    }
    .buttonsHolder {
        margin-top: 15px;
        display: flex;
        .sizeButtonHolder {
            display: flex;
            align-items: center;
            width: 60%;
            .sizeText {
                font-family: 'MercuryTextG4Roman' !important;
                font-size: 16px;
                color: #000000;
                margin-right: 10px;
            }
            .sizeButton {
                color: $sirBlue;
                background-color: $whiteColor;
                border: 1px solid $sirBlue;
                margin-right: 10px;
                font-family: 'MercuryTextG4Roman';
                font-size: 14px;
                padding: 6px 10px;
                border-radius: 4px;
                cursor: pointer;
            }
            .sizeButtonSelected {
                color: $whiteColor;
                background-color: $sirBlue;
            }
        }
        .submitButtonHolder {
            display: flex;
            justify-content: flex-end;
            width: 40%;
            .actionButton {
                font-family: 'MercuryTextG4Roman';
                font-size: 14px;
                padding: 6px 10px;
                border-radius: 4px;
                border: 1px solid $sirBlue;
                color: $whiteColor;
                cursor: pointer;
                &:first-child {
                    margin-right: 10px;
                }
            }
            .cancel {
                color: $sirBlue;
                background-color: $whiteColor;
            }
            .submitDisabled {
                background-color: $infoColor;
                border: 1px solid $infoColor;
                margin-right: 15px;
                cursor: default;
            }
            .submitEnabled {
                margin-right: 15px;
                background-color: $sirBlue;
            }
        }
    }
    .circularOverlay {
        @include loaderOverlay;
        .sharingText {
            @include loadingOverlayText;
        }
    }
}
