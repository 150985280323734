@import '../../../cmrMixins.scss';

@mixin showingFields {
    display: flex;
    flex-wrap: wrap;
    .actionHolder {
        margin-right: 20px;
        width: 30%;
        margin-bottom: 10px;
        .cmrInput {
            @include inlineFormInput;
        }
        @include datePickerInput;
        @include dateField;
    }
}

.showings-container-cmr {
    .data-container {
        .form-container {
            padding: 10px;
            height: auto;
            background-color: $backgroundGray;
            display: flex;
            align-items: center;
            box-shadow: 0px 1px 4px $infoColor;
            .add-showing > div.formFields {
                @include showingFields;
            }

            .addButtonHolder {
                float: right;

                @include inlineAddButton;
            }
        }
        .table-heading {
            @include tableHeading($fontColor: $infoColor);
            .heading-text {
                width: 17.5%;
            }
            .empty {
                width: 10%;
            }
        }
        .inner-data-container {
            overflow: overlay;
            .table-data {
                @include tableRow($display: inherit);

                .rows {
                    .row-1 {
                        display: flex;
                        justify-content: space-between;
                        .data-text {
                            letter-spacing: 0px;
                            @include bookFont;
                            color: $sirBlue;
                            line-height: 18px;
                            width: 17.5%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                        .date {
                            @include dateFormat;
                        }
                        .notes {
                            width: 17.5%;
                            padding-left: 10px;
                        }
                        .showings-icons {
                            @include tableIcons;
                        }
                    }
                    .row-2 {
                        .notes-container {
                            width: 50%;
                            margin: auto;
                            padding: 15px 0px 20px 0px;
                            .notes-row {
                                display: flex;
                                justify-content: space-between;
                                align-items: baseline;
                            }
                            .notes-title-text {
                                letter-spacing: 0.02px;
                                text-transform: uppercase;
                                @include bookFont;
                                color: $sirBlue;
                                line-height: 14px;
                            }
                            .notes-content {
                                width: 60%;
                                @include bookFont;
                                color: $sirBlue;
                                line-height: 30px;
                                letter-spacing: 0px;
                            }
                            .empty-content {
                                height: 27px;
                            }
                        }
                    }
                }
                .showingsEditContainer {
                    background-color: $whiteColor;
                    z-index: 1000;
                    position: relative;
                    padding: 10px;
                    overflow: auto;

                    .formFields {
                        @include showingFields;

                        .actionHolder {
                            width: 20%;
                        }
                    }

                    .buttonGroup {
                        @include inlineEditButtons;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .showings-container-cmr {
        .data-container {
            .form-container {
                .add-showing {
                    gap: 0px 25px;
                    .actionHolder {
                        width: 45%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .showings-container-cmr {
        .data-container {
            .form-container {
                .add-showing {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .showings-container {
        .data-container {
            .form-container {
                .add-showing > div.formFields {
                    flex-direction: column;
                    .actionHolder {
                        width: 100%;
                        margin-right: 0;
                        @include datePickerMobile;
                    }
                }
                .addButtonHolder {
                    justify-content: flex-end;
                    display: inherit;
                }
            }
            .table-heading {
                @include tableHeadingMobile;
            }
            .inner-data-container {
                .table-data {
                    .rows {
                        .row-1 {
                            .data-text {
                                @include tableRowMobile;
                            }
                            .showings-icons {
                                .icon-container {
                                    @include tableIconsMobile;
                                }
                            }
                        }
                        .row-2 {
                            .notes-container {
                                margin: auto;
                                padding: 15px 0px 20px 0px;
                                .notes-row {
                                    .notes-title-text {
                                        font-size: 10px;
                                    }
                                    .notes-content {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .showingsEditContainer {
                        .formFields {
                            display: flex;
                            flex-direction: column;
                            .actionHolder {
                                width: 100%;
                                @include datePickerMobile;
                            }
                        }
                        .buttonGroup {
                            justify-content: flex-end;
                            padding: 0px;
                            margin-top: 30px;
                            .actionButton {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
