@import '../../../styles/colors';
@import "../../../styles//commonMixins";
@import "../../../styles/typographyMixins";

.virtualTourHolder {
    padding-top: 20px;
    .content {
        margin-bottom: 30px;
        .tourLabel {
            color: $darkBlue;
            @include mediumFont;
        }
        .tourHolder {
            width: 100%;
            .tourInput {
                width: 100%;
                @include bookFont($color: $darkGray, $fontWeight: 400);
                @include customInput;
            }
        }

        .icon {
            text-align: center;
        }
    }
}
