@import '../../../developmentDetailsMixins';

.developmentAgentsEdit {
    .field-label {
        label {
            text-transform: capitalize;
        }
        text-transform: capitalize !important;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &.headline {
            @include mediumFont($fontSize: 14px);
        }
    }
    .field-control {
        .formControlRoot {
            width: 100%;
        }
    }
    .title {
        @include mediumFont($fontSize: 14px);
        margin: 10px 0;
    }
    .sub-title {
        @include MercuryTextG4Roman($fontSize: 12px);
        margin: 10px 0 20px 0;
    }
    .customMultiSelectAutocomplete {
        @include customMultiSelectAutocomplete;
        &.fullWidth {
            width: 100% !important;
        }
    }
}
