@import '../../../cmrMixins.scss';

.scheduleListingHolder {
    border-top: 1px solid #707070;
    margin-bottom: 50px;
    .scheduleListingHeader {
        margin: 35px 0;
        @include mediumFont($textTransform: uppercase, $color: $sirBlue);
    }
    .scheduleListingContent {
        background-color: $whiteColor;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        padding: 28px 22px;
        .schedulerTable {
            display: flex;
            flex-direction: column;
            .tableHead,
            .tableBody {
                .section {
                    width: 32.5%;
                    margin: 10px 0;
                    color: $sirBlue;
                }
                .icon-container {
                    width: 2.5%;
                    margin: 10px 20px 10px 0px;
                    display: flex;
                    flex-direction: row;
                    .icon {
                        cursor: pointer;
                    }
                    .lasticon {
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
            .tableHead {
                border-bottom: 1px solid #707070;
                // padding: 0 4px;
                display: inherit;
                padding: 0 30px 0 4px;
                .section {
                    @include mediumFont($fontSize: 12px);
                }
            }
            .tableBody {
                max-height: 320px;
                overflow: overlay;
                .content {
                    border-bottom: 1px solid #707070;
                    width: 100%;
                    display: flex;
                    padding: 0 15px 0 4px;
                    .section {
                        font-family: 'MercuryTextG4Roman';
                        font-size: 14px;
                        &.name {
                            word-break: break-all;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .scheduleListingHolder {
        margin-bottom: 20px;
        .scheduleListingHeader {
            margin: 20px 0;
            font-size: 12px;
        }
        .scheduleListingContent {
            padding: 14px 11px;
            .schedulerTable {
                .tableBody {
                    .content {
                        .section {
                            font-size: 12px;
                        }
                    }
                    .icon-container {
                        font-size: 12px;
                        margin: 10px 15px 10px 0px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .scheduleListingHolder {
        margin-bottom: 10px;
        .scheduleListingHeader {
            margin: 10px 0;
            font-size: 12px;
        }
        .scheduleListingContent {
            padding: 14px 11px;
            .schedulerTable {
                .tableHead {
                    padding: 0;
                    .section {
                        font-size: 10px;
                        width: 30%;
                    }
                }
                .tableBody {
                    .content {
                        padding: 0;
                        .section {
                            font-size: 10px;
                            word-break: break-word;
                            width: 30%;
                        }
                    }
                    .icon-container {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
