@import '../../../../../../shared/styles/colors';
@import '../../../../../../shared/styles/typographyMixins';

.user-role-custom-chip{
    background-color: $whiteColor !important;
    border: solid 1px $sirBlue !important;
    @include regularFont($sirBlue, 400, 14px, none);
    margin: 5px !important;
    &.selected{
        background-color: $sirBlue !important;
        color: $whiteColor !important;
        .MuiChip-deleteIcon {
            color: $whiteColor;
        }
    }
}