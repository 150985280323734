@import '../../../shared/styles/commonMixins';
@import '../../../shared/styles/colors';

// CSS Classes For Both Custom Components (LinearProgress, CircularProgress)

.color-primary {
    background-color: $sirBlue !important;
}
.bar-color-primary {
    background-color: $goldColor !important;
}

.loading-content {
    margin: auto;
    .loading-text {
        @include loadingOverlayText;
    }
}

.loader-overlay {
    @include loaderOverlay($width: 80%);
    &.individualComp {
        @include loaderOverlayComponentSize();
    }
}

@media screen and (max-width: 1024px) {
    .loader-overlay {
        @include loaderOverlay($width: 100%);
    }
}
