@import './colors';

@mixin mediumFont(
    $fontSize: 14px,
    $lineHeight: '',
    $color: '',
    $letterSpacing: '',
    $textTransform: none,
    $fontWeight: normal
) {
    font-size: $fontSize;
    font-family: 'BentonSansMedium' !important;
    font-weight: $fontWeight;
    text-transform: $textTransform;

    @if $lineHeight != '' {
        line-height: $lineHeight;
    }
    @if $color != '' {
        color: $color;
    }
    @if $letterSpacing != '' {
        letter-spacing: $letterSpacing;
    }
}

@mixin bookFont($fontSize: 14px, $fontWeight: normal, $color: '', $textTransform: none) {
    font-size: $fontSize;
    font-family: 'BentonSansBook' !important;
    font-weight: $fontWeight;
    text-transform: $textTransform;

    @if $color != '' {
        color: $color;
    }
}

@mixin specText {
    font-size: 10px;
    display: inline-block;
    color: $placeholderColor;
    cursor: pointer;
    font-family: 'MercuryTextG4Roman' !important;
}

@mixin regularFont(
    $color: $darkGray,
    $fontWeight: normal,
    $fontSize: 16px,
    $textTransform: none
) {
    font-family: 'BentonSansRegular';
    font-weight: $fontWeight;
    font-size: $fontSize;
    color: $color;
    text-transform: $textTransform;
}

@mixin mercuryDisplayRoman(
    $color: $black,
    $fontWeight: normal,
    $fontSize: 22px,
    $textTransform: none
) {
    font-family: 'MercuryDisplayRoman';
    font-weight: $fontWeight;
    font-size: $fontSize;
    color: $color;
    text-transform: $textTransform;
}

@mixin infoText {
    color: $infoColor;
    font-style: italic;
    font-size: 12px;
}

@mixin MercuryTextG4Roman(
    $color: $darkGray,
    $fontWeight: normal,
    $fontSize: 14px,
    $textTransform: none
) {
    font-family: 'MercuryTextG4Roman';
    font-weight: $fontWeight;
    font-size: $fontSize;
    color: $color;
    text-transform: $textTransform;
}
