@import '../../../cmrMixins.scss';

.crmOpenHouse {
    .data-container {
        .addOpenHouse {
            padding: 10px;
            height: auto;
            background-color: $backgroundGray !important;
            display: flex;
            align-items: center;
            box-shadow: 0px 1px 4px $infoColor;
        }
        .openHouseEditContainer {
            background-color: $whiteColor;
            // z-index: 1000;
            position: relative;
            .editForm {
                // display: flex;
                width: 100%;
                min-height: 140px;
                margin: 0px 10px;
                padding-top: 10px;
                .cmrInput {
                    @include inlineFormInput;
                }
                .editCol-1 {
                    width: 100%;
                    .row-1 {
                        display: flex;

                        .actionHolder {
                            margin-right: 15px;
                            .ant-picker {
                                padding: 3px 0px 4px;
                            }
                            @include datePickerInput;
                            @include dateField;
                        }
                        .editData-date {
                            width: 26%;
                        }
                        .editData-agent {
                            width: 26%;
                            .searchAutoComplete {
                                .formControlRoot {
                                    background-color: transparent;
                                    border-top: unset !important;
                                    border-left: unset !important;
                                    border-right: unset !important;
                                    border-bottom: 1px solid #666666 !important;
                                    border-radius: unset;
                                }
                                &.Mui-focused {
                                    border: unset !important;
                                    box-shadow: unset !important;
                                    border-radius: unset !important;
                                }
                            }
                        }
                        .editFromtime {
                            width: 17%;
                        }
                        .editTotime {
                            width: 17%;
                        }
                    }
                    .row-2 {
                        display: flex;
                        margin-top: 10px;
                        span.attendees {
                            width: 26%;
                        }
                        span.notes {
                            margin-left: 13px;
                            width: 66%;
                        }
                        input {
                            font-size: 14px;
                        }
                    }

                    &.edit-inline {
                        width: 60%;
                        span.notes {
                            width: 67%;
                        }
                    }
                }
                .editCol-2 {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    margin-top: 30px;
                    .individualCheckbox {
                        // margin: 0px 0 0px 10px;
                        display: inline-block;
                        font-size: 15px !important;
                        label {
                            font-family: 'MercuryTextG4Roman';
                            span {
                                font-weight: normal;
                            }
                        }
                        .activeCheckboxContent {
                            margin-left: 10px;
                            color: $sirBlue;
                        }
                        .inActiveCheckboxContent {
                            margin-left: 10px;
                            color: $darkGray;
                        }
                        .inActiveTextCheckboxContent {
                            margin-left: 10px;
                            color: $lightGray;
                        }
                        .customCheckbox {
                            appearance: none;
                            background-color: $whiteColor;
                            margin: 0;
                            font: inherit;
                            color: $infoColor;
                            width: 1.15em;
                            height: 1.15em;
                            border: 0.15em solid $infoColor;
                            transform: translateY(-0.075em);
                            display: inline-grid;
                            place-content: center;
                            cursor: pointer;

                            &::before {
                                content: '';
                                width: 0.65em;
                                height: 0.65em;
                                transform: scale(0);
                                transition: 120ms transform ease-in-out;
                                box-shadow: inset 1em 1em $sirBlue;
                            }
                            &:checked::before {
                                transform: scale(1);
                            }
                        }
                        .inActiveCustomCheckbox {
                            border: 0.15em solid $lightGray;
                            cursor: default;
                        }
                    }
                    .virtualTour {
                        margin: 0px 10px 0px 10px;
                        display: inline-block;
                        .virtualTourInput {
                            @include inlineFormInput;
                        }
                    }

                    &.edit-inline {
                        width: 40%;
                        span.notes {
                            width: 67%;
                        }
                    }
                }
            }
        }
        .table-heading {
            @include tableHeading($fontColor: $infoColor);

            .to-time {
                width: 8%;
            }
            .date {
                width: 15%;
            }
            .agent {
                width: 16%;
            }
            .time {
                width: 11%;
            }
            .large {
                width: 13%;
            }
            .small {
                width: 16%;
                padding-left: 15px;
            }
            .empty {
                width: 10%;
            }
        }
        .buttonGroup {
            @include inlineAddButton;
            @include inlineEditButtons;
            margin-top: 0px;
        }
        .openHouseContainer {
            .table-data {
                @include tableRow($display: inherit);

                .rows {
                    .row-1 {
                        display: flex;
                        justify-content: space-between;
                        .data-text {
                            letter-spacing: 0px;
                            color: $sirBlue;
                            @include bookFont;
                            color: $sirBlue;
                            line-height: 18px;
                        }
                        .date-text {
                            letter-spacing: 0.02px;
                            @include bookFont;
                            color: $sirBlue;
                            line-height: 18px;
                        }
                        .data-section {
                            color: $sirBlue;
                        }
                        .data-date {
                            width: 15%;

                            @include dateFormat;
                        }
                        .data-agent {
                            width: 16%;
                        }
                        .time {
                            width: 11%;
                        }
                        .to-time {
                            width: 8%;
                        }
                        .small {
                            width: 20%;
                            padding-left: 15px;
                        }
                        .large {
                            width: 13%;
                        }
                        .to {
                            width: 7%;
                        }
                        .more {
                            width: 9%;
                        }
                        .openHouse-icons {
                            @include tableIcons;
                        }
                    }
                    .row-2 {
                        .more-container {
                            width: 62%;
                            margin: auto;
                            padding: 15px 0px 20px 0px;
                            .more-row {
                                display: flex;
                                justify-content: space-between;
                                align-items: baseline;
                                margin-bottom: 10px;
                            }
                            .more-title-text {
                                letter-spacing: 0.02px;
                                text-transform: uppercase;
                                @include bookFont;
                                color: $sirBlue;
                                line-height: 14px;
                            }
                            .more-content,
                            .selectContent {
                                width: 60%;
                                font-size: 14px;
                                @include bookFont;
                                color: $sirBlue;
                                letter-spacing: 0px;
                            }
                            .selectContent {
                                .more-content {
                                    display: block;
                                }
                            }
                            .empty {
                                padding: 15px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .crmOpenHouse {
        .data-container {
            .openHouseEditContainer {
                .editForm {
                    width: 100%;
                    margin: 0px;
                    padding: 0px;
                    .editCol-1 {
                        display: flex;
                        width: 70%;
                        .row-1 {
                            width: 50%;
                            flex-direction: column;
                            .actionHolder {
                                margin-bottom: 10px;
                            }
                            .editData-date {
                                width: 94%;
                            }
                            .editData-agent {
                                width: 94%;
                            }
                            .editFromtime {
                                width: 94%;
                            }
                            .editTotime {
                                width: 94%;
                            }
                        }
                        .row-2 {
                            margin-top: 0px;
                            width: 50%;
                            flex-direction: column;
                            span.attendees {
                                width: 94%;
                                margin-bottom: 10px;
                            }
                            span.notes {
                                margin-left: 0px;
                                width: 94%;
                                margin-bottom: 10px;
                            }
                        }
                    }
                    .editCol-2 {
                        width: 30%;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .crmOpenHouse {
        .data-container {
            .openHouseEditContainer {
                padding: 10px;
                .editForm {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin: 0px;
                    padding-top: 0px;
                    .cmrInput {
                        font-size: 10px;
                    }
                    .editCol-1 {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        .row-1 {
                            width: 100%;
                            flex-direction: column;
                            .actionHolder {
                                margin-bottom: 10px;
                                margin-right: 0px;
                                @include datePickerMobile;
                            }
                            .editData-date {
                                width: 100%;
                            }
                            .editData-agent {
                                width: 100%;
                            }
                            .editFromtime {
                                width: 100%;
                            }
                            .editTotime {
                                width: 100%;
                            }
                        }
                        .row-2 {
                            margin-top: 0px;
                            width: 100%;
                            flex-direction: column;
                            span.attendees {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                            span.notes {
                                margin-left: 0px;
                                width: 100%;
                                margin-bottom: 10px;
                            }
                            input {
                                font-size: 10px;
                            }
                        }
                        &.edit-inline {
                            width: 100%;
                        }
                    }
                    .editCol-2 {
                        width: 100%;
                        margin-top: 10px;
                        .individualCheckbox {
                            margin: 0px;
                            .activeCheckboxContent,
                            .inActiveCheckboxContent,
                            .inActiveTextCheckboxContent {
                                font-size: 10px !important;
                            }
                            .customCheckbox {
                                width: 1.05em;
                                height: 1.05em;
                            }
                        }
                        .virtualTour {
                            margin: 0px;
                            .virtualTourInput {
                                font-size: 10px;
                            }
                        }
                        &.edit-inline {
                            width: 100%;
                        }
                    }
                }
            }
            .buttonGroup {
                justify-content: flex-end;
                padding: 0px;
                margin-top: 30px;
            }
            .table-heading {
                @include tableHeadingMobile;
                .empty {
                    width: 6%;
                }
            }
            .openHouseContainer {
                .table-data {
                    .rows {
                        .row-1 {
                            .data-text,
                            .date-text,
                            .data-section {
                                @include tableRowMobile;
                            }
                            .openHouse-icons {
                                @include tableIconsMobile;
                            }
                        }
                        .row-2 {
                            .more-container {
                                width: 80%;
                                .more-title-text {
                                    font-size: 10px;
                                    margin-left: 80px;
                                }
                                .more-content {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
