@import '../../../listingMixins';

.ownerInfo {
    margin: 10px 0px;
    border-top: 1px solid $lightGray;
    padding-top: 15px;
    @include mediumFont($fontSize: 16px, $lineHeight: 18px, $color: $darkGray);
    .requiredField {
        margin-top: 25px;
        @include mediumFont($fontSize: 16px, $lineHeight: 18px);
    }
    .owner-info-order-label {
        margin-top: 25px;
        @include mediumFont($fontSize: 14px, $lineHeight: 18px);
    }
    .ownerInfoToggle {
        margin: 10px 0px;
        .ownerInfoLabel {
            @include mediumFont($fontSize: 14px, $lineHeight: 18px);
        }
        .checkbox:checked {
            color: $sirBlue;
        }
        .checkbox:hover {
            background-color: transparent;
        }
    }
    .nameFields,
    .addressFields,
    .otherFields {
        margin: 10px 0px;
        .firstName,
        .lastName,
        .address,
        .city,
        .state,
        .zip {
            @include mediumFont($fontSize: 14px, $lineHeight: 18px, $color: $sirBlue);
        }
        .nameInput,
        .addressInput,
        .cityInput {
            @include customInput;
            // width: 80%;
        }
        .zipInput {
            @include customInput;
            // width: 80%;
        }

        .MuiSelect-selectMenu {
            background-color: $whiteColor !important;
            &:hover {
                background-color: $whiteColor !important;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .ownerInfo {
        .nameFields {
            .nameInput {
                width: 95%;
            }
        }
    }
}
