.singleTemplateHolder {
    display: inline-block;
    margin-bottom: 40px;

    a.templateLink {
        text-decoration: none;
    }

    div.templateImageHolder {
        width: 200px;
        height: 200px;
        display: inline-block;
        margin: 0 40px 0 0;

        .templateImage {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
            padding: 2px;
            filter: drop-shadow(0 0 0.15rem #002349);
        }
    }

    div.templateTitle {
        text-align: center;
        margin: 20px 40px 0 0;
        font-family: BentonSansMedium;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #002349;
        text-transform: uppercase;
        width: 200px;
        vertical-align: bottom;
    }

    div.templateSpec {
        text-align: center;
        margin: 0 40px 0 0;
        font-family: MercuryTextG4Roman;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #868686;
        width: 200px;
    }

    div.templatePreview {
        text-align: center;
        margin: 0 40px 10px 0;
        font-family: MercuryTextG4Roman;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #002349;
        width: 200px;
        min-height: 20px;

        button.sliderNav {
            border: none;
            background: none;
            padding: 0 10px;

            &:disabled {
                .sliderIcons {
                    color: #999;
                }
            }

            .sliderIcons {
                font-size: 10px;
                color: #000;
            }
        }
    }
}
