@import '../../../shared/styles/colors';
@import '../../../shared/styles/typographyMixins';

.officeFullSelection {
    background-color: $backgroundGray;
    @include mediumFont($color: $sirBlue, $fontWeight: 400);
    margin: 20px 0;
    padding: 10px 20px;
    &.region {
        margin: 5px 0;
        padding: 5px 20px;
    }
    span.link {
        margin-right: 20px;
        button {
            color: $darkBlue;
            border: none;
            background: transparent;
            cursor: pointer;
        }
    }
}
.regionSelection {
    .region {
        text-decoration: underline;
        padding: 5px 10px 5px 0;
        @include mediumFont($color: $sirBlue, $fontWeight: 400, $fontSize: 12px);
        cursor: pointer;
    }
}
// .officeList {
//     max-height: 300px;
//     overflow-y: scroll;
// }
.officeChips {
    .chip {
        background-color: $whiteColor;
        margin: 5px 10px 5px 0px;
        border: solid 1px $sirBlue;
        @include regularFont($sirBlue, 400, 14px, none);
        &.selected {
            color: $whiteColor;
            background-color: $sirBlue20;
        }
        .MuiChip-deleteIcon {
            color: $whiteColor;
        }
    }
}
