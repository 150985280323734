@import '../../../../../listingMixins';

.top-nav {
    @include subnav;

    margin-top: 20px;
    padding-bottom: 30px;
    border-bottom: solid 1px $goldColor;

    .nav-link {
        margin-right: 30px;
    }
}

.sectionContainer {
    width: 100%;
    .section {
        margin: 20px 0px;
        border-bottom: 1px solid $lightGray;      
        .content {
            margin-bottom: 10px;  
            &.tooltipContent {
                position: relative;
                .exteriorTooltip {
                    margin: 0px 30px;
                    cursor: pointer;
                    color: $infoColor;
                    position: absolute;
                    right: 0;
                }
            } 

            .formControlRoot {
                &.inputDisabled{
                    background-color: $backgroundGray;
                }
            }

            &.group {
                .inputField {
                    @include bookFont($color: $darkGray, $fontWeight: 400);
                }
                
                .formControlRoot {
                    > div > div {
                        background-color: transparent;
                    }
                }
                .searchControl {
                    width: 94% !important;
                }
    
                .formControlLabel {
                    @include mediumFont($color: $sirBlue, $fontWeight: 400);
                }
                .MuiIconButton-colorSecondary:hover {
                    background-color: transparent;
                }            
                .MuiInputBase-input {
                    padding: 8px 0px 8px;
                    color: $black;
                }                    
        
                .field-label {
                    padding: 0; 
                }
            }
        }        
    }
    .searchRow {
        border-bottom: none;
        margin-top: 0px;
    }
    .chipContent {
        width: 100%;
    }
    .MuiCheckbox-colorSecondary.Mui-checked {
        color: $sirBlue;
    } 
    .MuiIconButton-colorSecondary:hover, 
    .MuiCheckbox-colorSecondary.Mui-checked:hover {
        background-color: transparent;
    }
}

.extContainer {
    margin-top: 20px;
}

form {
    @include formLabel;
    @include drawerComponentWidth;

    .MuiGrid-container {
        .field-info {
            line-height: 32px;
            margin-right: 5px;
        }
    }
}
