@import '../../styles/_colors';
@import '../../styles/_typographyMixins';

.customHolder {
    background-color: $whiteColor;
    margin: auto;
    width: 100%;
    .header {
        margin: auto;
        padding: 24px 30px 23px 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        vertical-align: middle;
        border-bottom: 1px solid $lightGray;
        .title {
            color: $sirBlue;
            font-size: 32px;
            font-family: 'MercuryDisplayRoman';
            line-height: 37px;
            .hamburger {
                display: none;
            }
            .goPrevious {
                font-size: 20px;
                margin-right: 40px;
                vertical-align: inherit;
                cursor: pointer;
            }
        }
        .rightContent {
            display: inherit;
            align-items: flex-end;
            .searchConatiner {
                position: relative;
                margin-right: 15px;
                width: 300px;
                .search {
                    border-top: 0;
                    border-left: 0;
                    border-right: 0;
                    border-bottom: 1px solid $infoColor;
                    width: 100%;
                }
                .searchIcon {
                    position: absolute;
                    right: 5px;
                    top: 3px;
                }
                .globalSearch-results {
                    border: 1px solid $darkGray;
                    display: none;
                    width: 500px;
                    position: absolute;
                    top: 35px;
                    right: 0;
                    background-color: $backgroundGray;
                    padding: 10px;
                    @include mercuryDisplayRoman($fontSize: 14px);
                    z-index: 1001;
                    &.show-results {
                        display: block;
                    }
                    .searchResults {
                        padding: 5px;
                        display: block;
                        color: inherit;
                        cursor: pointer;
                        font-size: 15px;
                        .textBold {
                            font-weight: bold;
                        }
                    }
                    .detailsView {
                        text-align: center;
                    }
                }
            }
            .profile {
                position: relative;
                img {
                    width: 40px;
                    height: 40px;
                    object-fit: cover;
                }
                .circle {
                    position: absolute;
                    background-color: $green;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    box-shadow: 0px 0 0 3px $whiteColor;
                    right: 0;
                    bottom: 1px;
                }
            }

            // .profile{}
        }
    }
}

@media screen and (max-width: 1024px) {
    .customHolder {
        .header {
            .title {
                display: flex;
                .hamburger {
                    display: block;
                    margin-right: 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .customHolder {
        .header {
            flex-direction: column;
            padding: 12px 15px 12px 15px;
            .title,
            .rightContent {
                width: 100%;
            }
            .title {
                display: flex;
                align-items: center;
            }
            .rightContent {
                .searchConatiner {
                    .globalSearch-results {
                        width: 280px;
                    }
                }
            }
        }
    }
}
