@import '../cmrMixins.scss';

.cmr-container {
    .title {
        @include mediumFont(
            $lineHeight: 17px,
            $color: $sirBlue,
            $letterSpacing: 0.02px,
            $textTransform: uppercase
        );
        padding: 15px 0px 15px 0px;
        border-bottom: 1px solid $sirBlue;
    }
    .report {
        padding: 15px 0px 15px 0px;
        font-size: 16px;
        font-family: 'MercuryTextG4Roman';
        line-height: 19px;
        letter-spacing: 0px;
        color: $sirBlue;
        border-bottom: 1px solid $sirBlue;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
