@import '../../../listingMixins';

@include formLabel;
.datesContainer {
    .info-text {
        @include infoText;
    }
    .section {
        margin: 20px 0px;
        &:not(:nth-last-child(1)) {
            border-bottom: 1px solid $lightGray;
        }
        .content {
            margin-bottom: 10px;

            .inputBaseRoot {
                @include bookFont($color: $darkGray, $fontWeight: 400);
                @include customInput;
                &.inputDisabled {
                    background-color: $backgroundGray;
                }
            }
            .MuiInputBase-input {
                padding: 8px 0px 8px;
                color: $black;
                &:disabled {
                    cursor: not-allowed;
                }
            }
            .formControlRoot {
                .datePickerCommon {
                    // @include customInput;
                    .MuiInput-root {
                        &:hover::before {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                        }
                        input {
                            background-color: $whiteColor;
                            @include bookFont($color: $black, $fontWeight: 400);
                        }
                    }
                    input[type='date']:required:invalid::-webkit-datetime-edit {
                        color: transparent;
                    }
                    input[type='date']:focus::-webkit-datetime-edit {
                        color: $black !important;
                    }
                    &.inputDisabled {
                        background-color: $backgroundGray;
                    }
                }

                @include datePickerPlaceholder($color: $darkGray);
                .ant-picker-input > input {
                    @include bookFont($color: $black, $fontWeight: 400);
                }
            }
            .checkboxLabel {
                @include mediumFont($color: $sirBlue);
            }
            .disabledLabel {
                color: $infoColor;
            }
        }
    }
    .formControlRoot {
        .datePickerCommon {
            @include customInput;
        }
    }
}
@media only screen and (max-width: 767px) {
    .datesContainer {
        .section {
            .content {
                .formControlRoot {
                    width: 95%;
                }
            }
        }
    }
}
