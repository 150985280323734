@import '../../shared/styles/colors';
@import '../../shared/styles/typographyMixins';
@import '../../shared/styles/formMixins';
@import '../../shared/styles/datePickerMixins';
@import '../../shared/styles/fileMixins';
@import '../../shared/styles/tableMixins';

@mixin dateFormat {
    @include mediumFont(
        $fontSize: 12px,
        $lineHeight: 20px,
        $color: $infoColor,
        $letterSpacing: 0.02px
    );
    text-transform: uppercase;
}
