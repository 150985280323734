@import '../../../shared/styles/colors';
@import '../../../shared/styles/formMixins';
@import '../../../shared/styles/commonMixins';
@import '../../../shared/styles/typographyMixins';

@mixin sectionTitle {
    @include mercuryDisplayRoman($sirBlue, 500, 46px, none);
    margin: 80px 0 60px 0;
}

@mixin headline {
    @include mercuryDisplayRoman($sirBlue, normal, 22px, none);
}

.titleHolder {
    padding: 20px 40px 20px 40px;
}

.events-title {
    @include sectionTitle;
    margin: 35px 0 28px 0;
    width: 100%;
    .edit-button {
        padding: 15px;
        cursor: pointer;
        @include mediumFont(12px, 12px, $lightBlue, 1px, uppercase, 400);
        border: none;
        background-color: transparent;
        .edit-icon {
            color: $lightBlue;
            margin: 0px 7px;
        }
    }
}
