@import '../../../../styles/typographyMixins';
@import '../../../../styles/colors';

.imageDownloadContainer {
    position: absolute;
    height: 15%;
    width: 100%;
    top: 43%;
    display: flex;
    align-items: center;
    justify-content: center;
    .buttonsHolder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 70%;
        .sizeButton {
            color: $sirBlue;
            background-color: $whiteColor;
            border: 1px solid $sirBlue;
            @include mediumFont;
            padding: 17px 0px;
            cursor: pointer;
            text-transform: uppercase;
            width: 25%;
        }
        .thumbnailSizeButton {
            padding: 0px;
            text-transform: none;
            width: 30%;
            font-size: 10px;
            font-family: 'MercuryTextG4Roman' !important;
        }
        .mediumThumbnailSizeButton {
            font-size: 13px;
        }
        .largeThumbnailSizeButton {
            font-size: 15px;
        }
        .sizeButtonSelected {
            color: $whiteColor;
            background-color: $sirBlue;
        }
    }
    .thumbnailButtonHolder {
        width: 100%;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 767px) {
    .imageDownloadContainer {
        .buttonsHolder {
            width: 90%;
        }
    }
}
