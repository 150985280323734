@import '../../../officeMixins';

.managerContainer {
    .content {
        margin-bottom: 30px;
        .formControlRoot {
            width: 100%;
            > div > div {
                background-color: transparent;
            }
            // &.dropDownBaseRoot {
            //     @include customInput;
            // }
        }
        .MuiInputBase-input {
            padding: 8px 0px 8px;
            color: $black;
            &:disabled {
                cursor: not-allowed;
                color: $whiteColor;
            }
        }
        .formControlLabel {
            @include mediumFont(
                $color: $sirBlue,
                $fontWeight: 400,
                $textTransform: uppercase
            );
        }
        .formControlData {
            @include regularFont($darkGray, 400, 14px, none);
        }
    }
}
