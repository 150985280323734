@import '../../../../styles/typographyMixins';
@import '../../../../styles/colors';
@import '../../../../styles/formMixins';
@import '../../../../styles/commonMixins';

.cropperHolder {
    max-width: 600px;
    margin: auto;
    .bottomContainer {
        background-color: #00000029;
        .actionHolder {
            background-color: $whiteColor;
            .cropActionHolder {
                display: flex;
                justify-content: space-between;
                color: $darkGray;
                padding: 20px 0px;
                .cropDetails {
                    font-size: 14px;
                    margin: 0px 10px;
                    .aspectRatio {
                        @include bookFont($textTransform: uppercase);
                        display: block;
                    }
                    .dimDetails {
                        margin-top: 10px;
                        font-family: 'MercuryTextG4Roman';
                        display: block;
                    }
                }
                .cropActions {
                    .cropActionButton {
                        margin: 0px 10px;
                        @include mediumFont;
                        text-transform: uppercase;
                        cursor: pointer;
                        border: none;
                        background-color: $whiteColor;
                        .inactiveActionButton {
                            cursor: default;
                            color: $infoColor;
                        }
                    }
                    .activeCropActionButton {
                        text-decoration: underline;
                        color: $sirBlue;
                    }
                }
            }
            .buttonsHolder {
                @include inlineEditButtons;
                margin-top: 0px;
                background-color: $whiteColor;
            }
        }
    }
    .circularOverlay {
        background-color: #00000029;
        z-index: 1400;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $sirBlue;
        .imageLoadingText {
            @include mediumFont;
            color: $whiteColor;
            margin: 0px 20px;
        }
    }
    .propImage {
        display: none;
    }
    .cropSaveCircularOverlay {
        @include loaderOverlay;
        .savingCropText {
            @include loadingOverlayText;
        }
    }
}
.cropperHolderFullScreen {
    max-width: unset;
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    .bottomContainer {
        width: 100%;
        height: 22%;
        .actionHolder {
            height: 100%;
        }
    }
    .circularOverlay,
    .cropSaveCircularOverlay {
        width: 100%;
    }
}
.img-preview {
    overflow: hidden;
}
