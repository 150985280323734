@import "../../styles/colors";

.remain-char {
    color: $infoColor;
    font-style: italic;
    font-size: 12px;
    .alert {
        color: $maroon;
    }
}
