@import './typographyMixins';
@import './formMixins';

@mixin datePickerPlaceholder($color: $placeholderColor) {
    .ant-picker-input > input::placeholder,
    .ant-picker-input .ant-picker-suffix {
        color: $color;
    }
}

@mixin datePickerInput(
    $fontSize: 15px,
    $placeholderColor: $placeholderColor,
    $elementColor: #333
) {
    @include datePickerPlaceholder($color: $placeholderColor);
    .ant-picker-input > input {
        @include formElement($color: $elementColor, $fontSize: $fontSize);
    }
}

@mixin datePickerEdit($color: '') {
    @include datePickerPlaceholder;
    .ant-picker-input > input {
        @include bookFont;

        @if $color != '' {
            color: $color;
        }
    }
}

@mixin datePickerMobile {
    .cmrInput,
    .cmrDate {
        font-size: 10px;
        ::placeholder {
            font-size: 10px;
        }
    }

    .ant-picker-input > input {
        font-size: 10px;
    }
}

@mixin dateField($color: #333, $fontSize: 15px) {
    .cmrDate {
        width: 100%;
        .MuiInput-root {
            &:hover::before {
                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
            }
            input {
                @include formElement($color: $color, $fontSize: $fontSize);
            }
        }
    }
}
