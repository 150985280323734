@import './shared/styles/formMixins';
@import './shared/styles/colors';
@import './shared/styles/commonMixins';

@font-face {
    font-family: 'BentonSansMedium';
    src: url('./shared/fonts/BentonSans-Medium_sOT.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'MercuryTextG4Roman';
    src: url('./shared/fonts/MercuryTextG4-Roman-Pro.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'FreigBigProBook';
    src: url('./shared/fonts/FreigBigProBoo.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'BentonSansBook';
    src: url('./shared/fonts/BentonSans-Book_sOT.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'MercuryTextG4Italic';
    src: url('./shared/fonts/MercuryTextG4-Italic-Pro.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'FreigBigProMed';
    src: url('./shared/fonts/FreigBigProMed.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'BentonSansRegular';
    src: url('./shared/fonts/BentonSans-Regular.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'MercuryDisplayRoman';
    src: url('./shared/fonts/MercuryDisplayRoman.otf') format('opentype');
    font-display: swap;
}
@font-face {
    font-family: 'MercuryDisplayItalic';
    src: url('./shared/fonts/MercuryDisplayItalic.ttf') format('truetype');
    font-style: italic;
    font-display: swap;
}

@media screen {
    #print {
        display: none;
    }
}

@media print {
    .screen-display {
        display: none !important;
    }

    body {
        background: none !important;
        min-width: 0 !important;
    }

    @page { margin: 0; }
    body { margin: 1.6cm; }

    .page-break {
        page-break-after: always;
    }

    div.page-break {
        &:last-of-type {
            page-break-after: auto;
        }

        &:last-child {
            page-break-after: auto;
        }
    }

    .message-toast {
        display: none;
    }
}

@include customCheckbox;
@include customButton;

.inputBaseRoot {
    @include customInput;
    &.inputDisabled {
        background-color: $backgroundGray;
    }
    &.dropdownBaseRoot {
        > div {
            padding: 0px 10px;
        }
    }
}

.searchAutoComplete.Mui-focused,
.customMultiSelectAutocomplete.Mui-focused {
    border: 1px solid $sirBlue !important;
    box-shadow: 0 0 4px $sirBlue;
    border-radius: 10px;
    .formControlRoot {
        border: unset !important;
    }
}
.customMultiSelectAutocomplete.Mui-focused {
    > * > div {
        border-radius: 10px;
        border: unset;
        padding: 7px !important;
    }
}

.formControlRoot {
    width: 80%;
    .datePickerCommon {
        border: 1px solid $darkGray;
        @include formElement($color: $infoColor, $fontSize: 14px);
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        padding: 4px 0px;
        border-radius: 10px;
        &.ant-picker-focused {
            border: 2px solid $sirBlue !important;
            box-shadow: 0 0 2px $sirBlue;
            border-right-width: unset;
            outline: unset;
        }
    }
}

.container {
    min-height: 100vh;
}

.welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.green {
    color: $green;
}
.red {
    color: $red;
}

@media only screen and (max-width: 768px) {
    .formControlRoot {
        width: 95%;
    }
}
