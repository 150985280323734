@import '../../../../styles/colors';

.tagsHolder {
    width: 90%;
    background-color: $whiteColor;
    .imageTagsHolder {
        margin: 0px 20px;
        background-color: $whiteColor;
    }
}
