@import '../../../../../../shared/styles/commonMixins';
@import '../../../../../../shared/styles/typographyMixins';
.dashroles-container {
    .content {
        margin-bottom: 30px;
        .formControlRoot {
            width: 80% !important;
            // &.dropDownBaseRoot {
            //     @include customInput;
            // }
        }
        .inputBaseRoot {
            // @include customInput;
            &.inputDisabled {
                background-color: $backgroundGray;
            }
        }
        .formControlLabel {
            @include mediumFont(
                $color: $sirBlue,
                $fontWeight: 400,
                $textTransform: uppercase
            );
        }
        .formControlData {
            @include regularFont($darkGray, 400, 14px, none);
        }
    }
}
