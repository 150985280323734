@import './colors';
@import './typographyMixins';

@mixin tableRow($padding: 15px 0px, $display: flex) {
    border-bottom: 1px solid $lightGray;
    display: $display;
    justify-content: space-between;
    padding: $padding;
}

@mixin tableRowMobile {
    font-size: 10px;
    word-break: break-word;
    line-break: unset;
}

@mixin tableIcons {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    .edit {
        cursor: pointer;
    }
    .trash {
        cursor: pointer;
    }
    a {
        margin-right: 5px;
    }
    .icon {
        color: $infoColor;
        margin-right: 10px;
    }
}

@mixin tableIconsMobile {
    width: 6%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    padding-right: 0;
    a,
    .icon {
        margin-right: 0px;
    }
}

@mixin tableHeading(
    $padding: 18px 0px,
    $borderColor: $lightGray,
    $fontSize: 12px,
    $fontColor: $darkGray,
    $isMedium: true,
    $justifyContent: space-between
) {
    border-bottom: 1px solid $borderColor;
    display: flex;
    justify-content: $justifyContent;
    padding: $padding;
    @if $isMedium {
        .heading-text {
            @include mediumFont(
                $fontSize: $fontSize,
                $lineHeight: 18px,
                $color: $fontColor,
                $letterSpacing: 0.02px
            );
            text-transform: uppercase;
        }
    } @else {
        .heading-text {
            @include MercuryTextG4Roman();
            letter-spacing: 0.02px;
            line-height: 18px;
        }
    }
}

@mixin tableHeadingMobile {
    .heading-text {
        font-size: 9px;
    }
}
