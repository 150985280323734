@import '../../../listingMixins';

@mixin showingFields {
    display: flex;
    flex-wrap: wrap;
    .actionHolder {
        margin-right: 20px;
        width: 30%;
        margin-bottom: 18px;
        @include dateField($color: $darkGray, $fontSize: 14px);
        .cmrDate {
            padding: 4px 9px;
            // border: 1px solid $darkGray !important;
            border-radius: 10px;
            &:hover {
                border-color: $darkGray;
            }
        }
        input[type='date']:required:invalid::-webkit-datetime-edit {
            color: transparent;
        }
        input[type='date']:focus::-webkit-datetime-edit {
            color: $darkGray !important;
        }
        .cmrInput {
            @include bookFont($color: $darkGray, $fontWeight: 400);
            @include customInput;
            .MuiInputBase-input {
                padding: 8px 0px 8px;
                color: $black;
            }
            &.inputDisabled {
                background-color: $darkGray;
            }
        }

        @include datePickerInput($placeholderColor: #b6b6b6);
        .ant-picker-input > input {
            @include bookFont(14px, 400, $darkGray);
        }
    }
}

.showings-container-listing {
    .data-container {
        .form-container {
            padding: 30px 0px;
            height: auto;
            background-color: transparent;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $goldColor;
            .add-showing > div.formFields {
                @include showingFields;
            }

            .addButtonHolder {
                float: right;
                .addButtonDisabled,
                .unActive {
                    @include mediumFont(
                        $fontSize: 12px,
                        $lineHeight: 14px,
                        $color: $sirBlue,
                        $letterSpacing: 1px,
                        $fontWeight: 400,
                        $textTransform: uppercase
                    );
                    padding: 10px;
                    cursor: pointer;
                    background-color: $whiteColor;
                    border: 1px solid $gold;
                    width: 70px;
                }
                .addButtonActive,
                .active {
                    @include mediumFont(
                        $fontSize: 12px,
                        $lineHeight: 14px,
                        $color: $whiteColor,
                        $letterSpacing: 1px,
                        $fontWeight: 400,
                        $textTransform: uppercase
                    );
                    padding: 10px;
                    background-color: $infoColor;
                    border: 1px solid $infoColor;
                    width: 70px;
                    cursor: default;
                }
            }
        }
        .table-heading {
            @include tableHeading(
                $padding: 30px 0,
                $borderColor: $goldColor,
                $fontSize: 14px,
                $fontColor: $darkBlue
            );

            .heading-text {
                width: 17.5%;
            }
            .empty {
                width: 10%;
            }
        }
        .inner-data-container {
            overflow: overlay;
            .table-data {
                padding: 15px 0px;
                border-bottom: 1px solid $darkGray;
                .rows {
                    .row-1 {
                        display: flex;
                        justify-content: space-between;
                        .data-text {
                            @include mediumFont(
                                $lineHeight: 18px,
                                $color: $sirBlue,
                                $letterSpacing: 0px
                            );
                            width: 18%;
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                        }
                        .date {
                            @include dateFormat;
                        }
                        .notes {
                            width: 18%;
                            padding-left: 35px;
                        }
                        .showings-icons {
                            @include tableIcons;
                        }
                    }
                    .row-2 {
                        .notes-container {
                            width: 50%;
                            margin: auto;
                            padding: 15px 0px 20px 0px;
                            .notes-row {
                                display: flex;
                                justify-content: space-between;
                                align-items: baseline;
                            }
                            .notes-title-text {
                                @include moreTitleText;
                            }
                            .notes-content {
                                width: 60%;
                                @include mediumFont(
                                    $lineHeight: 30px,
                                    $letterSpacing: 0px,
                                    $color: $darkGray
                                );
                            }
                            .empty-content {
                                height: 27px;
                            }
                        }
                    }
                }
                .showingsEditContainer {
                    background-color: $whiteColor;
                    z-index: 1000;
                    position: relative;
                    padding: 10px 0px;
                    overflow: auto;

                    .formFields {
                        @include showingFields;

                        .actionHolder {
                            width: 20%;
                        }
                    }

                    .buttonGroup {
                        @include inlineEditButtons;
                        .actionButton {
                            @include mediumFont(
                                $fontSize: 12px,
                                $lineHeight: 14px,
                                $color: $sirBlue,
                                $letterSpacing: 1px,
                                $fontWeight: 400,
                                $textTransform: uppercase
                            );
                            border: 1px solid $gold;
                            padding: 10px;
                        }
                        .saveActive {
                            background-color: $whiteColor;
                        }
                        .saveInActive {
                            border: 1px solid $infoColor;
                            background-color: $infoColor;
                            color: $whiteColor;
                            cursor: default;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .showings-container-listing {
        .data-container {
            .form-container {
                .add-showing {
                    gap: 0px 25px;
                    .actionHolder {
                        width: 45%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .showings-container-listing {
        .data-container {
            .form-container {
                .add-showing {
                    width: 100%;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .showings-container-listing {
        .data-container {
            .form-container {
                .add-showing > div.formFields {
                    flex-direction: column;
                    .actionHolder {
                        width: 100%;
                        margin-right: 0;
                        @include datePickerMobile;
                        .cmrDate,
                        .cmrInput {
                            font-size: 14px;
                        }
                        .ant-picker-input > input {
                            @include bookFont(14px, 400, $darkGray);
                        }
                    }
                }
                .addButtonHolder {
                    justify-content: flex-end;
                    display: inherit;
                }
            }
            .table-heading {
                @include tableHeadingMobile;
            }
            .inner-data-container {
                .table-data {
                    .rows {
                        .row-1 {
                            .data-text {
                                @include tableRowMobile;
                            }
                            .showings-icons {
                                .icon-container {
                                    @include tableIconsMobile;
                                }
                            }
                        }
                        .row-2 {
                            .notes-container {
                                margin: auto;
                                padding: 15px 0px 20px 0px;
                                .notes-row {
                                    .notes-title-text {
                                        font-size: 10px;
                                    }
                                    .notes-content {
                                        font-size: 10px;
                                    }
                                }
                            }
                        }
                    }
                    .showingsEditContainer {
                        .formFields {
                            display: flex;
                            flex-direction: column;
                            .actionHolder {
                                width: 100%;
                                @include datePickerMobile;
                            }
                        }
                        .buttonGroup {
                            justify-content: flex-end;
                            padding: 0px;
                            margin-top: 30px;
                            .actionButton {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
