@import '../../../cmrMixins.scss';

.viwes-container {
    .inner-container {
        .data-container {
            .table-heading {
                @include tableHeading(
                    $fontSize: 14px,
                    $justifyContent: flex-end,
                    $isMedium: false
                );

                .title {
                    margin-right: 20px;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
            .webisteForm {
                padding: 10px;
                height: auto;
                background-color: $backgroundGray;
                display: flex;
                align-items: center;
                box-shadow: 0px 1px 4px $infoColor;
                .addSite {
                    display: flex;
                    flex-wrap: wrap;
                    width: 90%;
                    .actionHolder {
                        margin-right: 10px;
                        width: 30%;
                        margin-bottom: 10px;
                        .cmrDate {
                            width: 100%;
                            .MuiInput-root {
                                &:hover::before {
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                                }
                                input {
                                    color: #333;
                                    @include bookFont;
                                }
                            }
                            input[type='date']:required:invalid::-webkit-datetime-edit {
                                color: transparent;
                            }
                            input[type='date']:focus::-webkit-datetime-edit {
                                color: black !important;
                            }
                        }
                        .cmrInput {
                            @include inlineFormInput;
                        }
                        @include datePickerInput;
                        &.siteFileHolder {
                            .siteFile {
                                @include fileFields;
                            }
                            .spec {
                                @include specText;
                            }
                        }
                    }
                    .radioGroup {
                        flex-direction: row;
                        span {
                            @include mediumFont(
                                $color: $infoColor,
                                $lineHeight: 14px,
                                $letterSpacing: 0.02px
                            );
                        }
                    }
                }
                .addButtonHolder {
                    @include inlineAddButton;
                }
            }
            .info {
                justify-content: flex-start;
            }
            .table-total-views {
                border-bottom: 1px solid $lightGray;
                display: flex;
                justify-content: space-between;
                padding: 18px 0px 18px 0px;
                .col-1 {
                    display: flex;
                }
                .heading-text {
                    letter-spacing: 0.02px;
                    @include bookFont;
                    color: $sirBlue;
                    line-height: 18px;
                    font-weight: bolder;
                }
                .links {
                    width: 25%;
                    display: flex;
                    justify-content: flex-end;
                    .icon-container {
                        margin-left: 15px;
                        width: 30%;
                    }
                }
            }
            .inner-data-container {
                overflow-y: overlay;
                .table-data {
                    @include tableRow;

                    .data-col-1 {
                        display: flex;
                        .data-date {
                            @include mediumFont(
                                $fontSize: 12px,
                                $color: $darkGray,
                                $lineHeight: 20px,
                                $letterSpacing: 0.02px
                            );
                            margin-right: 56px;
                        }
                        .data-publications {
                            letter-spacing: 0px;
                            @include bookFont;
                            color: $sirBlue;
                            line-height: 18px;

                            a {
                                color: $sirBlue;
                                text-decoration: underline;
                            }
                            .info-icon {
                                margin-left: 10px;
                                cursor: pointer;
                            }
                            .siteInfo {
                                font-size: 12px;
                                color: $infoColor;
                                margin-left: 10px;
                            }
                        }
                    }
                    .data-links {
                        letter-spacing: 0px;
                        @include bookFont;
                        color: $sirBlue;
                        line-height: 18px;
                        .icon-container {
                            margin-left: 15px;
                            width: 30%;
                            .icon {
                                color: $infoColor;
                                cursor: pointer;
                                &:first-child {
                                    margin-right: 15px;
                                }
                            }
                        }
                    }
                    .add-icons {
                        display: flex;
                        width: 25%;
                        justify-content: flex-end;
                    }
                }
                .editWebisteForm {
                    padding: 10px;
                    height: auto;
                    box-shadow: 0px 3px 10px $infoColor;
                    position: relative;
                    z-index: 1000;
                    transform: 'translate(-50%, -50%)';
                    background-color: $whiteColor;
                    .editSite {
                        display: flex;
                        flex-wrap: wrap;
                        .editActionHolder {
                            margin-right: 10px;
                            width: 30%;
                            margin-bottom: 10px;
                            .cmrEditDate {
                                width: 100%;
                                margin-bottom: 10px;
                                .MuiInput-root {
                                    &:hover::before {
                                        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                                    }
                                    input {
                                        color: #333;
                                        @include bookFont;
                                    }
                                }
                                input[type='date']:required:invalid::-webkit-datetime-edit {
                                    color: transparent;
                                }
                                input[type='date']:focus::-webkit-datetime-edit {
                                    color: black !important;
                                }
                            }
                            .cmrEditInput {
                                @include inlineFormInput;
                            }
                            @include datePickerInput($fontSize: 14px);
                            &.siteFileHolder {
                                .siteFile {
                                    @include fileFieldsEdit;
                                }
                                .spec {
                                    @include bookFont($fontSize: 10px);
                                    display: inline-block;
                                    color: #bbb;
                                    cursor: pointer;
                                }
                            }
                        }
                        .radioGroup {
                            flex-direction: row;
                            span {
                                font-family: 'Mercury Text G4, Roman';
                                font-size: 14px;
                                color: $sirBlue;
                            }
                        }
                    }
                    .editButtonHolder {
                        @include inlineEditButtons;
                    }
                }
            }
        }
    }
}
.viewGraph {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    background-color: $whiteColor;
    position: absolute;
    padding: 10px 20px;
    overflow: auto;
    .dateRow {
        display: flex;
        justify-content: space-between;
        .dateRange {
            @include bookFont($color: $darkGray);
            padding: 10px;
            display: flex;
            background-color: #fafafa;
            .date {
                margin: 0px 10px;
            }
        }
    }

    .chartWrapper {
        width: 100%;
    }
    .noData {
        @include bookFont($color: $darkGray);
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        text-align: center;
    }
    .table-data {
        @include tableRow($padding: 15px 26px 15px 0px);
        .data-col-1 {
            display: flex;
            .data-date {
                margin-right: 56px;
                @include mediumFont(
                    $fontSize: 12px,
                    $color: $darkGray,
                    $lineHeight: 20px,
                    $letterSpacing: 0.02px
                );
            }
            .data-publications {
                @include mediumFont(
                    $fontSize: 16px,
                    $lineHeight: 18px,
                    $color: $sirBlue,
                    $letterSpacing: 0px
                );

                a {
                    color: $sirBlue;
                    text-decoration: underline;
                }
                .siteInfo {
                    font-size: 12px;
                    color: $infoColor;
                    margin-left: 10px;
                }
            }
        }
        .data-links {
            color: $darkBlue;
            letter-spacing: 0px;
            font-size: 16px;
            font-family: 'MercuryTextG4Italic';
            line-height: 18px;
        }
    }
}
.otherSites {
    overflow: overlay;
    width: 80%;
}

@media only screen and (max-width: 767px) {
    .viwes-container {
        .inner-container {
            .data-container {
                .webisteForm {
                    flex-direction: column;
                    .addSite {
                        width: 100%;
                        flex-wrap: nowrap;
                        flex-direction: column;
                        .actionHolder {
                            margin-right: 0px;
                            width: 100%;
                            @include datePickerMobile;
                            &.siteFileHolder {
                                .siteFile {
                                    font-size: 10px;
                                }
                                .spec {
                                    display: grid;
                                }
                            }
                        }
                    }
                    .addButtonHolder {
                        width: 100%;
                        justify-content: flex-end;
                        display: inherit;
                    }
                }
                .inner-data-container {
                    .table-data {
                        .data-col-1 {
                            .data-publications {
                                font-size: 10px;
                                .siteInfo {
                                    font-size: 11px;
                                    color: $infoColor;
                                    margin-left: unset;
                                    display: block;
                                    margin-top: 5px;
                                }
                            }
                        }
                        .data-links {
                            font-size: 10px;
                            .icon-container {
                                @include tableIconsMobile;
                                font-size: 13px;
                                .lasticon {
                                    margin-right: 0px;
                                }
                            }
                        }
                    }
                    .editWebisteForm {
                        .editSite {
                            display: flex;
                            flex-direction: column;
                            width: 100%;
                            .editActionHolder {
                                margin-right: 0px;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
    .viewGraph {
        .dateRow {
            .dateRange {
                padding: 10px 0px;
                font-size: 12px;
            }
        }
        .table-data {
            .data-col-1 {
                .data-publications {
                    .siteInfo {
                        font-size: 11px;
                        color: $infoColor;
                        margin-left: unset;
                        display: block;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .viwes-container {
        .inner-container {
            .data-container {
                .inner-data-container {
                    .table-data {
                        .data-col-1 {
                            .data-publications {
                                .siteInfo {
                                    font-size: 11px;
                                    color: $infoColor;
                                    margin-left: unset;
                                    display: block;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .viewGraph {
        .table-data {
            .data-col-1 {
                .data-publications {
                    .siteInfo {
                        font-size: 11px;
                        color: $infoColor;
                        margin-left: unset;
                        display: block;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
