@import '../../../../../listingMixins';

.top-nav {
    @include subnav;

    margin-top: 5px;
    padding-bottom: 20px;
    border-bottom: solid 1px $goldColor;

    .nav-link {
        margin-right: 30px;
        cursor: pointer;
    }
}
.circularOverlay {
    @include loaderOverlay;
    .loadingText {
        @include loadingOverlayText;
    }
}

@media only screen and (max-width: 1024px) {
    .top-nav {
        display: flex;
        flex-wrap: wrap;
        .nav-link {
            margin-bottom: 10px;
        }
    }
}
