@import '../../styles/colors';
.timeContainer {
    display: flex;
    .inputHolder {
        display: flex;
        align-items: flex-start;
        .timeInput {
            width: 45px !important;
            border-radius: 12px !important;
        }
        .separator {
            font-size: 24px;
            color: $darkGray;
            margin: 0px 3px;
        }
        input {
            text-align: center;
        }
    }
    .buttonHolder {
        display: flex;
        flex-direction: column;
        font-size: 9px;
        margin-left: 8px;
        .timeButton {
            border: 1px solid $darkGray;
            background-color: $whiteColor;
            font-family: 'BentonSansBook';
            letter-spacing: 1px;
            cursor: pointer;
        }
        .selectedButton {
            background-color: $sirBlue;
            color: $whiteColor;
            border: 1px solid $sirBlue;
        }
        .amButton {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
        }
        .pmButton {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
        }
    }
}
