@import '../../../cmrMixins.scss';

.email-container {
    .inner-container {
        .form-container {
            padding: 10px;
            height: auto;
            background-color: $backgroundGray;
            display: flex;
            align-items: center;
            box-shadow: 0px 1px 4px $infoColor;

            .add-email-campaign {
                display: flex;
                flex-wrap: wrap;

                .actionHolder {
                    margin-right: 20px;
                    width: 30%;
                    margin-bottom: 10px;

                    .cmrInput {
                        @include inlineFormInput;
                    }

                    @include datePickerInput;
                    @include dateField;

                    &.ecFileHolder {
                        .ecFile {
                            @include fileFields;
                        }

                        .spec {
                            @include specText;
                        }
                    }
                }
            }

            .addButtonHolder {
                @include inlineAddButton;
            }
        }

        .stats-container {
            display: flex;
            padding: 30px 10px 30px 10px;
            border-bottom: 1px solid $lightGray;
            justify-content: space-between;

            .stats-value {
                width: 18%;
                text-align: center;
                @include mediumFont(
                    $lineHeight: 18px,
                    $color: $sirBlue,
                    $letterSpacing: 0.02px
                );
                .text-value {
                    min-height: 18px;
                }

                .stats-text {
                    display: block;
                    padding-top: 10px;
                    @include mediumFont(
                        $fontSize: 12px,
                        $lineHeight: 18px,
                        $color: $goldColor,
                        $letterSpacing: 0.02px,
                        $textTransform: uppercase
                    );
                }
            }
        }

        .table-heading {
            @include tableHeading($padding: 15px 0, $fontColor: $infoColor);

            .small {
                width: 10%;
            }

            .large {
                width: 16%;
            }

            .name {
                width: 30%;
            }

            .audience {
                width: 14%;
            }
        }

        .inner-data-container {
            .table-data {
                @include tableRow;

                .data-text {
                    letter-spacing: 0px;
                    @include bookFont;
                    color: $sirBlue;
                    line-height: 20px;
                }

                .date-text {
                    line-height: 20px;
                    @include dateFormat;
                }

                .small {
                    width: 10%;
                }

                .large {
                    width: 16%;
                }

                .data-name {
                    width: 30%;
                    line-break: anywhere;
                }

                .data-audience {
                    width: 14%;
                    line-break: anywhere;
                }

                .email-campaigns-icons {
                    @include tableIcons;
                }
            }

            .edit-form-container {
                padding: 10px;
                padding-left: 5px;
                padding-right: 15px;
                height: auto;
                z-index: 1000;
                position: relative;
                background-color: $whiteColor;

                .edit-email-campaign {
                    display: flex;
                    flex-direction: row;
                    margin-bottom: 10px;

                    .editActionHolder {
                        display: flex;
                        padding-left: 5px;
                        @include bookFont;

                        .cmrEditDate {
                            .MuiInput-root {
                                &:hover::before {
                                    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                                }

                                input {
                                    color: #333;
                                    @include bookFont;
                                }
                            }
                        }

                        .cmrEditInput {
                            width: 80%;
                            @include bookFont;
                            color: #333;

                            &:hover::before {
                                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                            }

                            &::after {
                                border: none;
                            }
                        }

                        .editName {
                            margin-left: 15px;
                            margin-right: 5px;
                        }

                        .editEmailSent {
                            margin-left: 30px;
                            margin-right: 10px;
                            width: 80%;
                        }

                        .editViews {
                            margin-left: 20px;
                            width: 60%;
                        }

                        .editClicks {
                            width: 70%;
                        }

                        .editAudience {
                            margin-right: 30px;
                            width: 80%;
                        }

                        @include datePickerEdit($color: #333);
                    }
                }

                .editFileHolder {
                    display: flex;
                    flex-direction: row;
                    margin-left: 5px;

                    .cmrUrlInput {
                        width: 90%;
                        @include bookFont;
                        color: #333;
                        margin-right: 5px;

                        &:hover::before {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                        }

                        &::after {
                            border: none;
                        }
                    }

                    .ecFileHolder {
                        .ecFile {
                            @include fileFieldsEdit;
                            display: block;
                        }

                        .spec {
                            @include bookFont($fontSize: 10px);
                            display: inline-block;
                            color: #ccc;
                            cursor: pointer;
                        }
                    }
                }

                .editButtonHolder {
                    @include inlineEditButtons;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .email-container {
        .inner-container {
            .form-container {
                .add-email-campaign {
                    gap: 0px 25px;

                    .actionHolder {
                        width: 45%;
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .email-container {
        .inner-container {
            .inner-data-container {
                .edit-form-container {
                    .edit-email-campaign {
                        .editActionHolder {
                            .editEmailSent,
                            .editViews,
                            .editClicks {
                                margin-left: 0;
                                width: 80%;
                            }
                        }
                    }

                    .editFileHolder {
                        .actionHolder {
                            .cmrUrlInput {
                                width: 85%;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .email-container {
        .inner-container {
            .form-container {
                flex-direction: column;

                .add-email-campaign {
                    .actionHolder {
                        width: 100%;
                        margin-right: 0;
                        @include datePickerMobile;

                        &.ecFileHolder {
                            .ecFile {
                                font-size: 10px;
                            }

                            .spec {
                                display: grid;
                            }
                        }
                    }
                }

                .addButtonHolder {
                    width: 100%;
                    justify-content: flex-end;
                    display: inherit;
                }
            }

            .stats-container {
                padding: 30px 0 0 0;
                flex-wrap: wrap;
                justify-content: start;

                .stats-value {
                    width: 30%;
                    margin-bottom: 30px;
                    margin-right: 3%;
                    font-size: 9px;

                    .stats-text {
                        font-size: 10px;
                    }
                }
            }

            .table-heading {
                @include tableHeadingMobile;
            }

            .inner-data-container {
                .table-data {
                    .date-text,
                    .data-text {
                        @include tableRowMobile;
                    }

                    .data-name,
                    .data-date {
                        padding-right: 10px;
                    }

                    .email-campaigns-icons {
                        @include tableIconsMobile;
                    }
                }

                .edit-form-container {
                    .edit-email-campaign {
                        flex-direction: column;
                        margin-bottom: 0;

                        .editActionHolder {
                            width: 100%;
                            display: block;
                            margin-bottom: 12px;

                            .cmrEditInput {
                                width: 100%;
                            }

                            .editName,
                            .editEmailSent,
                            .editViews {
                                margin: 0;
                            }
                        }
                    }

                    .editFileHolder {
                        flex-direction: column;

                        .actionHolder {
                            margin-bottom: 12px;

                            .cmrUrlInput {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
