@import '../../../developmentDetailsMixins';

.developmentDetailsEdit {
    .field-label {
        label {
            text-transform: capitalize;
        }

        text-transform: capitalize !important;
    }

    .section {
        border-bottom: 1px solid $lightGray;
        margin-bottom: 20px;
        padding-bottom: 20px;

        &.last {
            border-bottom: unset;
            margin-bottom: 20px;
            padding-bottom: unset;
        }
    }
}