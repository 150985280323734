@import './colors';
@import './commonMixins';
@import './typographyMixins';

@mixin title {
    @include mediumFont($color: $infoColor, $textTransform: uppercase);
}

@mixin borderDesign {
    border: 1px solid $darkGray;
    width: 100%;
    max-height: inherit;
    min-height: 200px;
    border-radius: 5px;
    padding: 40px;
}

@mixin data {
    @include regularFont($color: $sirBlue);
}

@mixin font14Ben {
    @include mediumFont($textTransform: uppercase);
}

.fullPage {
    margin: auto;
    padding: 24px 40px 80px 40px;

    .reportingPadding {
        padding: 12px 10px 80px 10px;
    }

    .circularOverlay {
        @include loaderOverlay($width: 80%);
    }

    .headerSectionBoard {
        padding: 35px;
        background-color: $sirBlue;
        color: $whiteColor;
        flex-wrap: nowrap;

        .separator {
            flex-grow: 1;
        }

        .imageSection {
            margin-right: 30px;
            width: 230px;
            height: 306px;

            img {
                width: 100%;
                height: inherit;
            }
        }

        .contentSection {
            display: inherit;
            align-items: flex-start;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;

            .topContentHolder {
                width: 100%;

                .topContent {
                    display: flex;

                    .name {
                        font-size: 48px;
                        font-family: 'FreigBigProBook';
                        line-height: 56px;
                    }

                    .editIt {
                        @include font14Ben;
                        font-size: 12px;
                        opacity: 0.6;

                        span {
                            cursor: pointer;
                            color: $whiteColor;
                            display: flex;
                            align-items: flex-start;

                            .editText {
                                padding-right: 10px;
                            }

                            .editIcon {
                                font-size: 20px;
                            }
                        }
                    }
                }

                .headerSubTitle {
                    @include font14Ben;
                }
            }

            .bottomContent {
                width: 100%;
                @include font14Ben;

                .circle {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    right: 0;
                    bottom: 1px;
                    display: inline-block;
                    margin-right: 5px;

                    &.green {
                        background-color: $green;
                    }

                    &.red {
                        background-color: $red;
                    }
                }

                .lastUpdate {
                    display: flex;
                    color: $backgroundGray;
                    opacity: 0.6;

                    a {
                        color: $backgroundGray;
                    }

                    .lastUpdateText {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .sectionSplit {
        margin-top: 42px;

        .one {
            .splitHolderLR {
                margin-right: 10px;
            }
        }

        .two {
            .splitHolderLR {
                margin-left: 10px;
            }
        }

        .splitHolderLR {
            @include borderDesign;

            .title {
                @include title;
                margin-bottom: 32px;

                .edit-form {
                    padding: 15px;
                    cursor: pointer;
                    @include mediumFont(12px, 12px, $lightBlue, 1px, uppercase, 400);
                    border: none;
                    background-color: transparent;

                    .edit-form-icon {
                        color: $lightBlue;
                        margin: 0px 7px;
                    }
                }
            }

            .content {
                margin-bottom: 32px;

                .data {
                    @include data;
                    span {
                        a {
                            color: inherit;
                        }
                    }
                }

                .data-inline {
                    display: inline;
                }

                .subTitle {
                    @include title;
                    margin-bottom: 4px;
                }

                .clickable {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }

            .content-inline {
                display: inline;
            }
        }
    }

    .sectionCombined {
        margin-top: 42px;

        .splitHolder {
            overflow-y: auto;
            @include borderDesign;

            .title {
                @include title;
                margin-bottom: 32px;

                .edit-form {
                    padding: 15px;
                    cursor: pointer;
                    @include mediumFont(12px, 12px, $lightBlue, 1px, uppercase, 400);
                    border: none;
                    background-color: transparent;

                    .edit-form-icon {
                        color: $lightBlue;
                        margin: 0px 7px;
                    }
                }
            }

            .content {
                margin-bottom: 32px;

                .data {
                    @include data;
                }

                .subTitle {
                    @include title;
                    margin-bottom: 4px;
                }
            }

            .scrollSection {
                max-height: 400px;
                overflow-y: auto;

                .content {
                    margin-bottom: 32px;
                    @include data;

                    .subTitle {
                        @include title;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .fullPage {
        margin: auto;
        padding: 12px 20px 40px 20px;
        .sectionSplit {
            margin-top: 20px;
            .splitHolderLR {
                padding: 20px;
            }
            .one {
                .splitHolderLR {
                    margin-right: unset;
                }
            }
            .two {
                margin-top: 20px;
                .splitHolderLR {
                    margin-left: unset;
                }
            }
        }
        .sectionCombined {
            margin-top: 20px;
            .splitHolder {
                padding: 20px;
            }
        }
        .headerSectionBoard {
            padding: 20px;
            .imageSection {
                margin-right: 20px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .fullPage {
        margin: auto;
        padding: 12px 20px 40px 20px;
        .sectionSplit {
            margin-top: 20px;
            .splitHolderLR {
                padding: 20px;
            }
            .one {
                .splitHolderLR {
                    margin-right: unset;
                }
            }
            .two {
                margin-top: 20px;
                .splitHolderLR {
                    margin-left: unset;
                }
            }
        }
        .sectionCombined {
            margin-top: 20px;
            .splitHolder {
                padding: 20px;
            }
        }
        .headerSectionBoard {
            padding: 20px;
            flex-wrap: wrap;
            display: flex;
            justify-content: center;
            .imageSection {
                margin-right: unset;
                margin-bottom: 25px;
            }
        }
    }
}
