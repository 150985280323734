@import '../../../../../listingMixins';

.dateSelect {
    @include dateField($color: $infoColor, $fontSize: 14px !important);
    .dateClass {
        input[type='date']:required:invalid::-webkit-datetime-edit {
            color: transparent;
        }
        input[type='date']:focus::-webkit-datetime-edit {
            color: black !important;
        }
    }

    @include datePickerInput(
        $fontSize: 14px !important,
        $placeholderColor: $darkGray,
        $elementColor: $darkGray
    );
}

.datePickerClass {
    @include customInput;
    outline: none;
    box-shadow: none;
    width: 100%;
    padding: 4px 0px;
}

.inputFields {
    & input {
        @include roundedInput($padding: 8px 0px 8px, $backgroundColor: $whiteColor);
    }
}
.largeText {
    padding: 5px 22px;
}
.smallText {
    padding: 0px 22px;
}
.approved,
.customDate {
    .MuiTypography-body1 {
        font-family: 'MercuryTextG4Roman' !important;
        color: $darkGray;
    }
}
