@import "../../../shared/styles/colors";
@import "../../../shared/styles/typographyMixins";


.session-expiry-message {
    @include mediumFont(14px, 14px, $sirBlue, "");
}

.session-expiry-button-container {
    margin-top: 100px;

    >button {
        margin: 0px 10px;
    }

}