@import '../../../../../listing/listingMixins';
@import '../../../../../../../shared/styles/formMixins';
.newDomainHolder {
    align-items: center;
    .domainHeader {
        @include mediumFont($fontSize: 14px, $lineHeight: 18px, $color: $sirBlue);
    }
    .full-width {
        width: 100%;
    }
    .inputFields {
        @include customInput;
    }
    .domainAction {
        margin-top: 20px;
        .checkAvailable {
            background-color: $sirBlue;
            color: $whiteColor;
            margin: '20px 5px';
            font-family: 'MercuryTextG4Roman';
            font-size: '15px';
            background-color: $sirBlue;
            color: '#FFF';
        }
    }
}
