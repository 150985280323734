@import '../../../agentDashboardMixins';

.expertiseContainer {
    .content {
        &:not(.noBorder) {
            border-bottom: 1px solid $lightGray;
        }
        &:not(.subTitle) {
            padding-top: 18px;
        }
        .formControlRoot {
            width: 100%;
        }
        .inputBaseRoot {
            @include customInput;
            &.inputDisabled {
                background-color: $backgroundGray;
            }
        }
        .MuiInputBase-input {
            padding: 8px 0px 8px;
            color: $black;
            &:disabled {
                cursor: not-allowed;
                // color: $whiteColor;
            }
        }
        .formControlLabel {
            @include mediumFont($color: $sirBlue, $fontWeight: 400);
        }
    }
    .info-text {
        @include infoText;
    }
}
