@import '../../cmrMixins.scss';

.block {
    margin-top: 10px !important;
    padding: 0px !important;
    border: none !important;
    cursor: pointer !important;
    &:first-child {
        margin-top: 0 !important;
    }
    .content {
        padding: 12px !important;
        background-color: $sirBlue !important;
        @include mediumFont($color: #7c8ca2, $textTransform: uppercase);
        border-radius: 4px;
        &.selected {
            color: $whiteColor;
        }
        .drag {
            margin-right: 20px;
            color: #7c8ca2;
            cursor: move;
        }
    }
    .child {
        padding-top: 10px;
        padding-left: 30px;
        background-color: $whiteColor;
        .content {
            background-color: $lightGray !important;
            color: #000;
            .drag {
                margin-right: 20px;
                color: $infoColor;
                cursor: move;
            }
        }
    }
    .dropholder {
        padding: 12px !important;
        margin: 0 0 10px 30px;
        background-color: $infoColor;
        color: $lightGray;
    }
}

@media screen and (max-width: 1023px) {
    .block {
        box-shadow: none;
        .blocksummary {
            background-color: $sirBlue !important;
            padding: 0;
            min-height: auto;
            box-shadow: none;
            .MuiAccordionSummary-content {
                margin: 0;
            }
            .MuiAccordionSummary-expandIcon.Mui-expanded {
                transform: none;
            }
        }
        .blockDetails {
            display: block;
        }
        .expand {
            margin-right: 20px;
            color: #7c8ca2;
            cursor: default;
            font-size: 16px;
        }
        .child {
            box-shadow: none;
            .blocksummary {
                background-color: $lightGray !important;
            }
        }
    }
}
