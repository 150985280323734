@import '../../styles/typographyMixins';

.videoText {
    @include mediumFont($fontSize: 16px);
    min-height: 23px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
        .tooltip-icon {
            margin: 0px 5px;
            color: $infoColor;
            font-size: 12px;
        }
    }
    .delete {
        color: $darkGray;
        font-size: 12px;
        text-decoration: underline;
        font-family: 'MercuryTextG4Roman';
        text-transform: capitalize;
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        .deleteIcon {
            margin-right: 5px;
        }
    }
}
.videoAction {
    height: 180px;
    width: auto;
    background-color: $backgroundGray;
    margin: 20px 0;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    &.hasVideo {
        margin-bottom: 0;
    }
    .videoSection {
        width: 100%;
        height: 100%;
    }
    .videoUpload {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .video {
            display: none;
        }
    }
    .smallOverlay {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        color: $whiteColor;
        .text {
            span {
                display: flex;
                width: 100%;
                justify-content: center;
                margin-bottom: 5px;
            }
            .customColor {
                background-color: $red;
            }
        }
    }
}
.videoDetails {
    font-family: 'MercuryTextG4Roman';
    font-size: 14px;
    color: $sirBlue;
    background-color: $backgroundGray;
    .holder {
        border-bottom: 1px solid $infoColor;
        padding: 10px 0;
        margin: 0 10px;
        display: flex;
        align-items: center;
        &:last-child {
            border-bottom: none;
        }

        .leftContent {
            &.title {
                width: 10%;
            }
            &.description {
                width: 10%;
            }
            &.link {
                width: 30%;
            }
        }
        .italic {
            font-style: italic;
        }
        .rightContent {
            color: $infoColor;
            width: 90%;
            a {
                color: $infoColor;
                text-decoration: underline;
            }
            button.link {
                background-color: transparent;
                background-repeat: no-repeat;
                border: none;
                cursor: pointer;
                overflow: hidden;
                outline: none;
                color: $infoColor;
                font-family: inherit;
                font-size: inherit;
                font-style: inherit;
                padding: 0;
                text-decoration: underline;
            }
            &.title {
                width: 90%;
            }
            &.description {
                width: 90%;
            }
            &.link {
                width: 70%;
            }
            &.vidoeText {
                color: $darkGray;
            }
            .videoCaption {
                input {
                    padding: 2px;
                    @include bookFont(14px, 400, $darkGray);
                }
            }
        }
    }
}
