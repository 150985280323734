@import '../../../cmrMixins.scss';

.emailSentHolder {
    border-top: 1px solid #707070;
    .emailSentHeader {
        margin: 35px 0;
        @include mediumFont($color: $sirBlue, $textTransform: uppercase);
        .emailSentHeaderText {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .emailSentContent {
        background-color: $whiteColor;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        padding: 28px 22px;
        .emailSentTable {
            display: flex;
            flex-direction: column;
            .tableHead,
            .tableBody {
                .section {
                    width: 33%;
                    margin: 10px 0;
                    color: $sirBlue;
                }
            }
            .tableHead {
                border-bottom: 1px solid #707070;
                padding: 0 4px;
                display: flex;
                .section {
                    @include mediumFont($fontSize: 12px);
                    &.date {
                        text-align: right;
                        padding-right: 58px;
                    }
                }
            }
            .tableBody {
                max-height: 320px;
                overflow: overlay;
                .content {
                    border-bottom: 1px solid #707070;
                    width: 100%;

                    padding: 0 4px;
                    display: flex;
                    .section {
                        font-family: 'MercuryTextG4Roman';
                        font-size: 14px;
                        &.date {
                            text-align: right;
                            padding-right: 15px;
                        }
                        &.email {
                            word-break: break-all;
                            padding-right: 2px;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .emailSentHolder {
        .emailSentHeader {
            margin: 20px 0;
            font-size: 12px;
        }
        .emailSentContent {
            padding: 14px 11px;
            .emailSentTable {
                .tableHead {
                    .section {
                        &.date {
                            padding-right: 49px;
                        }
                    }
                }
                .tableBody {
                    .content {
                        .section {
                            font-size: 12px;
                        }
                    }
                    .icon-container {
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .emailSentHolder {
        margin-bottom: 10px;
        .emailSentHeader {
            margin: 10px 0;
            font-size: 12px;
        }
        .emailSentContent {
            padding: 14px 11px;
            .emailSentTable {
                .tableHead {
                    padding: 0 13px 0 0px;
                    .section {
                        font-size: 10px;
                        width: 31%;
                        &.date {
                            padding-right: 40px;
                        }
                    }
                }
                .tableBody {
                    .content {
                        padding: 0;
                        .section {
                            font-size: 10px;
                            word-break: break-word;
                            width: 30%;
                        }
                    }
                    .icon-container {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
