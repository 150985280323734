@import '../../styles/colors';
@import '../../styles/typographyMixins';
@import '../../styles/commonMixins';

.content {
    .modalControlRoot {
        width: 80%;
    }

    .modalInputBaseRoot {
        @include bookFont($color: $darkGray, $fontWeight: 400);
        @include customInput;
    }

    .MuiInputBase-input {
        padding: 8px 0px 8px;
        color: $black;
    }
}

.buttonHolder {
    display: flex;
    justify-content: flex-end;
    margin: 10px 18px 0px 10px;

    .button {
        padding: 10px;
    }
}

.noMls {
    @include mediumFont($color: $sirBlue);
}