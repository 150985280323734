@import "../../styles/colors";

.footerHolder {
    background-color: $darkBlue;
    margin: auto;
    max-width: 1300px;
    .footer {
        margin: auto;
        max-width: 1300px;
        padding: 24px 32px 23px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .textHolder {
            color: $whiteColor;
            font-size: 14px;
            font-family: "FreigBigProBook";
        }
    }
}
