@import '../cmrMixins.scss';

.header-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 500px;
    .image-container {
        width: 65%;
        background-color: $lightGray;
        position: relative;
        text-align: center;
        .cmrVideoPlayer {
            width: 100%;
            height: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            &.portrait {
                width: auto !important;
            }
        }
        .imageActionText {
            @include mediumFont(
                $fontSize: 12px,
                $color: $whiteColor,
                $textTransform: uppercase
            );
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 0 10px 10px 0;
            cursor: pointer;
            padding: 12px;
            background-color: $sirBlue;
            border: 1px solid $sirBlue;
        }
        .videoActionText {
            margin: 0 10px 40px 0;
        }
        .line {
            position: absolute;
            border: 1px solid $goldColor;
            background-color: $goldColor;
            top: 63px;
            right: -50px;
            width: 100px;
        }
        .no-image-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            text-align: center;
            text-transform: uppercase;
            background-color: $lightGray;

            @include mediumFont(
                $lineHeight: 17px,
                $color: $whiteColor,
                $letterSpacing: 0.02px
            );
        }
    }
    .details-container {
        width: 35%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .top {
            margin: 40px 0px 0px 70px;
            .title {
                font-size: 48px;
                line-height: 60px;
                font-family: 'FreigBigProBook';
                letter-spacing: 0.03px;
                color: $sirBlue;
                margin-bottom: 5px;
            }
            .address {
                font-size: 28px;
                line-height: 40px;
                font-family: 'MercuryDisplayItalic, MercuryDisplayRoman';
                letter-spacing: 0.02px;
                color: $darkGray;
                margin-bottom: 5px;
                font-style: italic;
            }
            .city {
                @include bookFont($color: $darkGray, $textTransform: uppercase);
                line-height: 13px;
                letter-spacing: 0.03px;
            }
        }
        .bottom {
            text-align: end;
            margin-right: 20px;
            .content {
                @include bookFont;
                line-height: 26px;
                letter-spacing: 0.03px;
                color: $darkGray;
                margin-left: 25px;
            }
            .buttons {
                margin-top: 10px;
                .action-button {
                    box-shadow: 0px 3px 6px #00000029;
                    padding: 12px;
                    border: 1px solid $sirBlue;
                    cursor: pointer;
                    @include mediumFont(
                        $fontSize: 12px,
                        $textTransform: uppercase,
                        $letterSpacing: 0.8px
                    );
                    &:first-child {
                        margin-right: 25px;
                    }
                }
                .settings {
                    color: $whiteColor;
                    background-color: $sirBlue;
                }
                .report {
                    color: $sirBlue;
                    background-color: $whiteColor;
                }
            }
        }
    }
    .loaderClass {
        width: 100%;
        height: 100%;
    }
}

.scheduler {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 85%;
    background-color: $whiteColor;
    position: absolute;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .header-container {
        .details-container {
            .top {
                margin: 40px 0px 0px 60px;
                .title {
                    font-size: 40px;
                }
                .address {
                    font-size: 30px;
                }
                .city {
                    font-size: 14px;
                }
            }
            .bottom {
                .buttons {
                    .action-button {
                        padding: 10px;
                        &:first-child {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .header-container {
        display: flex;
        flex-direction: column;
        height: auto;
        .image-container {
            width: 100%;
            height: 400px;
            img.portrait {
                width: auto !important;
            }
            .imageActionText {
                font-size: 12px;
            }
            .line {
                border: 2px solid $goldColor;
                top: unset;
                right: 50%;
                width: 3px;
                bottom: -50px;
                height: 100px;
            }
        }
        .details-container {
            width: 100%;
            .top {
                margin: 50px 0;
                text-align: center;
                .title {
                    margin-bottom: 15px;
                }
                .address {
                    margin-bottom: 10px;
                }
            }
            .bottom {
                text-align: center;
                margin-right: unset;
            }
        }
    }
    .scheduler {
        width: 96%;
    }
}

@media only screen and (max-width: 426px) {
    .header-container {
        display: flex;
        flex-direction: column;
        height: auto;
        .image-container {
            width: 100%;
            height: 400px;
            .imageActionText {
                font-size: 8px;
                padding: 10px;
                margin: 0 8px 8px 0;
            }
            .videoActionText {
                margin: 0 8px 40px 0;
            }
            .line {
                bottom: -34px;
                height: 60px;
                border: 0px solid $goldColor;
            }
        }
        .details-container {
            width: 100%;
            .top {
                margin: 40px 0;
                text-align: center;
                .title {
                    font-size: 20px;
                    margin-bottom: unset;
                }
                .address {
                    font-size: 18px;
                    margin-bottom: unset;
                    line-height: 25px;
                }
            }
            .bottom {
                text-align: center;
                margin-right: unset;
                .content {
                    font-size: 16px;
                    margin: 0 40px;
                    line-height: 22px;
                }
                .buttons {
                    margin-top: 30px;
                    .action-button {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}
