@import '../../cmr/cmrMixins.scss';

.contentHolder {
    ::placeholder {
        color: $placeholderColor;
        opacity: 1;
    }
    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: $placeholderColor;
    }
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
    min-height: 1000px;
    .loaderClass {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    .leftHolder {
        width: 30%;
        box-shadow: 0px 1px 4px $infoColor;
        .leftOverlay {
            top: 0px;
            position: sticky;
            .leftLoaderClass {
                position: absolute;
                width: 100%;
                height: 100%;
                .MuiCircularProgress-colorPrimary {
                    color: #ffffff !important;
                }
            }
        }
    }
    .rightHolder {
        width: 70%;
        box-shadow: 3px 0px 10px -4px $infoColor;
        padding: 30px;
        overflow-y: auto;
        .rightOverlay {
            position: relative;
            .rightLoaderClass {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }
    }
    .datePickerCommon {
        border: none;
        background: none;
        outline: none;
        box-shadow: none;
        width: 100%;
        @include formElement($color: $infoColor, $fontSize: 14px);
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
        padding: 4px 0px;
        border-radius: 0px;
    }
}
.ant-picker-time-panel-column::after {
    display: inline !important;
}
a.ant-picker-now-btn {
    @include mediumFont($fontSize: 12px, $color: $sirBlue);
    padding: 3px;
}
.ant-picker-ok > .ant-btn {
    @include mediumFont($fontSize: 12px, $color: $whiteColor);
    background-color: $sirBlue;
    border: 1px solid $sirBlue;
}

.overlayEditForm {
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
}

.contentHolder1 {
    padding: 10px 40px;
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 1023px) {
    .contentHolder {
        padding: 10px;
        min-height: auto;
        .rightHolder {
            display: none;
        }
        .leftHolder {
            width: 100%;
            .leftOverlay {
                position: unset;
            }
            .leftHolderContent {
                display: block;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .contentHolder1 {
        justify-content: center;
    }
}
