@import '../../../../styles/typographyMixins';
@import '../../../../styles/colors';

.imageCaptionContainer {
    position: absolute;
    height: 15%;
    width: 100%;
    top: 43%;
    display: flex;
    align-items: center;
    .actionHolder {
        @include mediumFont($fontSize: 20px);
        width: 100%;
        color: $darkGray;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        margin: 0px 20px;
        box-shadow: 0px 0px 6px 3px #00000029;
        .captionInput {
            width: 80%;
            font-family: 'MercuryTextG4Roman' !important;
            font-size: 20px;
            padding: 10px 100px 10px 15px;
            color: #333;
            background-color: $whiteColor;
            &:hover::before {
                border-bottom: none;
            }
            &::before {
                border: none;
            }
            &::after {
                border: none;
            }
        }
        .saveAction {
            width: 20%;
            text-transform: uppercase;
            padding: 18px 0 17px 0px;
            border: 1px solid $sirBlue;
            cursor: pointer;
            @include mediumFont;
            background-color: $sirBlue;
            color: $whiteColor;
        }
        .textCount {
            @include mediumFont;
            background-color: $whiteColor;
            position: absolute;
            right: 26.5%;
        }
    }
}
.imageCaptionContainerFullScreen {
    justify-content: center;
    .actionHolder {
        width: 80%;
    }
}
