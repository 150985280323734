.testimonialsAdd {
    .formControlRoot {
        width: 100%;
    }
    .content {
        margin-bottom: 15px;
        &.noMarginBottom {
            margin-bottom: 0;
        }
        .customTextArea {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
    .marginLeft {
        margin-left: 15px;
    }
}
