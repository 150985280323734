@import '../../../styles/colors';
@import '../../../styles/typographyMixins';
@import '../../../styles/commonMixins';

.imageUploadSection {
    margin: 15px 0px;
    .uploadSectionRows {
        .uploadRow {
            display: flex;
            align-items: center;
            .imageHolder {
                margin: 0px;
                section {
                    text-align: center;
                    height: 115px;
                    border: 2px $infoColor dashed;
                    border-radius: 25px;
                    margin-bottom: 10px;

                    p {
                        @include regularFont($sirBlue, 400, 12px, none);
                        height: 50px;
                        padding: 10px;
                    }
                }
                @include mediumFont($color: $darkBlue);
                .imageSiteFile {
                    cursor: pointer;
                    text-transform: uppercase;
                    border: 1px solid $sirBlue;
                    padding: 8px 10px;
                    background-color: $whiteColor;
                    .file {
                        display: none;
                    }
                    .fileIcon {
                        margin-right: 10px;
                    }
                }
                .disabledFile {
                    border: 1px solid $infoColor;
                    cursor: default;
                    color: $infoColor;
                }
            }
            .noteText {
                @include MercuryTextG4Roman();
            }
        }
        .uploadCriteriaText {
            margin-top: 30px;
            font-family: 'MercuryTextG4Roman' !important;
            color: $darkGray;
            font-size: 14px;
            width: 31%;
        }
        .errorRow {
            font-family: 'MercuryTextG4Roman' !important;
            color: #ff0000;
            font-size: 14px;
            margin-top: 25px;
        }
        .outer-container {
            width: 100%;
            .parent-holder {
                display: flex;
                width: 100%;
                .inputRow {
                    width: 70%;
                    display: flex;
                    justify-content: flex-end;
                    .actionHolder {
                        @include mediumFont($fontSize: 12px);
                        color: $red;
                        font-weight: bold;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: flex-end;
                        align-items: baseline;
                        .creditText {
                            margin-right: 20px;
                            font-size: 14px;
                        }
                        .credit-input-holder {
                            width: 65%;
                            .creditInput {
                                @include customInput;
                                padding: 0 8px;
                                &:hover::before {
                                    border-bottom: none;
                                }
                                &::before {
                                    border: none;
                                }
                                &::after {
                                    border: none;
                                }
                            }
                            .noteTextHolder {
                                @include mediumFont($fontSize: 12px);
                                color: $red;
                                margin-top: 5px;
                            }
                        }
                    }
                }
                .mlsImageImport {
                    // text-align: right;
                    // width: 24%;
                    align-items: baseline;
                    width: 30%;
                    .checkbox:checked {
                        color: $sirBlue;
                    }
                    .checkbox:hover {
                        background-color: transparent;
                    }
                    .checkboxLabel {
                        color: $darkGray;
                        @include mediumFont($fontSize: 12px);
                    }
                }
            }
        }
    }
    .circularOverlay {
        @include loaderOverlay;
        .uploadingText {
            @include loadingOverlayText;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .imageUploadSection {
        .uploadSectionRows {
            .uploadRow {
                .imageHolder {
                    width: 50%;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .imageUploadSection {
        .uploadSectionRows {
            .uploadRow {
                .imageHolder {
                    margin-bottom: 20px;
                }
            }
            .inputRow {
                .actionHolder {
                    margin-bottom: 20px;
                }
                .mlsImageImport {
                    text-align: unset;
                }
            }
        }
    }
}
