@import '../../../shared/styles/newGridDesigns.scss';
@import '../../../shared/styles/typographyMixins';
@import '../../../shared/styles/colors';

.table-container {
    .table-header {
        .table-row {
            .table-cell {
                @include mediumFont($color: $darkGray, $textTransform: uppercase);

                .MuiTableSortLabel-active {
                    color: $darkGray;
                }
            }
        }
    }

    .table-body {
        .table-row:hover {
            // cursor: pointer;
            background-color: $lightGray;
        }

        .table-cell {
            @include regularFont($sirBlue, 0, 14px, none);

            .office-valid-checkbox {
                color: $sirBlue;
            }

            .status-active {
                color: $green;
            }

            .status-inactive {
                color: $red;
            }

            img {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                object-fit: cover;
                object-position: top;
            }

            .fa-xmark {
                color: $red;
            }

            .fa-check {
                color: $green;
            }

            .customSwitch {
                @include customSwitch;
            }

            .customLink {
                @include regularFont($sirBlue, 0, 14px, none);
                text-decoration: underline;
            }
            a {
                color: inherit;
            }
            &.link {
                cursor: pointer;
            }
            &.exteranl {
                padding: unset;
                a {
                    cursor: pointer;
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    padding: 6px 24px 6px 16px;
                }
            }
            .fileIcon {
                cursor: pointer;
            }
        }

        .progress-bar {
            color: $sirBlue;
        }

        .noResults {
            text-align: center;
        }
    }

    .table-footer {
        bottom: 0px !important;
        position: sticky;
        background-color: $whiteColor;

        .table-row {
            .table-pagination {
                @include mediumFont($color: $sirBlue);
            }
        }
    }
    &.assignListing {
        .table-header {
            .table-row {
                .table-cell {
                    font-size: 12px;
                }
            }
        }
        .table-body {
            .table-cell {
                font-size: 12px;
                .plus {
                    padding: 10px;
                    &:hover {
                        background-color: $whiteColor;
                    }
                }
            }
        }
    }
}
