@import '../cmrMixins.scss';

.buttons {
    margin-top: 10px;
    .action-button1 {
        box-shadow: 0px 3px 6px #00000029;
        padding: 12px;
        border: 1px solid $sirBlue;
        cursor: pointer;
        @include mediumFont(
            $fontSize: 12px,
            $textTransform: uppercase,
            $letterSpacing: 0.8px
        );
        &:first-child {
            margin-right: 25px;
        }
    }
    .settings {
        color: $whiteColor;
        background-color: $sirBlue;
    }
    .report {
        color: $sirBlue;
        background-color: $whiteColor;
    }
}

.modal__bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(28, 28, 28, 0.19);
    backdrop-filter: blur(6px);
    opacity: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
    animation-name: modal-video;
    -webkit-transition: opacity 0.3s ease-out;
    -moz-transition: opacity 0.3s ease-out;
    -ms-transition: opacity 0.3s ease-out;
    -o-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
    z-index: 100;
}

.modal__align {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.modal__content {
    width: 800px;
    height: 500px;
    box-shadow: 0px 100px 80px rgba(184, 184, 184, 0.07),
        0px 25.8162px 19px 4px rgba(178, 178, 178, 0.0456112),
        0px 7.779px 7.30492px rgba(0, 0, 0, 0.035),
        0px 1.48838px 2.0843px rgba(0, 0, 0, 0.0243888);
    border-radius: 20px;
    background: transparent;
    color: #000;
    margin: 0rem 4rem;
}

.modal__close {
    background-color: $whiteColor;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    bottom: 50px;
    width: 32px;
    height: 32px;
    padding: 0;
}

.modal__video-align {
    display: flex;
    position: relative;
    bottom: 37px;
}

.modal__video-style {
    border-radius: 20px;
    z-index: 100;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .modal__bg {
        .modal__align {
            .modal__content {
                width: auto;
                height: auto;
                .modal__video-align {
                    video {
                        width: 500px;
                        height: 300px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .buttons {
        .action-button1 {
            &:first-child {
                margin-right: 0px;
            }
        }
    }
}

@media only screen and (max-width: 426px) {
    .buttons {
        .action-button1 {
            font-size: 10px;
            &:first-child {
                margin-right: 0px;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .modal__bg {
        .modal__align {
            .modal__content {
                width: auto;
                height: auto;
                .modal__video-align {
                    video {
                        width: 350px;
                        height: 250px;
                    }
                }
            }
        }
    }
}
