@import '../../../shared/styles/colors';
@import '../../../shared/styles/commonMixins';
.officeSearchHolder {
    .wide-field {
        width: 100%;
        > div > div {
            background-color: transparent;
        }
        .customInput {
            width: 100%;
            @include customInput;
        }
    }
    .customButton {
        &.space {
            margin-left: 10px;
        }
    }
}
