@import '../../../shared/styles/colors';
@import '../../../shared/styles/typographyMixins';
@import '../../../shared/styles/drawerMixins';
@import '../../../shared/styles/commonMixins';
@import '../../../shared/styles/datePickerMixins';
@import '../../../shared/styles/formMixins';

.searchAutoComplete {
    @include customSearchAutoComplete;
}

.arrow.mergelistingRedirectIcon {
    position: absolute;
    right: 1%;
    bottom: 15%;
}