@import '../../shared/styles/newGridDesigns.scss';
@import '../../shared/styles/colors.scss';
@import '../../shared/styles/typographyMixins';

.listing-list-page {
    .tab-panel-holder {
        // overflow: hidden;
        width: 100%;

        .table-holder {
            margin-top: 20px;
            // overflow: hidden;
            position: relative;
            .info-text-listing {
                @include MercuryTextG4Roman();
            }
            .loaderClass {
                position: absolute;
                width: 100%;
            }

            .grid-parent-holder {
                // height: 1000px;
                min-height: 1000px;
                // overflow-y: auto;
                overflow: hidden;
                width: 100%;
            }

            .my-listings {
                min-height: unset;
                overflow: unset;
            }
        }

        .totalRecords {
            margin-bottom: 10px;
            align-items: center;

            .section-one {
                display: flex;
                width: 60%;
                justify-content: space-between;
                align-items: center;
                .total-records-text {
                    width: 50%;
                }
                .sort-drop-down {
                    align-items: center;
                }
            }

            .selected {
                text-decoration: underline;
            }

            .actionItem {
                cursor: pointer;
            }
        }
    }

    .listing-option {
        .action-button-holder {
            .newListing {
                margin-right: 20px;
            }
        }
        .listing-icon-holder {
            cursor: pointer;
            font-weight: bold;
            display: flex;
            justify-content: flex-end;

            .advSearch {
                margin-right: 20px;
            }
            div {
                line-height: 35px;
                .listing-icon {
                    margin-left: 10px;
                    font-size: 17px;
                }
            }
        }
    }

    // .listing-tabs {
    //     position: sticky;
    //     top: 0px;
    //     width: 100%;
    //     z-index: 5;
    //     background-color: $whiteColor;
    // }

    .newTabIcon {
        cursor: pointer;
        font-size: 12px;

        &.list {
            margin-left: 10px;
        }
    }
    .scrollTop-button {
        position: fixed !important;
        bottom: 90px;
        right: 25px;
    }
}

.appsTableContainer {
    // max-height: 1000px;
    .list-image {
        width: inherit;
        height: auto;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .cells {
        @include mediumFont(
            $fontSize: 12px,
            $fontWeight: 400,
            $textTransform: none,
            $color: $sirBlue
        );
        padding: 16px 8px;
        line-height: 1.4;

        &.forAll {
            padding: 8px;
            &.radio {
                text-align: left;
            }
        }

        .checkBoxRoot {
            color: $sirBlue;

            &:hover {
                background-color: transparent;
            }

            &.disabledClass {
                color: $infoColor;
            }
        }

        .listing-first-column-holder {
            display: flex;
            align-items: center;

            .list-image {
                width: 150px;
                height: 100px;
                background-color: $backgroundGray;
            }

            .first-column-content {
                margin-left: 20px;
                text-align: left;

                .address {
                    font-size: 15px;
                    margin-bottom: 10px;
                    .address-mini {
                        margin: top 5px;
                        font-size: 14px;
                    }
                }

                .others {
                    color: $black;
                    margin-bottom: 5px;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

            &.sold {
                width: 310px;
            }
        }

        &.firstCell {
            border-right: 1px solid $lightGray;
        }

        a {
            color: inherit;
            cursor: pointer;
        }
    }

    .tableBody {
        tr:hover {
            // cursor: pointer;
            background-color: $lightGray;
        }

        .noResults {
            text-align: center;
        }
    }
}

.actions {
    padding: 5px;
}

.customSwitch {
    @include customSwitch;
}

.listActionHolder {
    .listActions {
        background-color: $backgroundGray;
        width: 200px;
        padding: 10px;
        @include mediumFont(
            $fontSize: 12px,
            $fontWeight: 400,
            $textTransform: none,
            $color: $sirBlue
        );

        .listing-action {
            &:hover {
                background-color: $whiteColor;
            }

            .section {
                padding: 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-content: center;
                line-height: 1;
                cursor: pointer;
            }
        }

        .listing-details {
            .section {
                padding: 10px;

                a {
                    color: inherit;
                }

                button {
                    padding: 0;
                    font-family: inherit;
                    color: inherit;
                    text-transform: capitalize;
                    font-size: inherit;
                    justify-content: flex-start;

                    &:hover {
                        background-color: transparent;
                    }
                }

                &.heading {
                    color: $darkGray;
                    cursor: pointer;
                }

                .leftChe {
                    padding-right: 15px;
                }
            }

            .action-details {
                .section {
                    &:hover {
                        cursor: pointer;
                        background-color: $whiteColor;
                    }
                }
            }
        }
    }
}

.grid-listing-design {
    .grid-holder {
        border: 1px solid $infoColor;
        @include mercuryDisplayRoman($fontSize: 13px);

        .grid-image-holder {
            // width: 240px;
            // height: 180px;

            position: relative;
            overflow: hidden;
            margin: 0 auto;
            justify-content: center;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            &.portrait {
                img {
                    width: auto;
                }
            }
            .setHeight {
                padding-top: 66.66%;
            }
        }

        .spacing {
            padding: 10px 0 10px 5px;
        }

        .sub-spacing {
            padding: 0 0 5px 5px;

            .data-spacing {
                display: flex;
                padding: 0 0 5px 0;
                align-items: center;

                &:last-child {
                    padding: 0;
                }

                .grid-icon {
                    margin-right: 5px;
                    font-size: 12px;
                    &.red {
                        margin-right: 10px;
                    }
                }
            }
        }

        .heading-color {
            color: $infoColor;
        }

        .grid-address {
            @include mercuryDisplayRoman($fontSize: 18px, $color: $sirSiteBlue);
            line-height: 24px;
            min-height: 70px;
            .grid-address-mini {
                margin-top: 5px;
                font-size: 17px;
            }
        }
        .price {
            @include mediumFont($fontSize: 14px, $color: $black, $fontWeight: 500);
            line-height: 1.7;
        }
        .others {
            @include bookFont($fontSize: 14px, $color: $darkGray, $fontWeight: 500);
            line-height: 1.71;
        }
        .grid-section {
            .checkBoxRoot {
                color: $sirBlue;

                &:hover {
                    background-color: transparent;
                }

                &.disabledClass {
                    color: $infoColor;
                }
            }

            .soldCheck {
                padding: 0;
            }

            .customRadioGrp {
                font-size: inherit;
                font-family: inherit !important;

                span {
                    padding: 0;
                    font-family: inherit !important;
                }
            }
        }

        // }
        .grid-more-action {
            border-top: 1px solid $infoColor;
            text-align: center;

            .grid-action-section {
                border-right: 1px solid $infoColor;
                position: relative;

                &:hover {
                    cursor: pointer;
                    background-color: $lightGray;
                }

                &:last-child {
                    border-right: 0;
                }

                .grid-action {
                    padding: 10px 0px;
                    height: 100%;
                }

                .grid-action-item {
                    border: 1px solid $infoColor;
                    margin-left: -0.5px;
                    position: absolute;
                    z-index: 100;
                    background-color: $whiteColor;
                    text-align: left;
                    width: max-content;
                    display: none;

                    .grid-item {
                        line-height: 20px;
                        padding: 3px 10px !important;

                        &:hover {
                            cursor: pointer;
                            background-color: $lightGray;
                        }

                        a {
                            color: inherit;
                        }

                        button {
                            font-size: inherit;
                            color: inherit;
                            padding: 0;
                            font-family: inherit;
                            width: inherit;
                            text-align: inherit;
                            text-transform: capitalize;
                            justify-content: flex-start;

                            &:hover {
                                background-color: transparent;
                            }
                        }
                    }

                    &.is-last {
                        right: 0;
                        margin-right: -0.5px;
                    }
                }
                .shift-top {
                    bottom: 39px;
                }
            }

            .grid-reports,
            .grid-marketing,
            .grid-manage {
                &:hover {
                    .grid-action-item {
                        display: block;
                    }
                }
            }
        }

        .publish {
            margin: 5px;

            .text {
                margin-right: 10px;
            }
        }

        a {
            color: inherit;
            width: inherit;
        }
    }
}

.listing-list-tooptip {
    font-size: 13px;

    .list-icon {
        margin-right: 10px;
    }
}

.listingDescriptionSections {
    background-color: $lightGray;
    padding-top: 16px !important;

    &.is-sticky {
        padding-top: 8px !important;
    }

    .highlight.customButton {
        background-color: $gold;
        color: $whiteColor;
        border: 1px solid $gold;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .grid-listing-design {
        .grid-holder {
            .grid-address {
                min-height: 70px;
            }
            .price {
                min-height: 55px;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .grid-listing-design {
        .grid-holder {
            .grid-address {
                min-height: unset;
            }
            .price {
                min-height: unset;
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .grid-listing-design {
        .grid-holder {
            .grid-address {
                min-height: unset;
            }
        }
    }
}

@media only screen and (max-width: 450px) {
    .listing-list-page {
        .newListing,
        .mlsImport {
            width: 100%;

            .createDraft,
            .import-listing-button {
                width: inherit;
            }
            .import-listing-button {
                margin-top: 20px;
            }
        }

        .listing-option {
            justify-content: unset;

            .listing-icon-holder {
                width: 100%;
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                .advSearch {
                    width: 100%;
                }

                div {
                    margin: 20px 0;
                    display: inline-block;
                }
            }
        }
        .tab-panel-holder {
            .table-holder {
                .totalRecords {
                    flex-direction: column;
                    align-items: unset;
                    .section-one {
                        width: 100%;
                        flex-direction: column;
                        align-items: unset;
                        margin-bottom: 10px;
                        .total-records-text {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
