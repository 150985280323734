@import '../../../../../../listingMixins';

.photographerInviteContainer {
    position: relative;
    .loaderClass {
        position: absolute;
        width: 100%;
    }
    .photographerInviteSection {
        .toggleInvite {
            align-items: center;
            font-family: 'MercuryTextG4Roman';
            font-family: 15px;
            margin: 10px 0px;
            .arrowIcon {
                cursor: pointer;
            }
        }
        .toGrid,
        .messageGrid {
            margin: 10px 0px;
        }
        .toLabel,
        .messageLabel {
            @include mediumFont($fontSize: 14px, $lineHeight: 18px, $color: $sirBlue);
        }
        .email,
        .messageField {
            width: 100%;
            color: $darkGray;
            font-size: 14px;
            background-color: $backgroundGray;
            border: 1px solid $darkGray;
            border-radius: 10px;
            padding-left: 5px;
            margin: 2px;
            input {
                font-family: 'MercuryTextG4Roman';
            }
            textarea {
                font-family: 'MercuryTextG4Roman';
                min-height: 100px;
            }
        }
        .emailChip {
            font-family: 'MercuryTextG4Roman';
            font-size: 14px;
            color: black;
            margin: 2px;
            &:hover {
                background-color: $sirBlue;
                color: $whiteColor;
                .MuiChip-deleteIcon {
                    color: $whiteColor;
                }
            }
        }
        .note {
            font-family: 'MercuryTextG4Roman';
            font-size: 12px;
            color: $infoColor;
            margin: 5px 0px;
            font-weight: 400;
        }
        .sendButton {
            margin: 20px 5px;
            font-family: 'MercuryTextG4Roman';
            font-size: 15px;
            background-color: $sirBlue;
            color: $whiteColor;
            border: 1px solid $sirBlue;
            border-radius: 0px;
            &:disabled {
                background-color: $infoColor;
                color: $whiteColor;
                border: 1px solid $infoColor;
            }
            &:hover {
                background-color: $sirBlue;
                color: $whiteColor;
            }
        }
        .rejectedEmails {
            font-family: 'MercuryTextG4Roman';
            font-size: 15px;
            color: red;
            margin: 5px 0px;
        }
    }
}
