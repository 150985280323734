@import "../../../agentDashboardMixins";

.biographyContainer {
    .content {
        &:not(:nth-last-child(2)) {
            margin-bottom: 20px;
        }
        .formControlRoot {
            width: 80%;
            > div > div {
                background-color: transparent;
            }
            &.dropDownBaseRoot {
                @include customInput;
                width: 80%;
            }
            &.editorInput,
            &.headline {
                width: 100%;
            }
        }
        .inputBaseRoot {
            @include customInput;
            &.inputDisabled {
                background-color: $backgroundGray;
            }
        }
        .MuiInputBase-input {
            padding: 8px 0px 8px;
            color: $black;
            &:disabled {
                cursor: not-allowed;
                color: $whiteColor;
            }
        }
        .formControlLabel {
            @include mediumFont($color: $sirBlue, $fontWeight: 400);
        }
    }
}
