@import '../../../shared/styles/colors';
@import '../../../shared/styles/typographyMixins';
.modalStyle {
    position: absolute;
    height: 50%;
    display: flex;
    justify-content: center;
    margin: auto;
    width: 40%;
    &.full-width {
        width: 70%;
        height: 80%;
    }

    &.mid-width {
        .boxStyle {
            .modalHeader {
                .modalTitle {
                    margin: 20px 25px 0 25px;
                }
            }
            .modalContent {
                margin: 25px !important;
            }
        }
    }
    .boxStyle {
        display: flex;
        flex-direction: column;
        border: 2px solid $gold;
        .modalHeader {
            display: inherit;
            justify-content: space-between;
            .closeButtonDiv {
                color: $black;
                z-index: 1300;
                .closeButton {
                    width: 20px;
                    height: 20px;
                }
            }
            .modalTitle {
                margin: 20px 44px 0 44px;
                letter-spacing: 1px;
                @include mediumFont(
                    $color: $gold,
                    $fontSize: 18px,
                    $fontWeight: 400,
                    $textTransform: uppercase
                );
            }
        }

        .modalContent {
            position: relative;
            margin: 44px;
            overflow: auto;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 767px) {
    .modalStyle {
        &.full-width {
            width: 100%;
            height: 80%;
        }
        .boxStyle {
            .modalTitle {
                margin: 0 22px 0 22px;
            }
            .modalContent {
                margin: 22px;
                .row {
                    div {
                        word-break: break-all;
                    }
                }
            }
        }
    }
}
