@import '../../../developmentDetailsMixins';

.developmentAdCopyEdit {
    .size-header {
        margin-bottom: 20px;
        .size-title {
            color: $sirBlue;
            font-weight: 700;
            font-size: 16px;
            margin-right: 20px;
        }
    }
}
