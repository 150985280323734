@import "../../../../../../../../shared/styles/colors";

.userTokens {
    margin-top: 40px;
}
.icons {
    width: 10%;
    color: $infoColor;
    display: flex;
    padding-right: 10px;
    .edit {
        cursor: pointer;
    }
    .delete {
        cursor: pointer;
    }
    .logs {
        cursor: pointer;
    }
}
