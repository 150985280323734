@import "../../../../../../shared/styles/typographyMixins";
@import '../../../../../../shared/styles/colors';

.videoTourContainer {
    position: relative;
    .loaderClass {
        position: absolute;
        width: 100%;
    }
    .videoTourHolder {
        .videoActionHolder {
            margin-bottom: 40px;
            &.one {
                padding-right: 20px;
                padding-left: 10px;
            }
            &.two {
                padding-right: 10px;
                padding-left: 20px;
            }
        }
    }
    .checkboxHolder{
        margin-bottom: 10px;
    }
    .checkboxLabel {
        @include mediumFont($color: $sirBlue);
    }
}

.loading {
    width: inherit;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 767px) {
    .videoTourContainer {
        .videoTourHolder {
            .videoActionHolder {
                &.one {
                    padding: unset;
                }
                &.two {
                    padding: unset;
                }
            }
        }
    }
}
