@import '../../../listingMixins';

@include formLabel;
.pricesContainer {
    .info-text {
        @include infoText;
    }
    .pricesSection {
        .section {
            margin: 20px 0px;
            &:not(:nth-last-child(1)) {
                border-bottom: 1px solid $lightGray;
            }
            .content {
                margin-bottom: 10px;
                .formControlRoot {
                    width: 80% !important;
                    &.dropDownBaseRoot {
                        @include customInput;
                    }
                    &.customRadioButton {
                        padding: 8px;
                    }
                }
                .inputBaseRoot {
                    @include bookFont($color: $darkGray, $fontWeight: 400);
                    @include customInput;
                    &.inputDisabled {
                        background-color: $backgroundGray;
                    }
                }
                .MuiInputBase-input {
                    padding: 8px 0px 8px;
                    color: $black;
                    &:disabled {
                        cursor: not-allowed;
                    }
                }
                .MuiIconButton-colorSecondary:hover {
                    background-color: transparent;
                }
                .MuiCheckbox-colorSecondary.Mui-checked {
                    color: $sirBlue;
                }
                .checkboxLabel {
                    @include mediumFont($color: $sirBlue);
                }
                .description {
                    @include mediumFont($fontSize: 10px, $color: $darkGray);
                }
                .formControlLabel {
                    @include mediumFont($color: $sirBlue, $fontWeight: 400);
                }
                &.infoText {
                    @include MercuryTextG4Roman();
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .pricesContainer {
        .pricesSection {
            .section {
                .content {
                    .formControlRoot {
                        width: 95% !important;
                    }
                }
            }
        }
    }
}
