@import '../../styles/colors';
@import '../../styles/commonMixins';
@import '../../styles/drawerMixins';
@import '../../styles/typographyMixins';

@include drawer($width: 80%, $backGround: $whiteColor);

.cancelMasquerade {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    background-color: $green;
    color: $whiteColor;
    font-size: 11px;
    @include mediumFont($fontSize: 11px, $color: $whiteColor);
    .action {
        text-decoration: underline;
        cursor: pointer;
    }
}

.sectionWrapper {
    display: flex;
    margin: auto;
    background-color: $whiteColor;
    .sectionDiv {
        display: flex;
        width: 100%;
        .leftNavigation {
            height: 100%;
            background-color: $sirBlue;
            padding: 20px 40px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 20%;
            .leftNavContainer {
                position: -webkit-sticky;
                position: sticky;
                top: 20px;
                width: 100%;
                height: 80vh;
                .menuCloseIcon {
                    display: none;
                }
                .logoWrapper {
                    margin: 10px 0 30px;
                    height: 50px;
                    .logo {
                        width: auto;
                        height: 100%;
                    }
                }
                .leftNavControls {
                    letter-spacing: 1px;
                    color: $whiteColor;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-family: 'BentonSansMedium';
                    // overflow: auto;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    line-height: 14px;
                    .navRow {
                        .navItem {
                            margin-bottom: 13px;
                            cursor: pointer;
                        }
                        .dividerContent {
                            margin-bottom: 20px;
                            width: 100%;
                            .dividerLine {
                                background-color: #425365;
                                color: #425365;
                                border-width: 0px;
                                height: 1.5px;
                                width: 100%;
                            }
                        }
                        .logout-button {
                            padding: 0px;
                            @include mediumFont(14px, 14px, $whiteColor, 1px, uppercase);
                        }
                        .notifications-badge {
                            .MuiBadge-badge {
                                background-color: $goldColor;
                                top: 6px;
                                right: -8px;
                            }
                        }
                    }
                }
            }
        }
        .rightSection {
            display: inline-block;
            width: 80%;
            min-height: 100vh;
            .circularOverlay {
                @include loaderOverlay($width: 80%);
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .sectionWrapper {
        .sectionDiv {
            .leftNavigation {
                display: none;
                &.showMenu {
                    position: absolute;
                    z-index: 111;
                    width: 30%;
                    display: block;
                    .leftNavContainer {
                        .menuCloseIcon {
                            display: block;
                            color: $whiteColor;
                            font-size: 30px;
                            position: absolute;
                            right: -30px;
                            top: -15px;
                        }
                    }
                }
            }
            .rightSection {
                width: 100%;
                .circularOverlay {
                    @include loaderOverlay($width: 100%);
                }
            }
        }
    }
    @include drawer($width: 100%, $backGround: $whiteColor);
}

@media only screen and (max-width: 767px) {
    .sectionWrapper {
        .sectionDiv {
            .leftNavigation {
                &.showMenu {
                    width: 100%;
                }
            }
        }
    }
}
