@import '../../../cmrMixins.scss';
.additional-container {
    .additionPromotion {
        padding: 10px;
        height: auto;
        background-color: $backgroundGray;
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 4px $infoColor;
        .add {
            display: flex;
            flex-wrap: wrap;
            width: 90%;
            .actionHolder {
                margin-right: 10px;
                width: 30%;
                margin-bottom: 10px;
                .cmrDate {
                    input[type='date']:required:invalid::-webkit-datetime-edit {
                        color: transparent;
                    }
                    input[type='date']:focus::-webkit-datetime-edit {
                        color: black !important;
                    }
                }
                .cmrInput {
                    @include inlineFormInput;
                }
                @include datePickerInput;
                @include dateField;
                &.apFileHolder {
                    .apFile {
                        @include fileFields;
                    }
                    .spec {
                        @include specText;
                    }
                }
            }
            .radioGroup {
                flex-direction: row;
                span {
                    @include formElement($color: $infoColor, $fontSize: 14px);
                }
            }
        }
        .addButtonHolder {
            @include inlineAddButton;
        }
    }
    .additional-Block {
        background-color: $backgroundGray;
        display: flex;
        width: auto;
        justify-content: space-evenly;
        .item {
            margin: 20px;
            background-color: $backgroundGray;
            border: none;
            .entry {
                border: none;
                background: $backgroundGray;
            }
            .entryButton {
                background-color: $sirBlue;
                color: $whiteColor;
            }
        }
    }
    .inner-container {
        padding: 0px;
        overflow: overlay;
        .data-container {
            border-bottom: 2px solid $backgroundGray;
            display: flex;
            width: auto;
            justify-content: space-between;
            margin-top: 20px;
            padding-right: 15px;
            .additionData {
                width: 75%;
                p {
                    @include bookFont;
                    color: $sirBlue;
                    line-break: anywhere;
                    &.date {
                        @include dateFormat;
                    }
                }
                .data-links {
                    width: 40%;
                    letter-spacing: 0px;
                    @include bookFont;
                    color: $sirBlue;
                    line-height: 18px;
                    text-decoration: underline;
                    margin-bottom: 10px;
                    a,
                    a:visited {
                        color: $darkBlue;
                    }
                }
            }
            .icon-container {
                padding-top: 16px;
                .icon {
                    color: $infoColor;
                    cursor: pointer;
                    &:first-child {
                        margin-right: 20px;
                    }
                }
                .lasticon {
                    margin-right: 15px;
                }
            }
        }
        .editPromotion {
            padding: 10px;
            height: auto;
            box-shadow: 0px 3px 10px $infoColor;
            position: relative;
            z-index: 1000;
            transform: 'translate(-50%, -50%)';
            background-color: $whiteColor;
            .edit {
                display: row;
                justify-content: space-between;
                width: 30%;
                .editActionHolder {
                    margin-right: 10px;
                    width: 30%;
                    margin-bottom: 10px;
                    .cmrEditDate {
                        width: 100%;
                        .MuiInput-root {
                            &:hover::before {
                                border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                            }
                            input {
                                color: $sirBlue;
                                @include bookFont;
                            }
                        }
                        input[type='date']:required:invalid::-webkit-datetime-edit {
                            color: transparent;
                        }
                        input[type='date']:focus::-webkit-datetime-edit {
                            color: black !important;
                        }
                    }
                    .cmrEditInput {
                        width: 100%;
                        @include bookFont;
                        color: #333;
                        &:hover::before {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.42);
                        }
                        &::after {
                            border: none;
                        }
                    }
                    @include datePickerEdit;
                    &.apFileHolder {
                        .apFile {
                            @include fileFieldsEdit;
                        }
                        .spec {
                            @include bookFont($fontSize: 10px);
                            display: inline-block;
                            color: #bbb;
                            cursor: pointer;
                        }
                    }
                }
                .radioGroup {
                    flex-direction: row;
                    span {
                        @include bookFont;
                        color: $sirBlue;
                    }
                }
            }
            .editButtonHolder {
                @include inlineEditButtons;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
    .additional-container {
        .additionPromotion {
            .add {
                gap: 0px 25px;
                justify-content: left;
                .actionHolder {
                    width: 45%;
                    margin-right: 0;
                }
            }
        }
    }
}
@media only screen and (max-width: 767px) {
    .additional-container {
        .additionPromotion {
            flex-direction: column;
            .add {
                width: 100%;
                flex-wrap: nowrap;
                flex-direction: column;
                .actionHolder {
                    margin-right: 0px;
                    width: 100%;
                    @include datePickerMobile;

                    &.apFileHolder {
                        .apFile {
                            font-size: 10px;
                        }
                        .spec {
                            display: grid;
                        }
                    }
                }
            }
            .addButtonHolder {
                width: 100%;
                justify-content: flex-end;
                display: inherit;
            }
        }
        .inner-container {
            .data-container {
                padding: 0px;
                .additionData {
                    p {
                        font-size: 14px;
                        margin-bottom: 12px;
                    }
                    .data-links {
                        font-size: 10px;
                    }
                }
                .icon-container {
                    padding-top: 0;
                    display: flex;
                    flex-direction: column;
                }
            }
            .editPromotion {
                .edit {
                    width: 100%;
                    .editActionHolder {
                        display: block;
                        width: 100%;
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}