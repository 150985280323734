@import '../../../../styles/colors';

.editMenuHolder {
    max-width: 460px;
    margin: auto;
    border: 1px solid $sirBlue;
    height: 360px;
    position: relative;
    box-shadow: 0px 0px 6px 3px #00000029;
    .imageContainer {
        width: 100%;
        height: 306px;
    }
    .imageContainerFullScreen {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
            &.portrait {
                margin: auto;
                width: auto;
            }
            border: none;
        }
    }
    .topBar {
        position: absolute;
        height: 15%;
        background-color: #000000;
        width: 100%;
        opacity: 0.5;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        top: 0;
        .cancelAction {
            color: $whiteColor;
            opacity: 1;
            font-family: 'MercuryTextG4Roman';
            font-size: 20px;
            display: flex;
            align-items: center;
            cursor: pointer;
            .fileIcon {
                margin: 0px 20px;
                font-size: 30px;
            }
        }
    }
    .fullScreenIcon {
        top: 5%;
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: flex-end;
        .fileIcon {
            color: $whiteColor;
            margin: 0px 20px;
            font-size: 30px;
            cursor: pointer;
        }
    }
    .tagsContainer {
        position: absolute;
        width: 100%;
        height: 40%;
        top: 30%;
        display: flex;
        justify-content: center;
    }
    .bottomBar {
        position: absolute;
        height: 15%;
        background-color: #000000;
        width: 100%;
        bottom: 0;
        opacity: 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
        .bottomActions {
            font-size: 30px;
            color: $whiteColor;
            opacity: 1;
            .fileIcon {
                margin: 0px 20px;
                cursor: pointer;
            }
        }
    }
}
.editMenuHolderFullScreen {
    width: inherit;
    height: inherit;
    margin: unset;
    max-width: unset;
}
.imageEditMenuHolderFullScreen {
    top: 0;
    position: fixed;
    left: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
}
