@import '../../shared/styles/colors';
@import './typographyMixins';

@mixin loaderOverlay($width: 85%) {
    position: fixed;
    width: $width;
    height: 100%;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sirBlue;
}

@mixin loaderOverlayComponentSize() {
    position: absolute;
    width: inherit;
    height: inherit;
    top: 0;
    left: auto;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    z-index: 1400;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: sirBlue;
}

@mixin customSwitch {
    .MuiSwitch-root {
        width: 30px;
        height: 18px;
        padding: 0px;
    }
    .MuiSwitch-switchBase {
        padding: 3px;
        color: $infoColor !important;
        &.Mui-disabled + .MuiSwitch-track {
            opacity: 0.5 !important;
        }
    }
    .Mui-checked {
        transform: translateX(12px) !important;
        color: $whiteColor !important;
        &.MuiSwitch-switchBase + .MuiSwitch-track {
            opacity: 1;
        }
        &.MuiSwitch-colorSecondary + .MuiSwitch-track {
            background-color: $solidBlue !important;
        }
    }
    .MuiSwitch-thumb {
        width: 12px;
        height: 12px;
        box-shadow: none;
    }
    .MuiSwitch-track {
        border: 1px solid $infoColor;
        border-radius: 9px;
        opacity: 1;
        background-color: $whiteColor;
    }
}

@mixin loadingOverlayText {
    @include mediumFont($color: $whiteColor);
    margin: 0px 20px;
}

@mixin customInput {
    width: 100%;
    background-color: $whiteColor;
    padding: 0px 10px;
    border: 1px solid $darkGray !important;
    border-radius: 10px;
    @include bookFont(14px, 400, $darkGray);
    .MuiSelect-select:focus {
        background-color: transparent;
    }
    &.Mui-focused {
        border: 1px solid $sirBlue !important;
        box-shadow: 0 0 4px $sirBlue;
    }
    .Mui-focused {
        border: 1px solid $sirBlue !important;
        box-shadow: 0 0 4px $sirBlue;
        border-radius: 10px;
    }
    &.autoComplete {
        .Mui-focused {
            border: unset !important;
            box-shadow: unset !important;
            border-radius: unset !important;
        }
    }
}

@mixin customDisabledInput {
    background-color: $backgroundGray;
    cursor: not-allowed;
}

.totalRecords {
    @include mediumFont($fontSize: 15px, $color: $sirBlue);
}
