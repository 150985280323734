$infoColor: #999;
$darkGray: #666666;
$goldColor: #b28e3a;
$sirBlue: #002349;
$darkBlue: #001731;
$whiteColor: #ffffff;
$placeholderColor: #777;
$lightGray: #d6d6d6;
$backgroundGray: #f2f2f2;
$green: #1da775;
$red: #ff0000;
$gold: #c29b40;
$black: #000000;
$lightBlue: #8d9bad;
$lightOrange: #ffcc99;
$lightGreen: #ccffcc;
$lightSeaBlue: #ccffff;
$maroon: #bc3c31;
$sirBlue20: #003166;
$blackOpacity: #00000080;
$solidBlue: #177eba;
$darkGreen: #388c3c;
$lighterGray: #fafafa;
$invalidRed: #d90429;
$sirSiteBlue: #00234c;
