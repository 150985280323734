@import '../../../shared/styles/colors';

.custom-carousel {
    height: 100%;
    .flickity-pdf{
        height: 549px;
        width: 100%;
    }
    .flickity-button {
        &:focus {
            box-shadow: unset !important;
        }
    }
}
.custom-carousel.is-fullscreen {
    .flickity-pdf{
        height: 100%!important;
    }
    .carousel-image {
        height: auto;
        max-height: 100%;
    }
    img, object {
        height: 100% !important;
        object-fit: contain !important;
    }
}
.flickity-prev-next-button {
    z-index: 10;
}
.flickity-prev-next-button.next {
    right: 48px;
}
.fullscreen-previous {
    left: -30% !important;
}
.fullscreen-next {
    right: -30% !important;
}
.flickity-button {
    &:focus {
        box-shadow: unset !important;
    }
}
.custom-fullscreen-previous {
    left: 50px !important;
}
.custom-fullscreen-next {
    right: 50px !important;
}
