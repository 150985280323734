@import '../../../../listingMixins';
@import '../../../../../../../shared/styles/formMixins';

.suppressMLSGrid {
    border-bottom: 1px solid $goldColor;

    .suppressMLSLabel {
        .MuiTypography-body1 {
            font-family: 'MercuryTextG4Roman' !important;
            font-size: 14px;
            color: $sirBlue;
            margin: 0px 10px;
        }

        margin: 10px 0px;
    }
}

.requiredField {
    .MuiTypography-body1 {
        color: $darkGray;
        font-family: 'MercuryTextG4Italic';
        font-size: 18px;
        margin: 10px 0px;
    }
}

.title {
    .sideText {
        @include mediumFont;
        color: $sirBlue;
        margin: 10px 0px;
    }

    .sideMsg {
        font-family: 'MercuryTextG4Roman' !important;
        font-size: 14px;
        margin: 10px 0px;
        color: $darkGray;
    }
}

.agentSearchGrid {
    .agentLabel {
        .MuiTypography-body1 {
            @include mediumFont($fontSize: 14px, $lineHeight: 18px, $color: $sirBlue);
        }
    }

    .agentSearchField {
        .MuiAutocomplete-input {
            color: #333;
            font-family: 'MercuryTextG4Roman';
            font-size: 14px;
            margin: 0px 5px;
        }

        &.searchAutoComplete {
            @include customSearchAutoComplete();
        }
    }

    padding: 50px 0px;
    border-bottom: 1px solid $goldColor;
}

.splitMsgContainer {
    padding: 25px 0px;

    .MuiTypography-body1 {
        font-family: 'MercuryTextG4Roman';
        color: $sirBlue;
        font-size: 14px;
    }
}

.splitPercentage {
    padding: 2px 0px;
    font-family: 'MercuryTextG4Roman';
    font-size: 14px;
    color: red;
}

.dndContainer {
    padding: 25px 0px;

    .dndHeader {
        @include mediumFont($fontSize: 14px, $lineHeight: 18px, $color: $sirBlue);
        padding: 0px 25px;
    }

    .dndBody {
        font-family: 'MercuryTextG4Roman';
        font-size: 14px;
        color: $darkGray;

        &:hover {
            cursor: move;
        }

        padding: 0px 25px;
    }

    .split {
        width: 50%;

        input {
            text-align: center;
        }
    }

    .delete {
        font-size: 14px;
        color: $darkGray;
        cursor: pointer;
    }

    .MuiButton-root:hover {
        background-color: transparent !important;
        text-decoration: underline;
    }

    .agentSplitter,
    .agent-bottom {
        flex-wrap: nowrap;
        margin-bottom: 20px;
    }
}

.splitError {
    .message {
        font-family: 'MercuryTextG4Roman';
        font-size: 14px;
        color: red;
        letter-spacing: 0.02px;
        line-height: 18px;
        margin-top: 20px;
    }
}
