@import '../../../../../../shared/styles/commonMixins';
@import '../../../../../../shared/styles/colors';

.sales-team-detail-desc-section {
    .section {
        padding: 20px 0px;
        border-bottom: 1px solid $lightGray;
        margin-bottom: 20px;
        &.small-fields {
            align-items: center;
        }

        &:last-child {
            border-bottom: 0px;
        }
        .customHolder {
            width: 80%;
            > div > div {
                background-color: transparent;
            }
            &.fullWidth {
                width: 100%;
            }
        }
        .inputField {
            @include customInput;
        }
        .info-text {
            @include infoText;
            .right-alig {
                text-align: end;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        .button {
            margin-left: 20px;
        }
    }
    .info-text {
        @include infoText;
    }
}
