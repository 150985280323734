@import '../../../../../../listingMixins';

.invitedSection {
    .invited {
        .toggleInvite {
            align-items: center;
            font-family: 'MercuryTextG4Roman';
            font-family: 15px;
            margin: 10px 0px;
            .arrowIcon {
                cursor: pointer;
            }
        }
        .content {
            margin-bottom: 30px;
            max-height: 200px;
            overflow: overlay;
            .formControlLabel {
                @include mediumFont(
                    $color: $sirBlue,
                    $fontWeight: 400,
                    $textTransform: uppercase
                );
            }
            .formControlData {
                @include regularFont($darkGray, 400, 14px, none);
            }
        }
    }
}
