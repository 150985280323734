@import '../../../../../../shared/styles/commonMixins';
@import '../../../../../../shared/styles/typographyMixins';
@import '../../../../../../shared/styles/colors';
@import '../../../../../../shared/styles/formMixins';

.office-detail-desc-section {
    .content {
        margin: 20px 0px;
        &:not(:nth-last-child(1)) {
            border-bottom: 1px solid $lightGray;
        }
        .section {
            margin-bottom: 10px;
            align-items: center;
            .customHolder {
                width: 80%;
                > div > div {
                    background-color: transparent;
                }
                &.fullWidth {
                    width: 100%;
                    .inputField {
                        &.textArea {
                            padding-top: 10px !important;
                            padding-bottom: 10px !important;
                        }
                    }
                }
            }
            .inputField {
                @include customInput;
                &.disabledInput {
                    @include customDisabledInput;
                    input {
                        cursor: not-allowed;
                    }
                }
            }
            .MuiInputBase-input {
                padding: 8px 0px 8px;
                color: $black;
            }
            .info-text {
                @include infoText;
                .moreInfo {
                    text-decoration: underline;
                    cursor: pointer;
                }
                .right-alig {
                    text-align: end;
                }
            }
            .moreInformation {
                .sectionOne {
                    background-color: $lightOrange;
                }
                .sectionTwo {
                    background-color: $lightGreen;
                }
                .sectionThree {
                    background-color: $lightSeaBlue;
                }
                .cells {
                    @include mediumFont(
                        $fontSize: 12px,
                        $color: $sirBlue,
                        $fontWeight: 400
                    );
                    border: 1px solid $darkGray;
                }

                // display: none;
            }
            .customSwitch {
                @include customSwitch;
            }
            .formControlLabel {
                @include mediumFont($color: $sirBlue, $fontWeight: 400);
            }
            .addMls {
                color: $infoColor;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
    .buttons {
        display: flex;
        justify-content: flex-end;
        .button {
            margin-left: 20px;
        }
    }
    .chipsContainer {
        @include customChips();
    }
}
.officeMlsContainer {
    .content {
        .section {
            margin-bottom: 10px;
            align-items: center;
            &:first-child {
                border-bottom: 1px solid $lightGray;
            }
            .customHolder {
                width: 80%;
                > div > div {
                    background-color: transparent;
                }
                &.fullWidth {
                    width: 100%;
                    .inputField {
                        &.textArea {
                            padding-top: 10px !important;
                            padding-bottom: 10px !important;
                        }
                    }
                }
            }
            .inputField {
                @include customInput;
                &.disabledInput {
                    @include customDisabledInput;
                    input {
                        cursor: not-allowed;
                    }
                }
            }
            .MuiInputBase-input {
                padding: 8px 0px 8px;
                color: $black;
            }
            .formControlLabel {
                @include mediumFont($color: $sirBlue, $fontWeight: 400);
            }
            .formControlData {
                @include regularFont($darkGray, 400, 14px, none);
            }
            .buttonHolder {
                .button {
                    &:nth-child(n) {
                        margin-right: 20px;
                    }
                }
            }
        }
    }
}
