@import '../../../listingMixins';

.openHouse-listing {
    .data-container {
        .addOpenHouse {
            padding: 30px 0px;
            height: auto;
            background-color: $whiteColor !important;
            display: flex;
            align-items: center;
            border-bottom: 1px solid $goldColor;
        }

        .openHouseEditContainer {
            background-color: $whiteColor;
            position: relative;

            .editForm {
                width: 100%;
                display: flex;
                min-height: 140px;
                padding-top: 10px;

                .cmrInput {
                    @include bookFont($color: $darkGray, $fontWeight: 400);
                    @include customInput;

                    .MuiInputBase-input {
                        padding: 8px 0px 8px;
                        color: $black;
                    }

                    &::before {
                        border: none;
                    }
                }

                .editCol-1 {
                    width: 75%;

                    .row-1 {
                        display: flex;

                        .actionHolder {
                            margin-right: 15px;
                            margin-bottom: 18px;

                            .cmrDate {
                                @include dateField($fontSize: 14px);
                                padding: 4px 9px;

                                border-radius: 10px;

                                &:hover {
                                    border-color: $darkGray;
                                }
                            }

                            input[type='date']:required:invalid::-webkit-datetime-edit {
                                color: transparent;
                            }

                            input[type='date']:focus::-webkit-datetime-edit {
                                color: $darkGray !important;
                            }

                            @include datePickerInput(
                                $fontSize: 14px,
                                $placeholderColor: #b6b6b6
                            );

                            .ant-picker-input > input {
                                @include bookFont(14px !important, 400, $darkGray);
                            }
                        }

                        .editData-date {
                            width: 26%;
                        }

                        .editData-agent {
                            width: 26%;
                        }

                        .editFromtime {
                            width: 18%;
                        }

                        .editTotime {
                            width: 18%;
                        }
                    }

                    .row-2 {
                        display: flex;
                        margin-bottom: 18px;
                        margin-top: 0px;

                        span.attendees {
                            width: 26%;
                        }

                        span.notes {
                            margin-left: 13px;
                            width: 66%;
                        }

                        input {
                            font-size: 14px;
                        }
                    }

                    .row-3 {
                        display: flex;
                        margin-top: 0px;

                        span.section {
                            width: 26%;
                        }

                        input {
                            font-size: 14px;
                        }
                    }

                    &.edit-inline {
                        width: 100%;

                        span.notes {
                            width: 65%;
                        }
                    }
                }

                .editCol-2 {
                    display: flex;
                    flex-direction: column;
                    width: 25%;

                    .individualCheckbox {
                        margin: 0px 0 0px 10px;
                        display: inline-block;
                        font-size: 15px !important;

                        label {
                            font-family: 'MercuryTextG4Roman';

                            span {
                                font-weight: normal;
                            }
                        }

                        .activeCheckboxContent {
                            margin-left: 10px;
                            color: $sirBlue;
                        }

                        .inActiveCheckboxContent {
                            margin-left: 10px;
                            color: $darkGray;
                        }

                        .inActiveTextCheckboxContent {
                            margin-left: 10px;
                            color: $lightGray;
                        }

                        .customCheckbox {
                            appearance: none;
                            background-color: $whiteColor;
                            margin: 0;
                            font: inherit;
                            color: $infoColor;
                            width: 1.15em;
                            height: 1.15em;
                            border: 0.15em solid $infoColor;
                            transform: translateY(-0.075em);
                            display: inline-grid;
                            place-content: center;
                            cursor: pointer;

                            &::before {
                                content: '';
                                width: 0.65em;
                                height: 0.65em;
                                transform: scale(0);
                                transition: 120ms transform ease-in-out;
                                box-shadow: inset 1em 1em $sirBlue;
                            }

                            &:checked::before {
                                transform: scale(1);
                            }
                        }

                        .inActiveCustomCheckbox {
                            border: 0.15em solid $lightGray;
                            cursor: default;
                        }
                    }

                    .virtualTour {
                        padding-top: 10px;
                        margin: 0px 10px 0px 10px;
                        display: inline-block;
                    }

                    &.edit-inline {
                        width: 30%;

                        span.notes {
                            width: 66%;
                        }
                    }
                }
            }
        }

        .table-heading {
            @include tableHeading(
                $padding: 30px 0,
                $borderColor: $goldColor,
                $fontSize: 14px,
                $fontColor: $darkBlue
            );

            .to-time {
                width: 8%;
            }

            .date {
                width: 15%;
            }

            .agent {
                width: 16%;
            }

            .time {
                width: 11%;
            }

            .large {
                width: 13%;
            }

            .small {
                width: 16%;
                padding-left: 15px;
            }

            .empty {
                width: 10%;
            }
        }

        .buttonGroup {
            .addButtonDisabled,
            .unActive {
                @include mediumFont(
                    $fontSize: 12px,
                    $lineHeight: 14px,
                    $color: $sirBlue,
                    $letterSpacing: 1px,
                    $fontWeight: 400,
                    $textTransform: uppercase
                );
                padding: 10px;
                cursor: pointer;
                background-color: $whiteColor;
                border: 1px solid $gold;
                width: 70px;
            }

            .addButtonActive,
            .active {
                @include mediumFont(
                    $fontSize: 12px,
                    $lineHeight: 14px,
                    $color: $whiteColor,
                    $letterSpacing: 1px,
                    $fontWeight: 400,
                    $textTransform: uppercase
                );
                padding: 10px;
                background-color: $infoColor;
                border: 1px solid $infoColor;
                width: 70px;
                cursor: default;
            }

            @include inlineEditButtons;

            .actionButton {
                @include mediumFont(
                    $fontSize: 12px,
                    $lineHeight: 14px,
                    $color: $sirBlue,
                    $letterSpacing: 1px,
                    $fontWeight: 400,
                    $textTransform: uppercase
                );
                border: 1px solid $gold;
                padding: 10px;
            }

            .saveActive {
                background-color: $whiteColor;
            }

            .saveInActive {
                border: 1px solid $infoColor;
                background-color: $infoColor;
                color: $whiteColor;
                cursor: default;
            }
        }

        .openHouseContainer {
            .table-data {
                padding: 15px 0px;
                border-bottom: 1px solid $darkGray;

                .rows {
                    .row-1 {
                        display: flex;
                        justify-content: space-between;

                        .data-text {
                            @include mediumFont(
                                $fontSize: 12px,
                                $lineHeight: 18px,
                                $color: $sirBlue,
                                $letterSpacing: 0px
                            );
                        }

                        .date-text {
                            @include mediumFont(
                                $fontSize: 12px,
                                $lineHeight: 18px,
                                $color: $sirBlue,
                                $letterSpacing: 0.02px
                            );
                        }

                        .data-section {
                            color: $sirBlue;
                        }

                        .data-date {
                            width: 15%;

                            @include dateFormat;
                        }

                        .data-agent {
                            width: 16%;
                        }

                        .time {
                            width: 11%;
                        }

                        .to-time {
                            width: 8%;
                        }

                        .small {
                            width: 20%;
                            padding-left: 15px;
                        }

                        .large {
                            width: 13%;
                        }

                        .to {
                            width: 7%;
                        }

                        .more {
                            width: 13%;
                            padding-left: 20px;
                        }

                        .openHouse-icons {
                            @include tableIcons;
                        }
                    }

                    .row-2 {
                        .more-container {
                            width: 62%;
                            margin: auto;
                            padding: 15px 0px 20px 0px;

                            .more-row {
                                display: flex;
                                justify-content: space-between;
                                align-items: baseline;
                                margin-bottom: 10px;
                            }

                            .more-title-text {
                                @include moreTitleText;
                            }

                            .more-content {
                                width: 80%;
                                @include mediumFont(
                                    $lineHeight: inherit,
                                    $letterSpacing: 0px,
                                    $color: $darkGray
                                );
                            }

                            .selectContent {
                                display: flex;
                                flex-direction: column;
                                width: 80%;
                            }

                            .empty {
                                padding: 8px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .openHouse-listing {
        .data-container {
            .openHouseEditContainer {
                .editForm {
                    width: 100%;
                    margin: 0px;
                    padding: 0px;

                    .editCol-1 {
                        display: flex;
                        width: 70%;

                        .row-1 {
                            width: 50%;
                            flex-direction: column;

                            .actionHolder {
                                margin-bottom: 10px;
                            }

                            .editData-date {
                                width: 94%;
                            }

                            .editData-agent {
                                width: 94%;
                            }

                            .editFromtime {
                                width: 94%;
                            }

                            .editTotime {
                                width: 94%;
                            }
                        }

                        .row-2 {
                            margin-top: 0px;
                            width: 50%;
                            flex-direction: column;

                            span.attendees {
                                width: 94%;
                                margin-bottom: 10px;
                            }

                            span.notes {
                                margin-left: 0px;
                                width: 94%;
                                margin-bottom: 10px;
                            }
                        }
                        .row-3 {
                            width: 50%;
                            flex-direction: column;
                            span.section {
                                margin-left: 0px;
                                width: 94%;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .editCol-2 {
                        width: 30%;
                    }
                }
            }
        }
    }
    .timeContainer {
        .buttonHolder {
            margin: 2px 18px 0px 10px;
        }
    }
}

@media only screen and (max-width: 767px) {
    .openHouse-listing {
        .data-container {
            .openHouseEditContainer {
                padding: 10px;

                .editForm {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    margin: 0px;
                    padding-top: 0px;

                    .cmrInput,
                    .cmrDate {
                        font-size: 14px !important;
                    }

                    .editCol-1 {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .row-1 {
                            width: 100%;
                            flex-direction: column;

                            .actionHolder {
                                margin-bottom: 10px;
                                margin-right: 0px;
                                @include datePickerMobile;
                            }

                            .editData-date {
                                width: 100%;
                            }

                            .editData-agent {
                                width: 100%;
                            }

                            .editFromtime {
                                width: 100%;
                            }

                            .editTotime {
                                width: 100%;
                            }
                        }

                        .row-2 {
                            margin-top: 0px;
                            width: 100%;
                            flex-direction: column;

                            span.attendees {
                                width: 100%;
                                margin-bottom: 10px;
                            }

                            span.notes {
                                margin-left: 0px;
                                width: 100%;
                                margin-bottom: 10px;
                            }

                            input {
                                font-size: 14px;
                            }
                        }

                        &.edit-inline {
                            width: 100%;
                        }

                        .row-3 {
                            width: 100%;
                            flex-direction: column;
                            span.section {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                        }
                    }

                    .editCol-2 {
                        width: 100%;
                        margin-top: 10px;

                        .individualCheckbox {
                            margin: 0px;

                            .activeCheckboxContent,
                            .inActiveCheckboxContent,
                            .inActiveTextCheckboxContent {
                                font-size: 10px !important;
                            }

                            .customCheckbox {
                                width: 1.05em;
                                height: 1.05em;
                            }
                        }

                        .virtualTour {
                            margin: 0px;

                            .virtualTourInput {
                                font-size: 14px;
                            }
                        }

                        &.edit-inline {
                            width: 100%;
                        }
                    }
                }
            }

            .buttonGroup {
                justify-content: flex-end;
                padding: 0px;
                margin-top: 30px;
            }

            .table-heading {
                @include tableHeadingMobile;

                .empty {
                    width: 6%;
                }
            }

            .openHouseContainer {
                .table-data {
                    .rows {
                        .row-1 {
                            .data-text,
                            .date-text,
                            .data-section {
                                @include tableRowMobile;
                            }

                            .openHouse-icons {
                                @include tableIconsMobile;
                            }
                        }

                        .row-2 {
                            .more-container {
                                width: 80%;

                                .more-title-text {
                                    font-size: 14px;
                                    margin-left: 80px;
                                }

                                .more-content {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .timeContainer {
        .buttonHolder {
            margin: 2px 18px 0px 10px;
        }
    }
}
